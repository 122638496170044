import React from 'react'
import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'

import { convertToMDY, formatDayTimeRange } from '../../utils/datetime'

import {
  ErrorMessage,
  ExpansionPanel,
  Flex,
  LoadingMessage,
  MakeupForm,
  Paper,
  Text,
  withStyles,
} from '../../components'

import { GET_MAKEUP } from './queries'

const styles = theme => ({
  root: {
    textAlign: 'left',
    margin: 'auto',
    width: '67%',
    minWidth: '600px',
    maxWidth: '1000px',
  },
  display1: {
    textAlign: 'left',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  expansionDetail: {
    overflow: 'visible',
  },
})

const MakeupDetailView = props => {
  const makeupId = props.match.params[0]

  const { loading, error, data } = useQuery(GET_MAKEUP, {
    fetchPolicy: 'cache-and-network',
    variables: { id: makeupId },
  })
  if (loading) return <LoadingMessage />
  if (error) return <ErrorMessage error={error} />
  if (!data) return null

  const { classroomCodes } = props
  const makeup = formatMakeuplInfo(data.makeup)

  const handleMakeupChange = name => event => {
    const value = event.target.value
    this.setState(state => ({
      makeup: {
        ...state.makeup,
        [name]: value,
      },
    }))
  }

  const { firstName, lastName } = makeup.student
  return (
    <Flex
      column
      grow={1}
      style={{
        textAlign: 'left',
        margin: 'auto',
        width: '67%',
        minWidth: '600px',
        maxWidth: '1000px',
      }}
    >
      <br />
      <Link to="/makeup">{'< back to Makeup List'}</Link>
      <br />
      <Text variant="h4">{`Makeup for ${firstName} ${lastName}`}</Text>
      <br />
      <Paper>
        <ExpansionPanel
          expanded
          overflow
          title="Makeup Information"
          form={
            <MakeupForm
              showCreatedBy
              makeup={makeup}
              handleChange={handleMakeupChange}
              fromClasses={classroomCodes}
              toClasses={classroomCodes}
              fromMakeupDetail
            />
          }
        />
      </Paper>
    </Flex>
  )
}

const formatMakeuplInfo = ({
  id,
  status,
  notes,
  fromLesson,
  toLesson,
  student,
}) => ({
  id: id,
  status: status,
  notes: notes,
  student: student,
  fromClassTitle: fromLesson.classroom.title,
  fromLessonTitle: `${fromLesson.lessonPlan.order}. ${convertToMDY(
    fromLesson.startOn
  )}, ${formatDayTimeRange(
    new Date(fromLesson.startOn),
    new Date(fromLesson.endOn)
  )}`,
  toClassTitle: toLesson.classroom.title,
  toLessonTitle: `${toLesson.lessonPlan.order}. ${convertToMDY(
    toLesson.startOn
  )}, ${formatDayTimeRange(
    new Date(toLesson.startOn),
    new Date(toLesson.endOn)
  )}`,
})

export default withStyles(styles)(MakeupDetailView)
