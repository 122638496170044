import React from 'react'
import ReactTable from 'react-table-6'
import { convertToMDY, dateFilterMethod } from '../../utils/datetime'

import {
  CLASS_SCHEDULED,
  TABLE_ROW_HIGHLIGHT_COLOR,
  TABLE_ROW_BACKGROUND_COLOR,
} from '../../config'
import { Box } from '@mui/material'

const MakeupTable = ({
  makeups,
  filtered,
  handleFilteredChange,
  handleRowClick,
}) => {
  const defaultSorted = [{ id: 'toDate', desc: true }]

  return (
    <Box align="left">
      <ReactTable
        data={makeups}
        defaultFiltered={filtered}
        onFilteredChange={handleFilteredChange}
        columns={columns}
        filterable={true}
        defaultSorted={defaultSorted}
        className="-highlight"
        style={{ fontSize: '10pt' }}
        defaultPageSize={25}
        defaultFilterMethod={(filter, row) =>
          row[filter.id] &&
          row[filter.id]
            .toString()
            .toLowerCase()
            .includes(filter.value.toString().toLowerCase())
        }
        getTrProps={(state, rowInfo, column) => {
          if (
            rowInfo &&
            rowInfo.row.status === CLASS_SCHEDULED &&
            rowInfo.row.startDate < new Date()
          ) {
            return { style: { background: TABLE_ROW_HIGHLIGHT_COLOR } }
          }
          return { style: { background: TABLE_ROW_BACKGROUND_COLOR } }
        }}
        getTdProps={(state, rowInfo, column, instance) => {
          return {
            onClick: (e, handleOriginal) => {
              if (column.Header !== undefined) {
                handleRowClick(rowInfo)
              }
              if (handleOriginal) {
                handleOriginal()
              }
            },
          }
        }}
        getTheadProps={(state, rowInfo, column, instance) => {
          return {
            style: {
              fontWeight: 'bold',
              borderBottom: '1px solid #E5E5E5',
            },
          }
        }}
      />
    </Box>
  )
}

const columns = [
  {
    Header: 'Status',
    accessor: 'status',
    width: 135,
    Cell: props => {
      return <span>{props.value ? props.value : 'UNKNOWN'}</span>
    },
    filterMethod: (filter, row) => {
      if (filter.value !== 'ALL') {
        let filterValue = filter.value
        return row[filter.id] === filterValue
      }
      if (filter.value === 'UNKNOWN') {
        return row[filter.id] === null
      }
      return true
    },
    Filter: ({ filter, onChange }) => (
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'all'}
      >
        <option value="ALL">All</option>
        <option value="SCHEDULED">SCHEDULED</option>
        <option value="ATTENDED">ATTENDED</option>
        <option value="ABSENT">ABSENT</option>
      </select>
    ),
  },
  {
    Header: 'First Name',
    accessor: 'firstName',
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
    filterMethod: (filter, row) => {
      return (
        row[filter.id] &&
        row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase())
      )
    },
  },
  {
    Header: 'Class',
    accessor: 'toClass',
    Cell: props => <span>{props.value || 'Absent'}</span>,
    width: 85,
  },
  {
    Header: 'Lesson',
    accessor: 'fromLesson',
    width: 60,
  },
  {
    Header: 'Date',
    accessor: 'toDate',
    Cell: props => (
      <span>{(props.value && convertToMDY(props.value)) || 'Absent'}</span>
    ),
    width: 120,
    filterMethod: dateFilterMethod,
  },
  {
    Header: 'Time',
    accessor: 'toTime',
    width: 180,
  },
  {
    Header: 'From',
    accessor: 'fromClass',
    width: 85,
  },
  {
    Header: 'From Date',
    accessor: 'fromDate',
    Cell: props => <span>{props.value && convertToMDY(props.value)}</span>,
    width: 120,
    filterMethod: dateFilterMethod,
  },

  {
    Header: 'Requested On',
    accessor: 'createdOn',
    Cell: props => <span>{props.value && convertToMDY(props.value)}</span>,
    width: 120,
    filterMethod: dateFilterMethod,
  },
]

export default MakeupTable
