import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
  Fragment,
} from 'react'
import {
  Flex,
  TextField,
  MenuItem,
  SnackbarNotification,
  Accordion,
  Typography,
  Button,
  Divider,
  makeStyles,
  CustomAccordionSummary,
} from '../../components'
import EditableTextField from './EditableTextField'
import VideoHandler from './VideoHandler'
import { CREATE_HINT, UPDATE_HINT, UPDATE_ISSUE } from './queries'
import { useMutation } from '@apollo/client'

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  textLabel: {
    alignSelf: 'start',
    marginLeft: '0.60vw',
    marginTop: '1%',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '12px',
  },
  textLabel2: {
    marginTop: 'auto',
    padding: 5,
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '13px',
  },
  imageDisplay: {
    width: '60%',
    height: 'auto',
  },
  textPreview: {
    width: '100%',
    borderRadius: '4px',
    border: '1px solid rgb(187, 187, 187)',
  },
  iconGroup: {
    marginLeft: 'auto',
  },
  iconButton: {
    width: 42,
    height: 42,
  },
  icon: {
    width: 24,
    height: 24,
  },
}))

/**
 * using a ref as a hacked up solution
 * to link the muataion from hintpanel to editissuedialogbtn
 * not good, but it works
 * hint panel would need to be completely refactored otherwise
 */

export const HintPanel = forwardRef((props, ref) => {
  const classes = useStyles()

  const [hints, setHints] = useState(props.hints)
  const [currentHint, setCurrentHint] = useState(0)
  const [hint, setHint] = useState(hints.length > 0 ? hints[0].text : '')
  const [startTime, setStartTime] = useState(0)
  const [endTime, setEndTime] = useState(null)
  const [duration, setDuration] = useState(0)
  const [open, setOpen] = useState(false)
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    messageType: '',
  })

  const {
    id: issueId,
    employeeId,
    problemId,
    videoId,
    problemSetTitle,
    order,
    updateTable,
    handleClose,
  } = props

  useEffect(() => {}, [duration])

  const [handleSendHint] = useMutation(UPDATE_ISSUE, {
    variables: {
      id: issueId,
      input: {
        responsibleEmployeeId: employeeId,
        dateAssigned: new Date().toISOString(),
        dateResolved: new Date().toISOString(),
        isRead: false,
        issueStatus: 'RESOLVED',
        response: hint,
      },
    },
    onCompleted: () => {
      updateTable()
      handleClose()
    },
  })

  useImperativeHandle(ref, () => ({
    async sendHint() {
      handleSendHint()
    },
  }))

  const handleVideoHint = event => {
    if (videoId && !open) {
      setOpen(true)
    } else {
      setStartTime(0)
      setEndTime(null)
      setDuration([0, null])
      setOpen(false)
    }
  }

  const [createHint] = useMutation(CREATE_HINT)
  const handleCreateHint = async inputText => {
    let success = false
    setSnackbar({
      open: true,
      message: 'Creating Hint',
      messageType: 'loading',
    })
    let videoLink = null
    if (open && videoId) {
      let minutes = Math.floor(Number(duration[0]) / 60)
      let seconds = Math.floor(Number(duration[0]) - minutes * 60)
      videoLink = `https://player.vimeo.com/video/${videoId}#t=${minutes}m${seconds}s`
    }
    await createHint({
      variables: {
        input: {
          problemId: problemId,
          text: inputText,
          video: videoLink ? videoLink : null,
          cost: 0, //Cost is not used so default is 0
        },
      },
    }).then(response => {
      //Probably better solution maybe update later
      hints.push(response.data.createHint)
      setHint(response.data.createHint.text)
      setHints([...hints])
      success = true
    })
    setSnackbar({
      open: true,
      message: success ? 'Hint Created' : 'error',
      messageType: success ? 'success' : 'error',
    })
  }

  const [updateHint] = useMutation(UPDATE_HINT)
  const handleUpdateHint = async inputText => {
    if (hints.length <= 0) {
      window.alert('Can not update a hint that does not exist!')
      return
    }
    let success = false
    let videoLink = null
    if (open && videoId) {
      let minutes = Math.floor(Number(duration[0]) / 60)
      let seconds = Math.floor(Number(duration[0]) - minutes * 60)
      videoLink = `https://player.vimeo.com/video/${videoId}#t=${minutes}m${seconds}s`
    }
    setSnackbar({
      open: true,
      message: 'Updating Hint',
    })
    await updateHint({
      variables: {
        id: hints[currentHint].id,
        input: {
          text: inputText,
          video: videoLink ? videoLink : null,
        },
      },
    }).then(response => {
      //Probably better solution maybe update later
      hints[currentHint] = response.data.updateHint
      setHints([...hints])
      success = true
    })
    setSnackbar({
      open: true,
      message: success ? 'Hint Updated' : 'error',
      messageType: success ? 'success' : 'error',
    })
  }

  return (
    <Accordion defaultExpanded style={{ marginBottom: '1%' }}>
      <CustomAccordionSummary
        aria-controls="panel1a-content"
        id="edit-issue-hints-panel"
      >
        <Typography
          className={classes.heading}
        >{`Create/Edit Hints for ${problemSetTitle} - Problem #${order}`}</Typography>
      </CustomAccordionSummary>
      <Flex
        column
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '2.5%',
        }}
      >
        {open && (
          <Fragment>
            <VideoHandler
              startTime={startTime}
              endTime={endTime}
              duration={duration}
              videoId={videoId}
              open={open}
              setStartTime={setStartTime}
              setEndTime={setEndTime}
              setDuration={setDuration}
              handleUpdate={handleUpdateHint}
              handleCreate={handleCreateHint}
            />
            <Divider
              style={{ width: '98%', marginTop: '2.5%', marginBottom: '2.5%' }}
            />
          </Fragment>
        )}
        <Flex
          style={{
            width: '98%',
            marginBottom: '2.5%',
            justifyContent: 'center',
          }}
        >
          <EditableTextField
            label={'Hint'}
            text={hint}
            create
            update
            handleUpdate={handleUpdateHint}
            handleCreate={handleCreateHint}
          />
        </Flex>
        <TextField
          name="hints"
          label="Hints"
          style={{ width: '95%', marginBottom: '2.5%' }}
          select
          value={hint === '' ? '' : currentHint}
          onChange={event => {
            setCurrentHint(event.target.value)
            setHint(hints[event.target.value].text)
          }}
        >
          {hints &&
            hints.map((hint, index) => (
              <MenuItem key={index} value={index}>
                {hint.text}
              </MenuItem>
            ))}
        </TextField>
        {videoId && (
          <Button onClick={handleVideoHint} style={{ marginBottom: '1.5%' }}>
            {open ? 'Delete Video Hint' : 'Add Video Hint'}
          </Button>
        )}
      </Flex>
      <SnackbarNotification
        open={snackbar.open}
        handleClose={() => {
          setSnackbar({
            snackbar: {
              ...snackbar,
              open: false,
            },
          })
        }}
        message={snackbar.message}
        messageType={snackbar.messageType}
      />
    </Accordion>
  )
})

export default HintPanel
