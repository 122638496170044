import { formatMMDD } from '../../utils/datetime'
import { StatusIcon } from './Icons'
import React from 'react'

export const compareStudents = (
  aLastName,
  bLastName,
  aFirstName,
  bFirstName
) => {
  return (
    aLastName.localeCompare(bLastName) || aFirstName.localeCompare(bFirstName)
  )
}

export const mapStatusToLabel = status => {
  switch (status) {
    case 'EARLY':
      return 'Leave Early'
    default:
      return (
        status.substring(0, 1).toUpperCase() + status.substring(1).toLowerCase()
      )
  }
}

export const cleanUpData = lessons => {
  const attendeeData = {}
  const studentData = {}

  for (const l of lessons) {
    for (const a of l.attendees) {
      attendeeData[
        a.student.id
      ] = {} /* Initializing an empty object for each student id*/
      studentData[a.student.id] = {
        id: a.student.id,
        firstName: a.student.firstName,
        lastName: a.student.lastName,
        ontime: 0,
        absent: 0,
        late: 0,
        early: 0,
        image: a.student.imageUrl,
        gender: a.student.gender,
      }
    }
  }
  for (const l of lessons) {
    for (const a of l.attendees) {
      attendeeData[a.student.id][l.lessonPlan.title] = {
        status: a.status ? a.status : 'UNKNOWN',
        note: a.note && a.note.length > 0 ? a.note : null,
        timeAdjustment: a.timeAdjustment ? a.timeAdjustment : 0,
        isMakeup: a.isMakeup,
        id: a.id,
      }
      if (a.status === 'ONTIME') studentData[a.student.id].ontime++
      else if (a.status === 'ABSENT') studentData[a.student.id].absent++
      else if (a.status === 'LATE') studentData[a.student.id].late++
      else if (a.status === 'EARLY') studentData[a.student.id].early++
    }
  }

  const data = Object.keys(attendeeData).map(key => {
    const { late, early, absent, ontime, ...rest } = studentData[key]
    return {
      student: { ...rest },
      late: late,
      early: early,
      absent: absent,
      ontime: ontime,
      ...attendeeData[key],
    }
  })
  return data
}

export const getTitles = lessons => {
  const titles = lessons.map(lesson => ({
    Header: `${lesson.lessonPlan.order}. ${formatMMDD(
      new Date(lesson.startOn)
    )}`,
    accessor: lesson.lessonPlan.title,
    align: 'center',
  }))
  return [
    {
      Header: 'Student Name',
      accessor: 'student',
      align: 'left',
    },
    {
      Header: <StatusIcon status="ONTIME" />,
      accessor: 'ontime',
      align: 'center',
    },
    {
      Header: <StatusIcon status="ABSENT" />,
      accessor: 'absent',
      align: 'center',
    },
    {
      Header: <StatusIcon status="LATE" />,
      accessor: 'late',
      align: 'center',
    },
    {
      Header: <StatusIcon status="EARLY" />,
      accessor: 'early',
      align: 'center',
    },
    ...titles,
  ]
}

export const NonIconColumns = column =>
  column.accessor === 'ontime' ||
  column.accessor === 'absent' ||
  column.accessor === 'late' ||
  column.accessor === 'early'
