import React, { useEffect, useState } from 'react'
import { useLazyQuery, useMutation } from '@apollo/client'
import { convertToDateTimeString } from '../../utils/datetime'
import { Link } from 'react-router-dom'

import {
  Box,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'

import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'

import {
  AddIcon,
  AttachmentIcon,
  CheckBoxIcon,
  CheckCircleOutlineIcon,
  EditIcon,
  HelpIcon,
  HighlightOffIcon,
  LaTeX,
  RadioButtonUncheckedIcon,
  SnackbarNotification,
} from '../../components'

import {
  CREATE_SUBMISSION,
  GET_GRADES_FROM_ASSIGNMENT,
  GET_STUDENTS_FROM_ASSIGNMENT,
  REPAIR_SUBMISSION,
  UPDATE_PROBLEM_RESPONSE,
  UPDATE_SUBMISSION,
} from './queries'
import { SUBMIT_GRADES } from './queries'
import CommentDialog from './CommentDialog'
import { cloneDeep } from 'lodash'

const cellStyle = {
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: 'lightgrey',
  padding: '5px 5px 5px 5px',
  align: 'center',
}

const formatProblemResponses = (grades, problems) => {
  const newProblemResponses = []
  for (const [i, problem] of Object.entries(problems)) {
    newProblemResponses[i] = {
      problemId: problem.id,
      correctAnswer: problem.correctAnswer,
      order: problem.order,
      count: problem.points,
      problemType: problem.problemType,
    }
    if (grades.submission && grades.submission.responses) {
      const response = grades.submission.responses.find(
        response => response.problem.order === problem.order
      )
      newProblemResponses[i] = {
        ...newProblemResponses[i],
        responseId: response && response.id,

        sum: response && response.id ? response.sum : '',
        attachmentUrl: response && response.attachmentUrl,
        scratchpadUrl: response && response.scratchpadUrl,
        comment: response && response.comment,
        studentAnswer: (function() {
          if (response && response.value) {
            if (problem.problemType === 'MULTIPLE_CHOICE_ONE_ANSWER') {
              return (Number(response.value) + 10).toString(36).toUpperCase()
            } else if (problem.problemType === 'MULTIPLE_CHOICE_MANY_ANSWERS') {
              let num = response.value.toString().split('')
              num.forEach((digit, index) => {
                num[index] = (Number(num[index]) + 10)
                  .toString(36)
                  .toUpperCase()
              })
              return num.join(',')
            } else {
              return response.value
            }
          }
        })(),
      }
    }
  }
  return newProblemResponses
}

const GradeStudentAssignment = props => {
  const assignmentId = props.match.params[2]
  const studentId = props.match.params[3]
  const [classroomId, setClassroomId] = useState(null)
  const [assignmentTitle, setAssignmentTitle] = useState('')
  const [problemResponses, setProblemResponse] = useState([])
  const [student, setStudent] = useState(null)
  const [students, setStudents] = useState([])

  const [commentDialog, setCommentDialog] = useState({
    id: '',
    open: false,
    comment: '',
    index: null,
  })
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    messageType: '',
  })

  const [repairSubmissions] = useMutation(REPAIR_SUBMISSION)
  const [updateSubmission] = useMutation(UPDATE_SUBMISSION)
  const [executeAssignments] = useMutation(CREATE_SUBMISSION)
  const [submitGrades] = useMutation(SUBMIT_GRADES)
  const [updateComments] = useMutation(UPDATE_PROBLEM_RESPONSE)

  const [getGradesFromAssignment] = useLazyQuery(GET_GRADES_FROM_ASSIGNMENT, {
    fetchPolicy: 'network-only',
    variables: { id: assignmentId, studentId },
    onCompleted: res => {
      const { problems, title } = res.assignment.problemSet
      const grades = res.gradesForAssignment[0]
      setStudent(res.gradesForAssignment[0].student)
      setClassroomId(res.assignment.lesson.classroom.id)
      setAssignmentTitle(title)
      const problemResponses = formatProblemResponses(grades, problems)
      setProblemResponse(problemResponses)
    },
  })

  const [getStudentsFromAssignment] = useLazyQuery(
    GET_STUDENTS_FROM_ASSIGNMENT,
    {
      fetchPolicy: 'network-only',
      variables: { id: assignmentId },
      onCompleted: res => {
        const students = res.gradesForAssignment
        // .filter(a => a.submission !== null)
        setStudents(students)
      },
    }
  )

  useEffect(() => {
    setStudent(props.student)
    getGradesFromAssignment()
    getStudentsFromAssignment()
  }, [getGradesFromAssignment, getStudentsFromAssignment])

  const originalResponses = cloneDeep(problemResponses)

  const handleOpenCommentDialog = (id, comment, index) => {
    setCommentDialog({
      id: id,
      comment: comment,
      index: index,
      open: true,
    })
  }

  const handleCloseCommentDialog = () => {
    setCommentDialog({
      open: false,
    })
  }

  const getGradesAssignmentInput = () => {
    return {
      studentId: student.id,
      responses: problemResponses.map(response =>
        response.sum === '' ? undefined : parseInt(response.sum)
      ),
    }
  }

  const handleGradeChange = (problemNum, value) => {
    const newProblemResponses = [...problemResponses]
    //correct -> null -> incorrect -> correct...
    const {
      problemType,
      studentAnswer,
      correctAnswer,
      count,
    } = originalResponses[problemNum]

    const changeStudentAnswer = problemType !== 'FREE_FORM'
    if (value > 0) {
      //the submission is correct, change problem response to null
      if (changeStudentAnswer) {
        if (!studentAnswer) {
          newProblemResponses[problemNum].studentAnswer = studentAnswer
        } else {
          newProblemResponses[problemNum].studentAnswer = undefined
        }
      }
      newProblemResponses[problemNum].sum = ''
      newProblemResponses[problemNum].percentCorrect = undefined
    } else if (value === 0) {
      //the submission is incorrect, change problem response to correct
      if (changeStudentAnswer) {
        problemResponses[problemNum].studentAnswer = correctAnswer
      }
      newProblemResponses[problemNum].sum = count
      newProblemResponses[problemNum].percentCorrect = 100
    } else {
      //the submission is null, change problem response to incorrect
      if (changeStudentAnswer) {
        if (studentAnswer) {
          newProblemResponses[problemNum].studentAnswer = studentAnswer
        } else {
          newProblemResponses[problemNum].studentAnswer = ''
        }
      }
      newProblemResponses[problemNum].sum = 0
      newProblemResponses[problemNum].percentCorrect = 0
    }
    setProblemResponse(newProblemResponses)
  }

  const handlePartialGradeChange = (problemNum, value) => {
    const newProblemResponses = [...problemResponses]
    const { correctAnswer, count, problemType } = originalResponses[problemNum]
    if (newProblemResponses[problemNum].count >= value && value >= 0) {
      newProblemResponses[problemNum].sum = value
      if (value === count && problemType !== 'FREE_FORM') {
        newProblemResponses[problemNum].studentAnswer = correctAnswer
      }
      setProblemResponse(newProblemResponses)
    }
  }

  const handleRepair = async submissionId => {
    if (submissionId === null) {
      setSnackbar({
        open: true,
        message: 'No submission found!',
        messageType: 'error',
      })
      return
    }
    await repairSubmissions({
      variables: {
        submissionId: submissionId,
      },
      refetchQueries: [
        {
          query: GET_GRADES_FROM_ASSIGNMENT,
          variables: {
            id: assignmentId,
          },
        },
      ],
    })
    setSnackbar({
      open: true,
      message: 'Submission Repaired Successfully',
      messageType: 'success',
    })
  }

  const handleResetSubmission = async submissionId => {
    if (submissionId === null) {
      setSnackbar({
        open: true,
        message: 'No submission found!',
        messageType: 'error',
      })
      return
    }
    await updateSubmission({
      variables: {
        id: submissionId,
        input: {
          attemptNumber: 0,
        },
      },
    })
    setSnackbar({
      open: true,
      message: 'Submission Reset Successfully',
      messageType: 'success',
    })
  }

  const handleExcuseAssignment = async submissionId => {
    let message
    if (submissionId === null) {
      executeAssignments({
        variables: {
          input: {
            studentId: student.id,
            assignmentId: assignmentId,
            submittedAt: new Date().toISOString(),
            excused: true,
            graded: true,
          },
        },
        refetchQueries: [
          {
            query: GET_GRADES_FROM_ASSIGNMENT,
            variables: {
              id: assignmentId,
            },
          },
        ],
      })
      message = 'Submission Excused!'
    } else {
      updateSubmission({
        variables: {
          id: submissionId,
          input: {
            excused: true,
            graded: true,
            submittedAt: new Date().toISOString(),
          },
        },
        refetchQueries: [
          {
            query: GET_GRADES_FROM_ASSIGNMENT,
            variables: {
              id: assignmentId,
            },
          },
        ],
      })
      message = 'Submission Reset Successfully'
    }

    setSnackbar({
      open: true,
      message: message,
      messageType: 'success',
    })
  }

  const handleResetReview = async submissionId => {
    if (submissionId === null) {
      setSnackbar({
        open: true,
        message: 'No submission found!',
        messageType: 'error',
      })
      return
    }
    updateSubmission({
      mutation: UPDATE_SUBMISSION,
      variables: {
        id: submissionId,
        input: {
          reviewed: false,
        },
      },
    })
    setSnackbar({
      open: true,
      message: 'Review Reset Successfully',
      messageType: 'success',
    })
  }

  const handleSubmit = async () => {
    setSnackbar({
      open: true,
      message: 'Grade Submission in Progress',
      messageType: 'loading',
    })

    // const {
    //   data: {
    //     gradeAssignment: { success },
    //   },
    // } = submitGrades({
    await submitGrades({
      variables: {
        aid: assignmentId,
        pids: problemResponses.map(response => {
          return response.problemId
        }),
        input: getGradesAssignmentInput(),
      },
      refetchQueries: [
        {
          query: GET_GRADES_FROM_ASSIGNMENT,
          variables: {
            id: assignmentId,
          },
        },
      ],
    })
    const success = true
    setSnackbar({
      open: true,
      message: success ? 'Grades Submitted ✔' : 'error',
      messageType: success ? 'success' : 'error',
    })
  }

  const handleSaveComment = async (id, comment, index) => {
    setSnackbar({
      open: true,
      message: 'Saving Comment',
      messageType: 'loading',
    })
    await updateComments({
      variables: {
        id: id,
        input: {
          comment: comment,
        },
      },
    })
    const newProblemResponses = [...problemResponses]
    newProblemResponses[index].comment = comment
    setProblemResponse(newProblemResponses)

    const success = true
    setSnackbar({
      open: true,
      message: success ? 'Comment Saved ✔' : 'error',
      messageType: success ? 'success' : 'error',
    })
  }

  const handleForkIcon = (value, row) => {
    const handleIconClick = e => {
      handleGradeChange(row, value)
    }
    let icon
    if (value > 0) {
      icon = <CheckCircleOutlineIcon color="primary" />
    } else if (value === 0) {
      icon = <HighlightOffIcon color="secondary" />
    } else {
      icon = <RadioButtonUncheckedIcon color="disabled" />
    }
    return <IconButton onClick={handleIconClick}>{icon}</IconButton>
  }

  const getInnerTableCell = (key, value, row, problemResponses) => {
    let cellContent = <></>
    switch (key) {
      case 'correct':
        cellContent = handleForkIcon(value, row)
        break
      case 'points':
        cellContent = (
          <TextField
            type="number"
            variant="standard"
            onChange={e => {
              handlePartialGradeChange(row, parseInt(e.target.value))
            }}
            fullWidth
            value={value}
            InputProps={{
              inputProps: {
                min: 0,
                max: problemResponses[row].count,
              },
            }}
          />
        )
        break
      case 'order':
        cellContent = value
        break
      case 'correctAnswer':
      case 'studentAnswer':
        if (
          problemResponses[row].problemType === 'SCIENTIFIC_UNIT' &&
          value !== null &&
          value !== undefined
        ) {
          cellContent = <LaTeX latex={value} />
        } else {
          cellContent = value
        }
        break
      case 'attachment':
      case 'scratchpad':
        if (value) {
          cellContent = (
            <IconButton onClick={() => window.open(value)}>
              <AttachmentIcon color="primary" />
            </IconButton>
          )
        } else {
          cellContent = (
            <IconButton disabled>
              <AttachmentIcon color="disabled" />
            </IconButton>
          )
        }
        break
      case 'comment':
        if (value) {
          cellContent = (
            <IconButton
              onClick={() =>
                handleOpenCommentDialog(
                  problemResponses[row].responseId,
                  problemResponses[row].comment,
                  row
                )
              }
            >
              <EditIcon color="primary" />
            </IconButton>
          )
        } else {
          cellContent = (
            <IconButton
              onClick={() =>
                handleOpenCommentDialog(
                  problemResponses[row].responseId,
                  problemResponses[row].comment,
                  row
                )
              }
            >
              <AddIcon color="primary" />
            </IconButton>
          )
        }
        break
      default:
        cellContent = value
        break
    }
    return (
      <TableCell key={key} align={cellStyle.align} style={cellStyle}>
        {cellContent}
      </TableCell>
    )
  }

  const columns = [
    {
      Header: 'Problem',
      accessor: 'order',
      align: 'center',
      width: '90em',
    },
    {
      Header: 'Correct Answer',
      accessor: 'correctAnswer',
      align: 'center',
    },
    {
      Header: 'Student Answer',
      accessor: 'studentAnswer',
      align: 'center',
    },
    {
      Header: <CheckCircleOutlineIcon color="primary" />,
      align: 'center',
      width: '70em',
    },
    {
      Header: 'Max',
      accessor: 'count',
      align: 'center',
      width: '45em',
    },
    {
      Header: 'Earned',
      accessor: 'sum',
      align: 'center',
      width: '60em',
    },
    {
      Header: 'Attachment',
      accessor: 'Attachment',
      align: 'center',
      width: '60em',
    },
    {
      Header: 'Scratchpad',
      accessor: 'Scratchpad',
      align: 'center',
      width: '60em',
    },
    {
      Header: 'Comment',
      accessor: 'comment',
      align: 'center',
      width: '60em',
    },
  ]

  const firstName = student ? student.firstName : 'First Name'
  const lastName = student ? student.lastName : 'Last Name'
  const studentIndex = student
    ? students.findIndex(a => a.student.id === student.id)
    : -1
  const submission =
    studentIndex >= 0 ? students[studentIndex].submission : null
  const disableGrading =
    !(submission && submission.submittedAt != null) ||
    (submission && submission.excused)
  const submissionId = submission ? submission.id : null
  return (
    <Box
      sx={{
        textAlign: 'left',
        margin: 'auto',
        width: '67%',
        minWidth: '600px',
        maxWidth: '1400px',
      }}
    >
      <Link to={`/classroom/${classroomId}/grade`}>
        {'< back to Grade Assignment'}
      </Link>
      <TitleBar
        title={`Grading ${firstName} ${lastName}'s Assignment`}
        index={studentIndex}
        total={students.length}
        backArrow={
          studentIndex !== -1 && studentIndex !== 0
            ? `/classroom/${classroomId}/grade/${assignmentId}/${
                students[studentIndex - 1].student.id
              }`
            : ''
        }
        forwardArrow={
          studentIndex === -1 && students.length !== 0
            ? `/classroom/${classroomId}/grade/${assignmentId}/${students[0].student.id}`
            : studentIndex !== students.length - 1
            ? `/classroom/${classroomId}/grade/${assignmentId}/${
                students[studentIndex + 1].student.id
              }`
            : ''
        }
      />
      <SubmissionDates
        createdOn={submission ? submission.createdOn : null}
        submittedAt={submission ? submission.submittedAt : null}
      />
      <Grades
        graded={submission ? submission.graded : false}
        overallGrade={submission ? submission.overallGrade : null}
        count={submission ? submission.count : null}
        sum={submission ? submission.sum : null}
        excused={submission ? submission.excused : false}
      />
      <WorkAttributes
        attemptNumber={submission ? submission.attemptNumber : null}
        coins={submission ? submission.coins : null}
        reviewed={submission ? submission.reviewed : false}
        totalTimeSpent={submission ? submission.totalTimeSpent : null}
      />
      <Typography
        sx={{
          mt: 2,
          ml: 0,
          pt: 1,
          pb: 1,
          fontSize: '17px',
          backgroundColor: '#e0dede',
        }}
      >{`Assignment: ${assignmentTitle}`}</Typography>
      <Typography
        component={'span'}
        sx={{
          align: 'center',
          flexGrow: 0,
          width: '100%',
        }}
        justify="space-evenly"
      >
        <Table>
          <TableHead>
            <TableRow>
              {columns.map((data, index) => (
                <TableCell
                  key={index}
                  align={cellStyle.align}
                  width={data.width}
                  style={cellStyle}
                >
                  {data.Header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {problemResponses.map(
              (
                {
                  order,
                  correctAnswer,
                  studentAnswer,
                  _,
                  sum,
                  count,
                  attachmentUrl,
                  scratchpadUrl,
                  comment,
                },
                row
              ) => (
                <TableRow key={row} hover={true}>
                  {[
                    { key: 'order', value: order },
                    { key: 'correctAnswer', value: correctAnswer },
                    { key: 'studentAnswer', value: studentAnswer },
                    { key: 'correct', value: sum },
                    { key: 'max', value: count },
                    { key: 'points', value: sum },
                    { key: 'attachment', value: attachmentUrl },
                    { key: 'scratchpad', value: scratchpadUrl },
                    { key: 'comment', value: comment },
                  ].map(column => {
                    return getInnerTableCell(
                      column.key,
                      column.value,
                      row,
                      problemResponses
                    )
                  })}
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row-reverse',
            p: 1,
            mt: 1,
          }}
        >
          <Button variant="contained" onClick={handleSubmit} disabled={false}>
            Submit
          </Button>
          <Button
            sx={{ mr: 2 }}
            variant="contained"
            onClick={() => handleRepair(submissionId)}
            disabled={disableGrading}
          >
            Repair
          </Button>
          <Button
            sx={{ mr: 2 }}
            variant="contained"
            onClick={() => handleResetReview(submissionId)}
            disabled={disableGrading}
          >
            Reset Review
          </Button>
          <Button
            sx={{ mr: 2 }}
            variant="contained"
            onClick={() => handleResetSubmission(submissionId)}
            disabled={disableGrading}
          >
            Reset Submission
          </Button>
          <Button
            sx={{ mr: 2 }}
            variant="contained"
            onClick={() => handleExcuseAssignment(submissionId)}
            disabled={submission && submission.excused}
          >
            Excuse Assignment
          </Button>
        </Box>
        <CommentDialog
          open={commentDialog.open}
          id={commentDialog.id}
          index={commentDialog.index}
          comment={commentDialog.comment}
          handleClose={handleCloseCommentDialog}
          handleSaveComment={handleSaveComment}
        />
      </Typography>
      <SnackbarNotification
        open={snackbar.open}
        handleClose={() => {
          setSnackbar({
            ...snackbar,
            open: false,
          })
        }}
        message={snackbar.message}
        messageType={snackbar.messageType}
      />
    </Box>
  )
}

const TitleBar = ({ title, index, total, backArrow, forwardArrow }) => {
  return (
    <Grid
      container={true}
      sx={{
        mt: 2,
        mb: 1,
        justifyContent: 'space-between',
      }}
    >
      <Typography variant="h4">{title}</Typography>
      <Box
        sx={{
          alignSelf: 'flex-end',
        }}
        aria-label="navitation of student list"
      >
        <Button disabled={index === -1 || index === 0}>
          <Link replace={true} to={backArrow}>
            {'< Prev'}
          </Link>
        </Button>
        <Button disabled={(index === -1 && total === 0) || index === total - 1}>
          <Link replace={true} to={forwardArrow}>
            {'Next >'}
          </Link>
        </Button>
      </Box>
    </Grid>
  )
}

const SubmissionDates = ({ createdOn, submittedAt }) => {
  return (
    <Grid
      container={true}
      sx={{
        display: 'flex',
      }}
    >
      <Typography
        sx={{
          fontWeight: 'bold',
          mr: 1,
        }}
      >
        Created On:
      </Typography>
      <Typography>
        {createdOn ? convertToDateTimeString(createdOn) : 'N/A'}
      </Typography>
      <Typography
        sx={{
          fontWeight: 'bold',
          ml: 3,
          mr: 1,
        }}
      >
        Submitted At:
      </Typography>
      <Typography>
        {submittedAt
          ? convertToDateTimeString(submittedAt)
          : 'Not Yet Submitted'}
      </Typography>
    </Grid>
  )
}

const Grades = ({ graded, overallGrade, count, sum, excused }) => {
  return (
    <Grid
      container={true}
      sx={{
        display: 'flex',
      }}
    >
      <Typography
        sx={{
          fontWeight: 'bold',
          mr: 1,
        }}
      >
        Graded:
      </Typography>
      {graded && <CheckBoxIcon color="primary" />}
      {!graded && <HelpIcon color="secondary" />}
      <Typography
        sx={{
          fontWeight: 'bold',
          ml: 3,
          mr: 1,
        }}
      >
        Overall Grade:
      </Typography>
      <Typography>
        {excused ? `Execused` : graded ? `${overallGrade}%` : 'N/A'}
      </Typography>
      <Typography
        sx={{
          fontWeight: 'bold',
          ml: 3,
          mr: 1,
        }}
      >
        Maximum Points:
      </Typography>
      <Typography>{count}</Typography>
      <Typography
        sx={{
          fontWeight: 'bold',
          ml: 3,
          mr: 1,
        }}
      >
        Earned Points:
      </Typography>
      <Typography>{excused ? `Execused` : sum}</Typography>
    </Grid>
  )
}

const WorkAttributes = ({
  attemptNumber,
  coins,
  reviewed,
  totalTimeSpent,
  execused,
}) => {
  return (
    <Grid
      container={true}
      sx={{
        display: 'flex',
      }}
    >
      <Typography
        sx={{
          fontWeight: 'bold',
          mr: 1,
        }}
      >
        Number of Attempts:
      </Typography>
      <Typography>{execused ? `Execused` : attemptNumber}</Typography>
      <Typography
        sx={{
          fontWeight: 'bold',
          ml: 3,
          mr: 1,
        }}
      >
        Coins:
      </Typography>
      <Typography>{execused || !coins ? 'N?A' : coins}</Typography>
      <Typography
        sx={{
          fontWeight: 'bold',
          ml: 3,
          mr: 1,
        }}
      >
        Reviewed:
      </Typography>
      {reviewed && <ThumbUpIcon color="primary" />}
      {!reviewed && <ThumbDownIcon color="secondary" />}

      <Typography
        sx={{
          fontWeight: 'bold',
          ml: 3,
          mr: 1,
        }}
      >
        Total Time Spent:
      </Typography>
      <Typography>
        {totalTimeSpent ? `${totalTimeSpent} seconds` : 'N/A'}
      </Typography>
    </Grid>
  )
}

export default GradeStudentAssignment
