import React, { useState } from 'react'

import { useMutation } from '@apollo/client'
import { UPDATE_TRIAL } from './queries'

import {
  Box,
  Button,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material'

import { StatusSelector } from '../../components'

const trialStatuses = ['SCHEDULED', 'RESCHEDULED', 'NOSHOW', 'ATTENDED']

const TrialForm = ({ classes, trial }) => {
  const trialId = trial.id
  const [status, setStatus] = useState(trial.status)
  const [notes, setNotes] = useState(trial.notes)
  const [trialInfoChanged, setTrialInfoChanged] = useState(false)

  const [handleSaveTrialInfo] = useMutation(UPDATE_TRIAL, {
    variables: {
      id: trialId,
      input: {
        status: status,
        notes: notes,
      },
    },
    onCompleted: () => {
      setTrialInfoChanged(false)
    },
  })

  const handleChange = event => {
    const { name, value } = event.target
    if (name === 'status') setStatus(value)
    if (name === 'notes') setNotes(value)
    setTrialInfoChanged(true)
  }

  const {
    followupStatus,
    followupResult,
    followupDate,
    toClassTitle,
    toLessonTitle,
    followupBy: followupEmployee,
  } = trial

  return (
    <Box
      column
      sx={{
        align: 'center',
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 2,
            m: '20px',
            maxWidth: '400px',
          }}
        >
          <TrialLesson classTitle={toClassTitle} lessonTitle={toLessonTitle} />
          <TrialStatus
            status={status}
            statusOptions={trialStatuses}
            handleChange={handleChange}
          />
          <TextField
            name="notes"
            label="Notes"
            variant="standard"
            multiline
            maxrows="4"
            value={notes || ''}
            onChange={handleChange}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 2,
            m: '20px',
            maxWidth: '400px',
          }}
        >
          <FollowupStatus followupStatus={followupStatus} />
          <FollowupInfo
            followupDate={followupDate}
            followupEmployee={followupEmployee}
            followupResult={followupResult}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
          alignItems: 'center',
        }}
      >
        <Button
          variant="contained"
          onClick={handleSaveTrialInfo}
          disabled={!trialInfoChanged}
        >
          Save Changes
        </Button>
      </Box>
    </Box>
  )
}

const TrialLesson = ({ classTitle, lessonTitle }) => {
  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 0, width: '45ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        name="toClassroom"
        label="To Class"
        variant="standard"
        sx={{ py: 1, width: '100%' }}
        disabled={true}
        value={classTitle}
      />
      <TextField
        name="toLesson"
        label="To Lesson"
        variant="standard"
        sx={{ py: 1, width: '100%' }}
        disabled={true}
        value={lessonTitle || ''}
      />
    </Box>
  )
}

const TrialStatus = ({ status, statusOptions, handleChange }) => {
  return (
    <StatusSelector
      label="Status"
      status={status}
      statusOptions={statusOptions}
      handleChange={handleChange}
      selectedOptionId={status}
    />
  )
}

const FollowupStatus = ({ followupStatus }) => {
  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '45ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <FormLabel component="legend" sx={{ fontSize: 12 }}>
        Followup Status
      </FormLabel>
      <RadioGroup
        name="followupStatus"
        aria-label="followupStatus"
        label="Followup Status"
        value={followupStatus}
        style={{ display: 'flex', flexDirection: 'row' }}
      >
        <FormControlLabel
          value="Yes"
          control={<Radio color="primary" />}
          disabled={true}
          label="Yes"
        />
        <FormControlLabel
          value="No"
          control={<Radio color="secondary" />}
          disabled={true}
          label="No"
        />
      </RadioGroup>
    </Box>
  )
}

const FollowupInfo = ({ followupDate, followupEmployee, followupResult }) => {
  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '45ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        name="followupDate"
        label="Followup Date"
        variant="standard"
        disabled={true}
        value={followupDate || ''}
      />
      <TextField
        name="followupEmployee"
        label="Followup Employee"
        variant="standard"
        disabled={true}
        value={followupEmployee || ''}
      />
      <TextField
        name="followupResult"
        label="Followup Result"
        variant="standard"
        multiline
        maxrows="4"
        disabled={true}
        value={followupResult || ''}
      />
    </Box>
  )
}

export default TrialForm
