import React from 'react'
import { Route, Switch } from 'react-router-dom'

import ResponseTableView from './ResponseTableView'

const ResponseView = props => {
  const url = props.match.url
  return (
    <Switch>
      <Route path={url} component={ResponseTableView} />
    </Switch>
  )
}

export default ResponseView
