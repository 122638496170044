import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import { compareStudents } from './utils'
import {
  ErrorMessage,
  Flex,
  InnerClassTabBar,
  LoadingMessage,
  Paper,
  SnackbarNotification,
} from '../../components'
import { GET_STUDENTS_IN_CLASS, SET_STUDENT_PASSWORD } from './queries'
import { TableFork, TableHeader } from './TableForkUtil'
import EmailMessage from './EmailMessage'
import TextMessage from './TextMessage'
import StudentColumn from './StudentColumn'

const ClassView = props => {
  const path = props.match.params[0]
  const split = path.indexOf('/')
  const classroomId = split === -1 ? path : path.substring(0, split)
  const viewType = path.substring(split + 1)
  const instructorName = localStorage.getItem('employeeName')
  const { loading, error, data } = useQuery(GET_STUDENTS_IN_CLASS, {
    fetchPolicy: 'network-only',
    variables: { id: classroomId },
  })

  if (loading) return <LoadingMessage />
  if (error) return <ErrorMessage />

  const classroomCode = data.classroom.code
  const classroomTitle = `${classroomCode}`
  const { endTime, enrollments } = data.classroom
  const students = enrollments
    .map(enrollment => {
      const { student } = enrollment
      const { primaryFamilyMember } = enrollment.student.familyAccount
      return {
        id: enrollment.id,
        studentId: student.id,
        imageUrl:
          student.imageUrl && student.imageUrl.indexOf('generic-07.png') === -1
            ? student.imageUrl
            : null,
        firstName: student.firstName,
        lastName: student.lastName,
        type: enrollment.status,
        gradeUpdated: student.gradeUpdated,
        username: student.username,
        birthdate: student.birthdate,
        gender: student.gender,
        primaryGuardian:
          primaryFamilyMember.firstName + ' ' + primaryFamilyMember.lastName,
        email: primaryFamilyMember.email,
        phoneNumber: primaryFamilyMember.phoneNumber,
        lastLogin: student.lastLogin,
        enrollDate: enrollment.createdOn,
        overallGrade: enrollment.overallGrade,

        familyMemberId: primaryFamilyMember.id,
        familyMember: primaryFamilyMember,

        active: student.active,
      }
    })
    .sort((a, b) =>
      compareStudents(a.lastName, b.lastName, a.firstName, b.firstName)
    )

  return (
    <ClassDetailMainInnerView
      {...props}
      students={students}
      classroomId={classroomId}
      viewType={viewType}
      classroomTitle={`Class: ${classroomTitle}`}
      classroomCode={classroomCode}
      instructorName={instructorName}
      endTime={endTime}
    />
  )
}

const ClassDetailMainInnerView = React.memo(
  ({
    students,
    classroomId,
    viewType,
    classroomTitle,
    client,
    classroomCode,
    instructorName,
    endTime,
  }) => {
    const [fireAction, setFireAction] = useState(null)
    const [lesson, setLesson] = useState(localStorage.getItem('lesson') || '')
    const [assignment, setAssignment] = useState(
      localStorage.getItem('assignment') || ''
    )
    const [selectAll, setSelectAll] = useState(false)
    const [checked, setChecked] = useState(
      new Array(students.length).fill(false)
    )
    const [snackbar, setSnackbar] = useState({
      open: false,
      message: '',
      messageType: '',
      windowReload: false,
    })
    const [openEmailDialog, setOpenEmailDialog] = useState(false)
    const [openTextDialog, setOpenTextDialog] = useState(false)

    useEffect(() => {
      //Clear gradebook redirects after loading them to state
      localStorage.removeItem('lesson')
      localStorage.removeItem('assignment')
    }, [])

    const checkedStudents = useMemo(
      () =>
        students.filter((student, index) => {
          return checked[index] ? student : null
        }),
      [checked, students]
    )

    const checkedFamilyMemberIds = useMemo(() => {
      if (checkedStudents.length !== 0) {
        return checkedStudents.map(student => student.familyMemberId)
      }
      return []
    }, [checkedStudents])

    const checkedPhoneNumbers = useMemo(() => {
      if (checkedStudents.length !== 0) {
        return checkedStudents.map(student => student.phoneNumber)
      }
      return []
    }, [checkedStudents])

    //Don't add checked or selected to the dependecy array
    //It will rerender everything
    const handleSingleCheck = useCallback(
      index => {
        checked[index] = !checked[index]
        setChecked([...checked])
        setSelectAll(!checked[index] && selectAll ? false : null)
        console.log('handleSingle2 ', checked)
      },
      [setChecked]
    )

    const handleOpenTextDialog = useCallback(() => {
      setOpenTextDialog(true)
    }, [])

    const handleOpenEmailDialog = useCallback(() => {
      console.log('Class View')
      setOpenEmailDialog(true)
    }, [])

    const handleCloseTextDialog = useCallback(() => {
      setOpenTextDialog(false)
    }, [])

    const handleCloseEmailDialog = useCallback(() => {
      setOpenEmailDialog(false)
    }, [])

    /**
     * Putting this in a callback probably won't do much
     */
    const handleLessonChange = useCallback(
      value => {
        setLesson(value)
        setAssignment('')
      },
      [setLesson, setAssignment]
    )

    /**
     * Putting this in a callback probably won't do much
     */
    const handleAssignmentChange = useCallback(
      value => {
        setAssignment(value)
      },
      [setAssignment]
    )

    const [resetStudentPassword] = useMutation(SET_STUDENT_PASSWORD)
    const handleResetPassword = async () => {
      setSnackbar({
        open: true,
        message: 'Reseting Student Password(s) to "Student"',
        messageType: 'loading',
      })
      const checkedIds = checkedStudents.map(student => student.studentId)
      const resetPasswordResponses = await Promise.all(
        checkedIds.map(async id => {
          return await resetStudentPassword({
            variables: {
              id: id,
              input: {
                password: 'student',
              },
            },
          })
        })
      )
      const success = resetPasswordResponses.every(
        res => res.data.updateStudent.id
      )
      setSnackbar({
        open: true,
        message: success ? 'Password(s) Reset ✔' : 'error',
        messageType: success ? 'success' : 'error',
      })
    }

    /**
     * Changes the state of fireAction
     *
     * fireAction is changed from header ->
     * table checks if there is a matching actionType ->
     * calls handleSiblingAction with the corrposonding function from table
     *
     */
    const handleActionChange = useCallback(
      e => {
        setFireAction(e.currentTarget.value)
      },
      [setFireAction]
    )

    /**
     * This function takes in a callback function
     * It is used to communicate between siblings: header and table
     *
     * fireAction is the trigger
     */
    const handleSiblingAction = useCallback(async handlerFunction => {
      await handlerFunction()
      setFireAction(null)
    }, [])

    return (
      <Flex column>
        <Flex column style={{ marginBottom: '.5%' }}>
          <InnerClassTabBar classId={classroomId} viewType={viewType} />
        </Flex>
        <Flex column>
          <Paper
            square
            elevation={0}
            style={{
              minWidth: '100%',
              justifyContent: 'center',
              shrink: 1,
            }}
          >
            <Paper
              elevation={2}
              square={true}
              style={{
                height: '100%',
                justifyContent: 'center',
                alignItems: 'right',
              }}
            >
              <TableHeader
                type={viewType}
                classId={classroomId}
                classroomTitle={classroomTitle}
                lesson={lesson}
                assignment={assignment}
                setFireAction={setFireAction}
                handleOpenTextDialog={handleOpenTextDialog}
                handleOpenEmailDialog={handleOpenEmailDialog}
                handleActionChange={handleActionChange}
                handleLessonChange={handleLessonChange}
                handleAssignmentChange={handleAssignmentChange}
                handleResetPassword={handleResetPassword}
                students={students}
              />
            </Paper>
            <Flex align="left" grow={1}>
              <Flex>
                <StudentColumn
                  students={students}
                  viewType={viewType}
                  checked={checked}
                  handleSingleCheck={handleSingleCheck}
                />
              </Flex>
              <TableFork
                type={viewType}
                fireAction={fireAction}
                handleSiblingAction={handleSiblingAction}
                checked={checked}
                classId={classroomId}
                lesson={lesson}
                assignment={assignment}
                setSnackbar={setSnackbar}
                client={client}
                students={students}
              />
            </Flex>
          </Paper>
        </Flex>
        {openEmailDialog && (
          <EmailMessage
            open={openEmailDialog}
            handleClose={handleCloseEmailDialog}
            setSnackbar={setSnackbar}
            familyMemberIds={checkedFamilyMemberIds}
            checkedStudents={checkedStudents}
          />
        )}
        {openTextDialog && (
          <TextMessage
            open={openTextDialog}
            handleClose={handleCloseTextDialog}
            setSnackbar={setSnackbar}
            phoneNumbers={checkedPhoneNumbers}
            checkedStudents={checkedStudents}
            classCode={classroomCode}
            instructor={instructorName}
            endTime={endTime}
          />
        )}
        <SnackbarNotification
          open={snackbar.open}
          handleClose={() => {
            setSnackbar({ ...snackbar, open: false })
          }}
          message={snackbar.message}
          messageType={snackbar.messageType}
        />
      </Flex>
    )
  }
)

export default ClassView
