import React, { useState } from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  LoadingMessage,
  ErrorMessage,
  Flex,
  Typography,
  ImageFileUploader,
} from '../../components'

import { Button } from '@mui/material'
import { useQuery, useMutation } from '@apollo/client'
import { GET_ATTENDEE, UPDATE_ATTENDEE, UPDATE_STUDENT } from './queries'
import { StatusIcon } from './Icons'
import { mapStatusToLabel } from './utils'

import { TextField } from '@mui/material'

const attendanceStatus = [
  'ONTIME',
  'ABSENT',
  'LATE',
  'EARLY',
  'EXEMPT',
  'UNKNOWN',
]
const AttendeeHeader = ({ student, client, setImage, image }) => {
  return (
    <Flex style={{ position: 'relative' }}>
      <Flex style={{ left: 0 }}>
        <ImageFileUploader
          src={student.imageUrl}
          alt="student profile"
          style={{ width: '200ppx', height: '200px' }}
          client={client}
          setImage={setImage}
          imageKey={image}
        />
      </Flex>
      <Flex column style={{ right: 0, paddingLeft: '2em' }}>
        <Flex column style={{ position: 'absolute', top: 0 }}>
          <Typography variant="h3">{student.firstName}</Typography>
          <Typography variant="h3">{student.lastName}</Typography>
        </Flex>
        <Flex column style={{ position: 'absolute', bottom: 0 }}>
          <Typography>Grade: {student.grade}</Typography>
          <Typography>School: {student.school}</Typography>
        </Flex>
      </Flex>
    </Flex>
  )
}

const AttendeeForm = ({ attendee, open, handleClose, refetch, client }) => {
  const { student } = attendee
  const [status, setStatus] = useState(attendee.status)
  const [timeAdjustment, setTimeAdjustment] = React.useState(
    attendee.timeAdjustment
  )
  const [note, setNote] = useState(attendee.note)
  const [image, setImage] = useState(student.image)

  const [updateAttendee] = useMutation(UPDATE_ATTENDEE)
  const [updateStudent] = useMutation(UPDATE_STUDENT)
  const handleSubmit = async () => {
    await updateAttendee({
      variables: {
        id: attendee.id,
        input: {
          status: status,
          note: note,
          timeAdjustment: parseInt(timeAdjustment),
        },
      },
    })

    //Only need to update the student if the student profile picture has been changed
    if (image !== student.image) {
      await updateStudent({
        variables: {
          id: student.id,
          input: {
            image: image,
          },
        },
      })
    }

    refetch()
    handleClose()
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <AttendeeHeader
          student={student}
          client={client}
          setImage={setImage}
          image={image}
        />
        <hr />
      </DialogTitle>
      <DialogContent>
        <Flex column>
          <Flex>
            {attendanceStatus.map((name, index) => (
              <MenuItem
                value={name}
                key={index}
                selected={name === status ? true : false}
                onClick={() => setStatus(name)}
              >
                <Flex column align="center">
                  <StatusIcon status={name} />
                  {mapStatusToLabel(name)}
                </Flex>
              </MenuItem>
            ))}
          </Flex>
          <Flex>
            <TextField
              onChange={event => setNote(event.target.value)}
              value={note || ''}
              placeholder="Additional Notes"
              multiline
              rows={5}
              rowsmax={5}
              variant="outlined"
              fullWidth
              style={{ marginTop: '2em' }}
            />
          </Flex>
          {(status === 'LATE' || status === 'EARLY') && (
            <Flex>
              <TextField
                onChange={event => setTimeAdjustment(event.target.value)}
                value={timeAdjustment}
                style={{ width: '25%', marginTop: '1em' }}
                type="number"
                label={
                  status === 'LATE' ? 'Minutes Late' : 'Minutes Left Early'
                }
              />
            </Flex>
          )}
        </Flex>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const AttendanceDialog = ({
  open,
  handleClose,
  attendeeId,
  classroomId,
  refetch,
  client,
  ...rest
}) => {
  const getAttendee = useQuery(GET_ATTENDEE, {
    fetchPolicy: 'no-cache',
    variables: { id: attendeeId },
  })
  const { loading, error, data } = getAttendee
  if (loading)
    return (
      <Dialog open={open}>
        <LoadingMessage />
      </Dialog>
    )
  if (error) return <ErrorMessage error={error} />
  return (
    <AttendeeForm
      attendee={data.attendee}
      open={open}
      handleClose={handleClose}
      refetch={refetch}
      client={client}
    />
  )
}

export default AttendanceDialog
