import React from 'react'
import ReactTable from 'react-table-6'

import { dateFilterMethod } from '../../utils/datetime'

const ClassTable = ({
  classrooms,
  filtered,
  handleFilteredChange,
  handleRowClick,
}) => {
  const defaultSorted = [{ id: 'code' }]

  return (
    <div align="left">
      <ReactTable
        data={classrooms}
        defaultFiltered={filtered}
        onFilteredChange={handleFilteredChange}
        columns={columns}
        filterable={true}
        defaultSorted={defaultSorted}
        className="-highlight"
        style={{ fontSize: '10pt' }}
        defaultPageSize={25}
        defaultFilterMethod={(filter, row) =>
          row[filter.id] &&
          row[filter.id]
            .toString()
            .toLowerCase()
            .includes(filter.value.toString().toLowerCase())
        }
        getTdProps={(state, rowInfo, column, instance) => {
          return {
            onClick: (e, handleOriginal) => {
              if (column.Header !== undefined) {
                handleRowClick(rowInfo)
              }
              if (handleOriginal) {
                handleOriginal()
              }
            },
          }
        }}
        getTheadProps={(state, rowInfo, column, instance) => {
          return {
            style: {
              fontWeight: 'bold',
              borderBottom: '1px solid #E5E5E5',
            },
          }
        }}
      />
    </div>
  )
}

const columns = [
  {
    Header: 'Start Date',
    accessor: 'startDate',
    width: 100,
    filterMethod: dateFilterMethod,
  },
  {
    Header: 'End Date',
    accessor: 'endDate',
    width: 100,
    filterMethod: dateFilterMethod,
  },
  {
    Header: 'Quarter',
    accessor: 'quarter',
    width: 100,
    Cell: props => {
      return <span>{props.value ? props.value : 'UNKNOWN'}</span>
    },
    filterMethod: (filter, row) => {
      if (filter.value !== 'ALL' && filter.value !== 'UNKNOWN') {
        return row[filter.id] === filter.value
      }
      if (filter.value === 'UNKNOWN') {
        return row[filter.id] === null
      }
      return true
    },
    Filter: ({ filter, onChange }) => (
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'ALL'}
      >
        <option value="ALL">ALL</option>
        <option value="Fall">Fall</option>
        <option value="Winter">Winter</option>
        <option value="Spring">Spring</option>
        <option value="PreSummer">PreSummer</option>
        <option value="Summer">Summer</option>
        <option value="OnDemand">OnDemand</option>
        <option value="Tutoring">Tutoring</option>
      </select>
    ),
  },
  {
    Header: 'Year',
    accessor: 'year',
    width: 100,
  },
  {
    Header: 'Class Code',
    accessor: 'code',
    width: 100,
  },
  {
    Header: 'Class Title',
    accessor: 'title',
    width: 250,
  },
  {
    Header: 'Enrollments',
    accessor: 'enrollmentCount',
    style: { textAlign: 'right' },
    width: 100,
  },
  {
    Header: 'Day',
    accessor: 'day',
    width: 100,
  },
  {
    Header: 'Time',
    accessor: 'time',
    width: 150,
  },
  {
    Header: 'Location',
    accessor: 'centerLocation',
    width: 150,
  },
  {
    Header: 'Instructor',
    accessor: 'instructor',
    width: 200,
  },
]
export default ClassTable
