import React from 'react'
import { Flex, ColorizeIcon, Divider } from '../../../components'
import { Button, Tooltip } from '@mui/material'

export const LessonHeader = React.memo(({ handleActionChange }) => (
  <React.Fragment>
    <Divider orientation="vertical" flexItem style={{ marginLeft: 'auto' }} />
    <Tooltip title={'Colorize Grades'}>
      <Button
        variant="text"
        style={{ width: '6em' }}
        color="primary"
        value="colorize"
        onClick={handleActionChange}
      >
        <Flex column>
          <Flex style={{ marginBottom: '.5em', justifyContent: 'center' }}>
            <ColorizeIcon />
          </Flex>
        </Flex>
      </Button>
    </Tooltip>
  </React.Fragment>
))

export default LessonHeader
