import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import Cookies from 'js-cookie'
import { idName } from '../../config'

import TutorSessionTable from './TutorSessionTable'
import { Paper } from '@mui/material'
import { ErrorMessage, LoadingMessage } from '../../components'
import { GET_TUTOR_SESSIONS } from './queries'
import { convertToMDY } from '../../utils/datetime'
import TutorSessionSidebar from './TutorSessionSidebar'
import { SELECTABLE_TIMES } from './timeUtils'
import { useHistory } from 'react-router-dom'

const TutorSessionView = props => {
  const history = useHistory()
  const employeeId = Cookies.get(idName)

  const [userAuthorized] = useState(() => {
    const value = localStorage.getItem('userAuthorized')
    const userAuthorized = JSON.parse(value)
    return userAuthorized || false
  })
  const [isAdmin] = useState(() => {
    const value = localStorage.getItem('isAdmin')
    const isAdmin = JSON.parse(value)
    return isAdmin || false
  })

  const [myClassrooms, setMyClassrooms] = useState(true)
  const [filtered, setFiltered] = useState([])

  const [selectedTime, setSelectedTime] = useState(SELECTABLE_TIMES[0])
  const { startDate, endDate } = selectedTime.getRange()

  const getTutorSessions = useQuery(GET_TUTOR_SESSIONS, {
    variables: {
      fetchPolicy: 'network-only',
      filter: {
        startDateTime: {
          $gteDate: startDate,
          $lteDate: endDate,
        },
      },
    },
  })
  if (!userAuthorized) return null

  const { data, loading, error } = getTutorSessions
  if (loading) return <LoadingMessage />
  if (error) return <ErrorMessage error={error} />

  const tutorSessions = data
    ? data.tutorSessions
        .filter(
          tutorSession => !myClassrooms || tutorSession.tutor.id === employeeId
        )
        .map(tutorSession => formatTutorSession(tutorSession))
    : []

  const handleRowClick = rowInfo => {
    history.push(`/tutee/${rowInfo.original.id}`)
  }

  const handleFilteredChange = filtered => {
    setFiltered(filtered)
  }

  const handleSelectedTimeRange = timeRange => {
    setSelectedTime(timeRange)
  }

  return (
    <Paper sx={{ display: 'flex' }}>
      <TutorSessionSidebar
        isAdmin={isAdmin}
        times={SELECTABLE_TIMES}
        selectedTime={selectedTime}
        onSelectedTimeChange={handleSelectedTimeRange}
        tutorSessions={tutorSessions}
        myClassrooms={myClassrooms}
        handleMyClassrooms={setMyClassrooms}
      />
      <Paper sx={{ width: '100%' }}>
        <TutorSessionTable
          tutorSessions={tutorSessions}
          filtered={filtered}
          handleRowClick={handleRowClick}
          handleFilteredChange={handleFilteredChange}
        />
      </Paper>
    </Paper>
  )
}

const formatTutorSession = ({
  id,
  status,
  student,
  classroom,
  order,
  startDateTime,
  duration,
  tutor,
}) => ({
  id: id,
  status: status,
  firstName: student.firstName,
  lastName: student.lastName,
  code: classroom.code,
  order: order,
  startDate: convertToMDY(startDateTime),
  tutor: `${tutor.firstName}, ${tutor.lastName}`,
  duration: duration,
})

export default TutorSessionView
