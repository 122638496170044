import React from 'react'
import { Link, withRouter } from 'react-router-dom'

import { Paper, Tab, Tabs } from '@mui/material'
import withStyles from './Styles'

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabsRoot: {
    maxHeight: '48px',
  },
  tabsIndicator: {
    backgroundColor: theme.palette.background.paper,
  },
  tabRoot: {
    maxHeight: '48px',
  },
})

const InnerClassTabBar = React.memo(({ classId, viewType, classes }) => {
  const tabs = [
    {
      name: 'enrollment',
      displayText: 'Enrollment',
      path: `/classroom/${classId}/enrollment`,
    },
    {
      name: 'lesson',
      displayText: 'Lesson',
      path: `/classroom/${classId}/lesson`,
    },
    {
      name: 'assignment',
      displayText: 'Assignment',
      path: `/classroom/${classId}/assignment`,
    },
    {
      name: 'grade',
      displayText: 'Grade',
      path: `/classroom/${classId}/grade`,
    },
    {
      name: 'attendance',
      displayText: 'Attendance',
      path: `/classroom/${classId}/attendance`,
    },
  ]

  const determineTabValue = () => {
    const tabIndex = tabs.findIndex(tab => tab.name === viewType)
    return tabIndex >= 0 ? tabIndex : 0
  }

  return (
    <div className={classes.root}>
      <Paper square>
        <Tabs
          classes={{
            root: classes.tabsRoot,
            indicator: classes.tabsIndicator,
          }}
          value={determineTabValue()}
          variant="scrollable"
          textColor="primary"
        >
          {tabs.map((tab, index) => (
            <Tab
              classes={{ root: classes.tabRoot }}
              label={tab.displayText}
              component={Link}
              key={tab.name}
              to={tab.path}
            />
          ))}
        </Tabs>
      </Paper>
    </div>
  )
})

export default withRouter(withStyles(styles)(InnerClassTabBar))
