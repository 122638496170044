import { gql } from '@apollo/client'

export const GET_PROBLEM_RESPONSES = gql`
  query problemResponses($filter: Filters, $sort: Sorts) {
    problemResponses(filter: $filter, sort: $sort) {
      id
      createdOn
      count
      sum
      percentCorrect
      timeSpent
      problem {
        id
        order
      }
      submission {
        id
        lesson {
          id
          lessonPlan {
            id
            order
          }
        }
        assignment {
          id
          problemSet {
            id
            order
          }
        }
        classroom {
          id
          code
        }
        student {
          id
          firstName
          lastName
        }
      }
    }
  }
`
