import React from 'react'
import Text from './Text'

const ErrorMessage = ({ error, padding = '20px', variant = 'h5' }) => (
  <Text style={{ padding: padding }} variant={variant}>
    Error: {error.message}
  </Text>
)

export default ErrorMessage
