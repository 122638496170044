import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  Fragment,
} from 'react'
import Tooltip from '@mui/material/Tooltip'
import {
  Flex,
  Typography,
  Accordion,
  AccordionDetails,
  Divider,
  LaTeX,
  SnackbarNotification,
  IconButton,
  CheckIcon,
  LinkIcon,
  makeStyles,
  CustomAccordionSummary,
} from '../../components'
import {
  UPDATE_PROBLEM,
  UPDATE_CHOICE,
  UPDATE_PROBLEM_RESPONSE,
  UPDATE_ISSUE,
} from './queries'
import Choice from './Choice'
import EditableTextField from './EditableTextField'
import { useMutation } from '@apollo/client'

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  textLabel: {
    alignSelf: 'start',
    marginLeft: '0.60vw',
    marginTop: '1%',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '12px',
  },
  textLabel2: {
    marginTop: 'auto',
    padding: 5,
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '13px',
  },
  imageDisplay: {
    width: '60%',
    height: 'auto',
  },
  textPreview: {
    width: '100%',
    borderRadius: '4px',
    border: '1px solid rgb(187, 187, 187)',
  },
  iconGroup: {
    marginLeft: 'auto',
  },
  iconButton: {
    width: 42,
    height: 42,
  },
  icon: {
    width: 24,
    height: 24,
  },
}))

export const ProblemPanel = forwardRef((props, ref) => {
  const classes = useStyles()
  const [problemData] = useState(props.problem)
  if (problemData.choices.length > 0) {
    let choices = problemData.choices.map(o => o)
    for (let i = 0; i < problemData.choices.length; i++) {
      problemData.choices[i] =
        choices[choices.findIndex(c => c.order === i + 1)]
    }
  }
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    messageType: '',
  })
  const { edits } = props
  const { problem } = edits
  const { questionText, solutionText, answer } = problem
  const studentAnswer = edits.problemResponse

  const [updateIssue] = useMutation(UPDATE_ISSUE)
  useImperativeHandle(ref, () => ({
    async sendSolution() {
      if (solutionText) {
        await updateIssue({
          variables: {
            id: props.id,
            input: {
              responsibleEmployeeId: props.employeeId,
              dateAssigned: new Date().toISOString(),
              dateResolved: new Date().toISOString(),
              isRead: false,
              issueStatus: 'RESOLVED',
              response: solutionText,
            },
          },
        })
        props.updateTable()
        props.handleClose()
      } else {
        window.alert('Error: There is no solution for this problem.')
      }
    },
  }))

  const [updateProblemResponse] = useMutation(UPDATE_PROBLEM_RESPONSE)
  const handleCorrectResponse = async (id, sum) => {
    let success = false
    setSnackbar({
      open: true,
      message: 'Correcting Problem Response',
      messageType: 'loading',
    })
    await updateProblemResponse({
      variables: {
        id: id,
        input: {
          sum: sum,
        },
      },
    }).then(() => {
      success = true
    })
    setSnackbar({
      open: true,
      message: success ? 'Problem Response Corrected' : 'error',
      messageType: success ? 'success' : 'error',
    })
  }

  const handleUpdateStudentAnswer = async (id, inputText) => {
    let success = false
    setSnackbar({
      open: true,
      message: 'Editing Student Answer',
      messageType: 'loading',
    })
    await updateProblemResponse({
      variables: {
        id: id,
        input: {
          value: inputText ? inputText : '',
        },
      },
    }).then(() => {
      success = true
    })
    setSnackbar({
      open: true,
      message: success ? 'Student Answer Edited' : 'error',
      messageType: success ? 'success' : 'error',
    })
  }

  const [updateProblem] = useMutation(UPDATE_PROBLEM)
  const handleUpdateAnswer = async inputText => {
    let success = false
    setSnackbar({
      open: true,
      message: 'Updating Answer',
      messageType: 'loading',
    })
    await updateProblem({
      variables: {
        id: edits.problem.id,
        input: {
          answer: inputText ? inputText : '',
        },
      },
    }).then(() => {
      success = true
    })
    setSnackbar({
      open: true,
      message: success ? 'Answer Updated' : 'error',
      messageType: success ? 'success' : 'error',
    })
  }

  const handleUpdateQuestion = async inputText => {
    let success = false
    setSnackbar({
      open: true,
      message: 'Updating Question',
      messageType: 'loading',
    })
    await updateProblem({
      variables: {
        id: edits.problem.id,
        input: {
          questionText: inputText ? inputText : '',
        },
      },
    }).then(() => {
      success = true
    })
    setSnackbar({
      open: true,
      message: success ? 'Question Updated' : 'error',
      messageType: success ? 'success' : 'error',
    })
  }

  const handleUpdateSolution = async inputText => {
    let success = false
    setSnackbar({
      open: true,
      message: 'Updating Solution',
      messageType: 'loading',
    })
    await updateProblem({
      variables: {
        id: edits.problem.id,
        input: {
          solutionText: inputText ? inputText : '',
        },
      },
    }).then(() => {
      success = true
    })
    setSnackbar({
      open: true,
      message: success ? 'Solution Updated' : 'error',
      messageType: success ? 'success' : 'error',
    })
  }

  const [updateChoice] = useMutation(UPDATE_CHOICE)
  const handleUpdateChoice = async (id, inputText) => {
    let success = false
    setSnackbar({
      open: true,
      message: 'Updating Solution',
      messageType: 'loading',
    })
    await updateChoice({
      variables: {
        id: id,
        input: {
          text: inputText ? inputText : '',
        },
      },
    }).then(() => {
      success = true
    })
    setSnackbar({
      open: true,
      message: success ? 'Solution Updated' : 'error',
      messageType: success ? 'success' : 'error',
    })
  }

  return (
    <Accordion defaultExpanded expanded style={{ marginBottom: 15 }}>
      <CustomAccordionSummary
        id="problem-eidtor-header"
        aria-controls="panella-content"
        expandIcon={
          <Tooltip title={'Open in HQ'}>
            <IconButton
              variant="outlined"
              color={'primary'}
              className={classes.iconButton}
              onClick={() => {
                const path =
                  `course/${edits.courseId}` +
                  `/lessonPlan/${edits.lessonPlanId}` +
                  `/problemSet/${edits.problemSetId}` +
                  `/problem/${edits.problem.id}`
                if (process.env.REACT_APP_NODE_ENV === 'production')
                  window.open(`https://hq.ardentlabs.io/${path}`)
                else window.open(`https://hq-next.ardentlabs.io/${path}`)
              }}
            >
              <LinkIcon className={classes.icon} />
            </IconButton>
          </Tooltip>
        }
      >
        <Typography
          className={classes.heading}
        >{`Course: ${edits.classroomCode.slice(0, 5)}, Lesson: ${
          edits.lessonPlanOrder
        }, 
        Problem Set: ${edits.problemSetOrder}, Problem: ${
          edits.problemOrder
        }`}</Typography>
      </CustomAccordionSummary>
      <AccordionDetails>
        <Flex
          column
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '1%',
          }}
        >
          {problemData.questionImageUrl && (
            <Fragment>
              <Typography className={classes.textLabel}>
                Question Image:
              </Typography>
              <img
                className={classes.resizedImage}
                src={problemData.questionImageUrl}
                alt="questionImage"
              />
              <br />
              <Divider style={{ width: '98%' }} />
            </Fragment>
          )}
          {problemData.questionText && (
            <Fragment>
              <EditableTextField
                key={'question-text'}
                label={'Question'}
                update
                clear
                handleUpdate={handleUpdateQuestion}
                text={questionText}
              />
              <Divider style={{ marginTop: 30, width: '98%' }} />
            </Fragment>
          )}
          {problemData.choices.length === 0 && (
            <Fragment>
              <Flex className={classes.row} style={{ width: '98%' }}>
                {studentAnswer ? (
                  <EditableTextField
                    key={'student-answer-text'}
                    label={'Student Answer'}
                    update
                    correct
                    clear
                    editCorrect
                    handleUpdate={inputText => {
                      handleUpdateStudentAnswer(studentAnswer.id, inputText)
                    }}
                    handleCorrect={() => {
                      handleCorrectResponse(
                        studentAnswer.id,
                        studentAnswer.count
                      )
                    }}
                    text={studentAnswer.value ? studentAnswer.value : ''}
                  />
                ) : (
                  <EditableTextField label={'Student Answer'} text={'N/A'} />
                )}
                <Divider
                  orientation="vertical"
                  style={{
                    marginLeft: '.5em',
                    marginRight: '.5em',
                  }}
                />
                <EditableTextField
                  key={'correct-answer'}
                  label={'Answer'}
                  update
                  clear
                  handleUpdate={handleUpdateAnswer}
                  text={answer}
                />
              </Flex>
              <Divider style={{ marginTop: 30, width: '98%' }} />
            </Fragment>
          )}
          {problemData.choices.length > 0 && (
            <Fragment>
              {studentAnswer != null && studentAnswer.value != null && (
                <Fragment>
                  <Flex row style={{ width: '98% ' }}>
                    <Typography className={classes.textLabel2}>
                      Student Answer
                    </Typography>
                    <Flex className={classes.iconGroup}>
                      <IconButton className={classes.iconButton}>
                        <CheckIcon
                          className={classes.icon}
                          color={'primary'}
                          onClick={() => {
                            handleCorrectResponse(
                              studentAnswer.id,
                              studentAnswer.count
                            )
                          }}
                        />
                      </IconButton>
                    </Flex>
                  </Flex>
                  <Flex style={{ width: '98%', justifyContent: 'center' }}>
                    <Flex p={'12px 14px'} className={classes.textPreview}>
                      <LaTeX latex={studentAnswer.value} />
                    </Flex>
                  </Flex>
                  <Divider style={{ marginTop: 30, width: '98%' }} />
                </Fragment>
              )}
              <Flex column style={{ width: '98%', alignItems: 'center' }}>
                {problemData.choices.map((choice, index) => {
                  return (
                    <Fragment>
                      <br />
                      <Choice
                        id={choice.id}
                        encoded={(index + 10).toString(36).toUpperCase()}
                        handleUpdate={handleUpdateChoice}
                        value={choice.text}
                        correct={choice.correct}
                        choiceText={choice.text}
                      />
                    </Fragment>
                  )
                })}
              </Flex>
              <Divider style={{ marginTop: 30, width: '98%' }} />
            </Fragment>
          )}
          {problemData.solutionImageUrl && (
            <Fragment>
              <Typography className={classes.textLabel}>
                Solution Image:
              </Typography>
              <img
                className={classes.resizedImage}
                src={problemData.solutionImageUrl}
                alt="solutionImageUrl"
              />
              <br />
              <Divider style={{ width: '98%' }} />
            </Fragment>
          )}
          <EditableTextField
            label={'Solution'}
            key={'solution-panel'}
            update
            clear
            handleUpdate={handleUpdateSolution}
            text={solutionText}
          />
        </Flex>
        <SnackbarNotification
          open={snackbar.open}
          handleClose={() => {
            setSnackbar({
              snackbar: {
                ...snackbar,
                open: false,
              },
            })
          }}
          message={snackbar.message}
          messageType={snackbar.messageType}
        />
      </AccordionDetails>
    </Accordion>
  )
})

export default ProblemPanel
