import React, { Component } from 'react'

import MakeupDetailView from './MakeupDetailView'
import MakeupTableView from './MakeupView'

import { Route, Switch } from 'react-router-dom'

class MakeupView extends Component {
  render() {
    const { url } = this.props.match
    return (
      <Switch>
        <Route path={`${url}/*`} component={MakeupDetailView} />
        <Route path={url} component={MakeupTableView} />
      </Switch>
    )
  }
}

export default MakeupView
