const MS_PER_DAY = 86400000
const DAYS_PER_WEEK = 7

const getTodayDateOnly = () => {
  const now = new Date()
  return new Date(now.getFullYear(), now.getMonth(), now.getDate())
}

const addMonths = (date, months) => {
  const result = new Date(date.getTime())
  result.setMonth(result.getMonth() + +months)
  const d = result.getDate()
  if (result.getDate() !== d) {
    result.setDate(0)
  }
  return result
}

export const SELECTABLE_TIMES = [
  {
    text: 'Today',
    getRange: () => {
      const today = getTodayDateOnly()
      const tomorrow = new Date(today.getTime() + MS_PER_DAY)
      return {
        startDate: today.toISOString(),
        endDate: tomorrow.toISOString(),
      }
    },
  },
  {
    text: 'Tomorrow',
    getRange: () => {
      const tomorrow = new Date(getTodayDateOnly().getTime() + MS_PER_DAY)
      const dayAfterTmr = new Date(tomorrow.getTime() + MS_PER_DAY)
      return {
        startDate: tomorrow.toISOString(),
        endDate: dayAfterTmr.toISOString(),
      }
    },
  },
  {
    text: 'This Week',
    getRange: () => {
      const today = getTodayDateOnly()
      const weekStart = new Date(today.getTime() - today.getDay() * MS_PER_DAY)
      const weekEnd = new Date(weekStart.getTime() + DAYS_PER_WEEK * MS_PER_DAY)
      return {
        startDate: weekStart.toISOString(),
        endDate: weekEnd.toISOString(),
      }
    },
  },
  {
    text: 'Next Week',
    getRange: () => {
      const today = getTodayDateOnly()
      const weekStart = new Date(
        today.getTime() + (DAYS_PER_WEEK - today.getDay()) * MS_PER_DAY
      )
      const weekEnd = new Date(weekStart.getTime() + DAYS_PER_WEEK * MS_PER_DAY)
      return {
        startDate: weekStart.toISOString(),
        endDate: weekEnd.toISOString(),
      }
    },
  },
  {
    text: 'This Month',
    getRange: () => {
      const now = new Date()
      const monthStart = new Date(now.getFullYear(), now.getMonth(), 1)
      const monthEnd = addMonths(monthStart, 1)
      return {
        startDate: monthStart.toISOString(),
        endDate: monthEnd.toISOString(),
      }
    },
  },

  {
    text: 'Next Month',
    getRange: () => {
      const now = new Date()
      const monthStart = addMonths(
        new Date(now.getFullYear(), now.getMonth(), 1),
        1
      )
      const monthEnd = addMonths(monthStart, 1)
      return {
        startDate: monthStart.toISOString(),
        endDate: monthEnd.toISOString(),
      }
    },
  },
  {
    text: 'Yesterday',
    getRange: () => {
      const today = getTodayDateOnly()
      const yesterday = new Date(today.getTime() - MS_PER_DAY)
      return {
        startDate: yesterday.toISOString(),
        endDate: today.toISOString(),
      }
    },
  },
  {
    text: 'Last Week',
    getRange: () => {
      const today = getTodayDateOnly()
      const weekEnd = new Date(today.getTime() - today.getDay() * MS_PER_DAY)
      const weekStart = new Date(weekEnd.getTime() - DAYS_PER_WEEK * MS_PER_DAY)
      return {
        startDate: weekStart.toISOString(),
        endDate: weekEnd.toISOString(),
      }
    },
  },
  {
    text: 'Last Month',
    getRange: () => {
      const now = new Date()
      const monthEnd = new Date(now.getFullYear(), now.getMonth(), 1)
      const monthStart = addMonths(monthEnd, -1)
      return {
        startDate: monthStart.toISOString(),
        endDate: monthEnd.toISOString(),
      }
    },
  },
]
