import React, { useCallback } from 'react'
import { Flex, SyncIcon, Divider } from '../../../components'
import { Button, Tooltip } from '@mui/material'
import { useMutation } from '@apollo/client'
import { RECAL_GRADES_BY_CLASSROOM } from '../queries'

export const EnrollmentHeader = React.memo(({ classId }) => {
  const recalculateGrades = useMutation(RECAL_GRADES_BY_CLASSROOM)

  const handleRecalculate = useCallback(
    () => async () => {
      await recalculateGrades({
        variables: {
          classroomId: classId,
        },
      })
    },
    [classId]
  )

  return (
    <React.Fragment>
      <Divider orientation="vertical" flexItem style={{ marginLeft: 'auto' }} />

      {recalculateGrades => (
        <Tooltip title={'Recalculate Grades'}>
          <Button
            variant="text"
            style={{ width: '6em' }}
            color="primary"
            onClick={handleRecalculate(recalculateGrades)}
          >
            <Flex column>
              <Flex style={{ marginBottom: '.5em', justifyContent: 'center' }}>
                <SyncIcon />
              </Flex>
            </Flex>
          </Button>
        </Tooltip>
      )}
    </React.Fragment>
  )
})

export default EnrollmentHeader
