import React from 'react'
import {
  ClassroomSwitcher,
  Divider,
  SidebarText,
  SidebarContainer,
  withStyles,
} from '../../components'

import TimePeriodSelector from './TimePeriodSelector'
import { sideBarStyles } from '../../utils/style'

const TutorSessionSidebar = ({
  tutorSessions,
  isAdmin,
  myClassrooms,
  handleMyClassrooms,
  times,
  selectedTime,
  onSelectedTimeChange,
  showInstructorOnly,
  onShowInstructorOnlyChange,
  classes,
}) => {
  return (
    <SidebarContainer>
      <ClassroomSwitcher
        title={'Tutees'}
        disabled={!isAdmin}
        checked={!myClassrooms}
        handleMyClassrooms={handleMyClassrooms}
      />
      <Divider />
      <TimePeriodSelector
        name={'Time'}
        options={times}
        selectedTime={selectedTime}
        onSelectedTimeChange={onSelectedTimeChange}
      />
      <SidebarText
        classes={{
          root: classes.headline,
        }}
        title="Summary View"
      />
      <Divider />
      <SidebarText
        classes={{ root: classes.subheadingOne }}
        title="Total Classes:"
        value={0}
      />
      <SidebarText
        classes={{ root: classes.subheadingOne }}
        title="Total Students:"
        value={0}
      />
    </SidebarContainer>
  )
}

export default withStyles(sideBarStyles)(TutorSessionSidebar)
