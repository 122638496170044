import React from 'react'
import { Button, Tooltip } from '@mui/material'
import { EmailIcon, PasswordIcon, TextsmsIcon } from '../../components'
import { Text, Flex, Divider } from '../../components'

import {
  EnrollmentHeader,
  LessonHeader,
  AssignmentHeader,
  GradesHeader,
  AttendanceHeader,
} from './Headers'
import {
  EnrollmentTableView,
  LessonTableView,
  AssignmentTableView,
  AttendanceTableView,
  GradeTableView,
} from './Tables'

export const TableHeader = React.memo(props => {
  const getRenderProps = () => {
    switch (props.type) {
      case 'enrollment':
        return <EnrollmentHeader {...props} />
      case 'lesson':
        return <LessonHeader {...props} />
      case 'assignment':
        return <AssignmentHeader {...props} />
      case 'grade':
        return <GradesHeader {...props} />
      case 'attendance':
        return <AttendanceHeader {...props} />
      default:
        return null
    }
  }
  return <DefaultHeader {...props} renderProps={getRenderProps()} />
})

export const TableFork = React.memo(props => {
  switch (props.type) {
    case 'enrollment':
      return <EnrollmentTableView {...props} />
    case 'lesson':
      return <LessonTableView {...props} />
    case 'assignment':
      return <AssignmentTableView {...props} />
    case 'grade':
      return <GradeTableView {...props} />
    case 'attendance':
      return <AttendanceTableView {...props} />
    default:
      return null
  }
})

//make more modular renderprops later...
const DefaultHeader = React.memo(props => (
  <Flex>
    <Text
      variant="h6"
      style={{
        marginTop: '0.3%',
        marginLeft: '0.3%',
        marginRight: 'auto',
        align: 'center',
      }}
    >
      {props.classroomTitle}
    </Text>
    {props.renderProps}
    <Divider orientation="vertical" flexItem />
    <Tooltip title={'Email Student'}>
      <Button
        variant="text"
        style={{ width: '6em' }}
        color="primary"
        onClick={props.handleOpenEmailDialog}
      >
        <Flex column>
          <Flex style={{ marginBottom: '.5em', justifyContent: 'center' }}>
            <EmailIcon />
          </Flex>
        </Flex>
      </Button>
    </Tooltip>
    <Divider orientation="vertical" flexItem />
    <Tooltip title={'Text Student'}>
      <Button
        variant="text"
        style={{ width: '6em' }}
        color="primary"
        onClick={props.handleOpenTextDialog}
      >
        <Flex column>
          <Flex style={{ marginBottom: '.5em', justifyContent: 'center' }}>
            <TextsmsIcon />
          </Flex>
        </Flex>
      </Button>
    </Tooltip>
    <Divider orientation="vertical" flexItem />
    <Tooltip title={'Reset Password'}>
      <Button
        variant="text"
        style={{ width: '6em' }}
        color="primary"
        onClick={props.handleResetPassword}
      >
        <Flex column>
          <Flex style={{ marginBottom: '.5em', justifyContent: 'center' }}>
            <PasswordIcon />
          </Flex>
        </Flex>
      </Button>
    </Tooltip>
  </Flex>
))
