import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import Cookies from 'js-cookie'
import { idName } from '../../config'

import ClassTable from './ClassTable'
import ClassSidebar from './ClassSidebar'

import { Paper } from '@mui/material'
import { ErrorMessage, LoadingMessage } from '../../components'
import { GET_CLASSROOMS } from './queries'
import { formatTimeRange, convertToMDY } from '../../utils/datetime'
import { formatInstructorNames } from '../../utils/instructors'

const ClassTableView = props => {
  const history = useHistory()
  const employeeId = Cookies.get(idName)

  const [userAuthorized] = useState(() => {
    const value = localStorage.getItem('userAuthorized')
    const userAuthorized = JSON.parse(value)
    return userAuthorized || false
  })
  const [isAdmin] = useState(() => {
    const value = localStorage.getItem('isAdmin')
    const isAdmin = JSON.parse(value)
    return isAdmin || false
  })

  const [myClassrooms, setMyClassrooms] = useState(true)
  const [filtered, setFiltered] = useState([])

  const getClassrooms = useQuery(GET_CLASSROOMS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: { status: 'OPENED' },
    },
  })

  if (!userAuthorized) return null

  const { loading, error, data } = getClassrooms
  if (loading) return <LoadingMessage />
  if (error) return <ErrorMessage error={error} />

  const isInstructor = classroom => {
    for (const instructor of classroom.instructors) {
      if (
        classroom.quarter !== 'OnDemand' &&
        instructor.employee.id === employeeId
      )
        return true
    }
    return false
  }
  const classrooms = data
    ? data.classrooms
        .filter(classroom => !myClassrooms || isInstructor(classroom))
        .map(classroom => formatClassroom(classroom))
    : []

  const handleRowClick = rowInfo => {
    history.push(`/classroom/${rowInfo.original.id}/enrollment`)
  }

  const handleFilteredChange = filtered => {
    setFiltered(filtered)
  }

  return (
    <Paper sx={{ display: 'flex' }}>
      <ClassSidebar
        isAdmin={isAdmin}
        classrooms={classrooms}
        myClassrooms={myClassrooms}
        handleMyClassrooms={setMyClassrooms}
      />
      <Paper sx={{ width: '100%' }}>
        <ClassTable
          classrooms={classrooms}
          filtered={filtered}
          handleRowClick={handleRowClick}
          handleFilteredChange={handleFilteredChange}
        />
      </Paper>
    </Paper>
  )
}

const formatClassroom = ({
  id,
  status,
  title,
  code,
  quarter,
  year,
  startDate,
  endDate,
  startTime,
  endTime,
  meets,
  centerLocation,
  enrollmentCount,
  instructors,
}) => ({
  id: id,
  status: status,
  title: title.slice(title.indexOf('-') + 2, title.length),
  code: code,
  quarter: quarter,
  year: year,
  day: meets,
  time: formatTimeRange(new Date(startTime), new Date(endTime)),
  startDate: convertToMDY(startDate),
  endDate: convertToMDY(endDate),
  centerLocation: centerLocation && centerLocation.name,
  enrollmentCount: enrollmentCount,
  instructor: formatInstructorNames(instructors),
})

export default ClassTableView
