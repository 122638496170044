import React from 'react'
import MaskedInput from 'react-text-mask'

// export const convertToMDY = data => {
//   const serverDate = new Date(data);
//   if (!serverDate) {
//     return null;
//   }

//   let localTime = serverDate.getTime();
//   let localOffset = serverDate.getTimezoneOffset()*60000;
//   const offset = -8; // TODO (DRLI): Hard code for PST, replace with local office offset and day light saving

//   let utc = localTime + localOffset;
//   var centerDate = new Date(utc + (3600000*offset));

//   let month = "" + (centerDate.getUTCMonth() + 1);
//   let day = "" + centerDate.getUTCDate();
//   let year = "" + centerDate.getUTCFullYear();

//   if (month.length < 2) month = "0" + month;
//   if (day.length < 2) day = "0" + day;
//   while (year.length < 4) year = "0" + year;

//   return [month, day, year].join("/");
// };

// // formatTime takes a Date object and returns a time string (e.g. "4:20 PM")
// export const formatTime = data => {
//   const serverDate = new Date(data);
//   if (!serverDate) return "";

//   let localTime = serverDate.getTime();
//   let localOffset = serverDate.getTimezoneOffset()*60000;
//   const offset = -8; // TODO (DRLI): Hard code for PST, replace with local office offset and day light saving

//   let utc = localTime + localOffset;
//   var centerDate = new Date(utc + (3600000*offset));

//   const hours = centerDate.getHours();
//   const minutes = `${centerDate.getMinutes() < 10 ? "0" : ""}${centerDate.getMinutes()}`;

//   if (hours === 0) return `12:${minutes} AM`;
//   else if (hours > 21) return `${hours - 12}:${minutes} PM`;
//   else if (hours > 12) return `0${hours - 12}:${minutes} PM`;
//   else if (hours === 12) return `${hours}:${minutes} PM`;
//   else if (hours < 10) return `0${hours}:${minutes} AM`;
//   else return `${hours}:${minutes} AM`;
// };

// formatTimeRange takes two Date objects and returns a time range string.
// (e.g. "4:20 PM — 4:40 PM")
// export const formatTimeRange = (start, end) => {
//   return formatTime(start) + " - " + formatTime(end);
// };

export const convertPhoneNumber = phoneNumber => {
  if (!phoneNumber) {
    return null
  }
  const cleanedNumber = phoneNumber.replace(/\D/g, '')
  let formattedNumber = ''
  if (cleanedNumber.length > 10) {
    formattedNumber = cleanedNumber
      .slice(0, cleanedNumber.length - 10)
      .concat(' (')
      .concat(
        cleanedNumber.slice(cleanedNumber.length - 10, cleanedNumber.length - 7)
      )
      .concat(') ')
      .concat(
        cleanedNumber.slice(cleanedNumber.length - 7, cleanedNumber.length - 4)
      )
      .concat('-')
      .concat(cleanedNumber.slice(cleanedNumber.length - 4))
  } else {
    formattedNumber = '('
      .concat(cleanedNumber.slice(0, cleanedNumber.length - 7))
      .concat(') ')
      .concat(
        cleanedNumber.slice(cleanedNumber.length - 7, cleanedNumber.length - 4)
      )
      .concat('-')
      .concat(cleanedNumber.slice(cleanedNumber.length - 4))
  }
  return formattedNumber
}

export function PhoneNumberInput(props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={[
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  )
}

export const getRegions = country => {
  if (!country) {
    return []
  }
  return country[2].split('|').map(regionPair => {
    let [regionName] = regionPair.split('~')
    return regionName
  })
}

export const numberInputValidate = event => {
  if (
    !(
      (event.keyCode >= 48 && event.keyCode <= 57) ||
      event.keyCode === 8 ||
      event.keyCode === 46
    )
  ) {
    //allow backspace, delete, and 0-9
    event.preventDefault()
    event.returnValue = false
  }
}

export const sortByName = (aFirstName, aLastName, bFirstName, bLastName) => {
  const aFirstNameLowerCase = aFirstName.toLowerCase()
  const bFirstNameLowerCase = bFirstName.toLowerCase()
  const aLastNameLowerCase = aLastName.toLowerCase()
  const bLastNameLowerCase = bLastName.toLowerCase()
  if (aLastNameLowerCase !== bLastNameLowerCase) {
    return aLastNameLowerCase > bLastNameLowerCase ? 1 : -1
  } else if (aFirstNameLowerCase !== bFirstNameLowerCase) {
    return aFirstNameLowerCase > bFirstNameLowerCase ? 1 : -1
  } else {
    return 0
  }
}

export const sortByClassCode = (aclassCode, bclassCode) => {
  return aclassCode > bclassCode ? 1 : aclassCode < bclassCode ? -1 : 0
}

export const arrayToObjectFilter = array =>
  array.reduce((obj, item) => {
    switch (item.id) {
      case 'enrollmentStatus':
      case 'gender':
        if (item.value !== 'ALL') {
          obj[item.id] = item.value
        }
        return obj
      case 'grade':
        if (item.value !== 'ALL') {
          obj[item.id] = parseInt(item.value, 10)
        }
        return obj
      case 'email':
      case 'phoneNumber':
        return (obj = {
          ...obj,
          familyAccount: {
            primaryFamilyMember: { [item.id]: { $match: `(?i)${item.value}` } },
          },
        })
      default:
        obj[item.id] = { $match: `(?i)${item.value}` }
        return obj
    }
  }, {})

export const arrayToObjectFamilyFilter = array =>
  array.reduce((obj, item) => {
    switch (item.id) {
      case 'status':
        if (item.value !== 'ALL') {
          obj[item.id] = item.value
        }
        return obj
      case 'firstName':
      case 'lastName':
      case 'relation':
      case 'preferredLanguage':
      case 'phoneNumber':
      case 'email':
        obj = {
          ...obj,
          primaryFamilyMember: {
            [item.id]: { $match: `(?i)${item.value}` },
          },
        }
        return obj
      case 'city':
        obj = {
          ...obj,
          location: {
            [item.id]: { $match: `(?i)${item.value}` },
          },
        }
        return obj
      default:
        obj[item.id] = { $match: `(?i)${item.value}` }
        return obj
    }
  }, {})

export const arrayToObjectSort = array =>
  array.reduce((obj, item) => {
    switch (item.id) {
      case 'email':
      case 'phoneNumber':
        return (obj = {
          ...obj,
          familyAccount: {
            primaryFamilyMember: { [item.id]: item.desc ? -1 : 1 },
          },
        })
      default:
        obj[item.id] = item.desc ? -1 : 1
        return obj
    }
  }, {})

export const arrayToObjectFamilySort = array =>
  array.reduce((obj, item) => {
    switch (item.id) {
      case 'firstName':
      case 'lastName':
      case 'relation':
      case 'preferredLanguage':
      case 'phoneNumber':
      case 'email':
        obj = { ...obj, primaryFamilyMember: { [item.id]: item.desc ? -1 : 1 } }
        return obj
      case 'city':
        obj = { ...obj, location: { [item.id]: item.desc ? -1 : 1 } }
        return obj
      default:
        obj[item.id] = item.desc ? -1 : 1
        return obj
    }
  }, {})
