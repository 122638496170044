import React from 'react'
import Flex from './Flex'
import { photoGallery, rotate } from './assests'
import { IconMaker } from './Icons'
import Button from '@mui/material/Button'
import { useDropzone } from 'react-dropzone'
import { gql } from '@apollo/client'

const UPLOAD_FILE = gql`
  mutation uploadFile($file: String!, $type: String!) {
    s3Sign(filename: $file, contentType: $type) {
      success
      signedUrl
      fileKey
      publicUrl
    }
  }
`
const ImageFileUploader = props => {
  const { src, style, alt, client, setImage, imageKey } = props
  const [hover, setHover] = React.useState(false)
  const [file, setFile] = React.useState(src)
  const [imagePath, setPath] = React.useState(imageKey)

  const onDrop = async files => {
    const File = files[0]
    const { path, type } = File

    const { data } = await client.mutate({
      mutation: UPLOAD_FILE,
      variables: { file: path, type: type },
    })

    const {
      s3Sign: { signedUrl, fileKey, publicUrl },
    } = data

    await fetch(signedUrl, {
      method: 'PUT',
      body: File,
    })

    setFile(publicUrl)
    setImage(fileKey)
    setPath(path)
  }

  const { getRootProps, getInputProps } = useDropzone({
    noDrag: true,
    accept: 'image/jpeg, image/png, image/jpg, image/gif, image/png',
    multiple: false,
    onDrop: files => onDrop(files),
  })

  const rotateFunction = async () => {
    const img = new Image()
    const c = document.createElement('canvas')
    const ctx = c.getContext('2d')

    img.onload = function() {
      c.width = this.naturalWidth // update canvas size to match image
      c.height = this.naturalHeight
      ctx.translate(c.width / 2, c.height / 2) //move photo to center of canvas
      ctx.rotate((90 * Math.PI) / 180) // converting degrees to radians

      ctx.drawImage(
        this,
        -this.width / 2,
        -this.height / 2,
        this.width,
        this.height
      ) // draw in image
      ctx.translate(-c.width, -c.height / 2) //move photo back to original place

      c.toBlob(async function(blob) {
        // get content as JPEG blob
        const { data } = await client.mutate({
          mutation: UPLOAD_FILE,
          variables: { file: imagePath, type: blob.type },
        })

        const {
          s3Sign: { signedUrl, fileKey, publicUrl },
        } = data

        await fetch(signedUrl, {
          method: 'PUT',
          body: blob,
        })

        setFile(publicUrl)
        setImage(fileKey)
      }, 'image/jpeg')
    }

    img.crossOrigin = ''
    img.src = `${file}?timestamp=${Date.now()}`
  }

  return (
    <Flex
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Flex style={{ position: 'relative' }}>
        <img src={file} alt={alt} style={{ ...style }} />
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <Button
            disableRipple
            disable="true"
            style={{
              backgroundColor: 'transparent',
              position: 'absolute',
              right: 0,
              bottom: 0,
              padding: 0,
              minWidth: '30px',
              display: hover ? 'block' : 'none',
            }}
          >
            <IconMaker
              src={photoGallery}
              width="30px"
              height="30px"
              alt="photo gallery icon"
            />
          </Button>
        </div>
        <Button
          disableRipple
          disable="true"
          style={{
            backgroundColor: 'transparent',
            position: 'absolute',
            left: 0,
            bottom: 0,
            padding: 0,
            minWidth: '30px',
            display: hover ? 'block' : 'none',
          }}
          onClick={() => rotateFunction()}
        >
          <IconMaker
            src={rotate}
            width="30px"
            height="30px"
            alt="rotate icon"
          />
        </Button>
      </Flex>
    </Flex>
  )
}

export default ImageFileUploader
