import { gql } from '@apollo/client'

export const GET_MAKEUPS = gql`
  query getMakeups($filter: Filters!) {
    makeups(filter: $filter) {
      id
      createdOn
      notes
      status
      createdBy {
        id
        firstName
        lastName
      }
      student {
        id
        firstName
        lastName
      }
      fromLesson {
        id
        startOn
        endOn
        lessonPlan {
          id
          order
        }
        classroom {
          id
          code
        }
      }
      toLesson {
        id
        startOn
        endOn
        lessonPlan {
          id
          order
        }
        classroom {
          id
          code
          instructors {
            id
            isAssistant
            displayName
            employee {
              id
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`

export const GET_MAKEUP = gql`
  query getMakeup($id: ID!) {
    makeup(id: $id) {
      id
      notes
      status
      student {
        id
        firstName
        lastName
      }
      fromLesson {
        id
        startOn
        endOn
        lessonPlan {
          order
        }
        classroom {
          id
          title
        }
      }
      toLesson {
        id
        startOn
        endOn
        lessonPlan {
          order
        }
        classroom {
          id
          title
        }
      }
    }
  }
`

export const UPDATE_MAKEUP = gql`
  mutation updateMakeup($id: ID!, $input: UpdateMakeupInput!) {
    updateMakeup(id: $id, input: $input) {
      id
    }
  }
`
