import React from 'react'
import { Divider } from '@mui/material'

import {
  ClassroomSwitcher,
  SidebarText,
  SidebarContainer,
  withStyles,
} from '../../components'
import { sideBarStyles } from '../../utils/style'

const TrialSidebar = ({
  trials,
  isAdmin,
  myClassrooms,
  handleMyClassrooms,
  classes,
}) => {
  const trialByLesson = new Array(10).fill(0)
  trials.forEach(element => {
    trialByLesson[(element.lessonNumber - 1) % 10]++
  })

  return (
    <SidebarContainer>
      <ClassroomSwitcher
        title={'Classes'}
        disabled={!isAdmin}
        checked={!myClassrooms}
        handleMyClassrooms={handleMyClassrooms}
      />
      <SidebarText
        classes={{
          root: classes.headline,
        }}
        title="Detail View"
      />
      <Divider />
      <SidebarText
        classes={{ root: classes.subheadingOne }}
        title="Total Trials:"
        value={trials.length}
      />
      {trialByLesson.map((tbw, index) => {
        if (!tbw) return ''
        return (
          <SidebarText key={index} title={`Lesson ${index + 1}:`} value={tbw} />
        )
      })}
    </SidebarContainer>
  )
}

export default withStyles(sideBarStyles)(TrialSidebar)
