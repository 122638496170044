import React, { useState, useEffect } from 'react'
import {
  Flex,
  Typography,
  EditIcon,
  CheckIcon,
  IconButton,
  ClearIcon,
  AddIcon,
  ThumbUpIcon,
  LaTeX,
  Paper,
  InputBase,
  makeStyles,
} from '../../components'

const useStyles = makeStyles(theme => ({
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  input: {
    flex: 1,
  },
  leftTextField: {
    padding: '5px 10px',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid rgb(187, 187, 187)',
  },
  iconDivider: {
    height: 28,
    margin: 4,
  },
  textLabel: {
    marginTop: 'auto',
    padding: 5,
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '13px',
  },
  textPreview: {
    borderRadius: '4px',
    border: '1px solid rgb(187, 187, 187)',
  },
  textHeader: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  iconButton: {
    width: 42,
    height: 42,
  },
  iconGroup: {
    marginLeft: 'auto',
  },
  icon: {
    width: 20,
    height: 20,
  },
  iconTwo: {
    width: 24,
    height: 24,
  },
}))

/**
 * Need to refactor later...
 */

const EditableTextField = props => {
  const classes = useStyles()
  const [text, setText] = useState(props.text)
  const [editable, setEditable] = useState(false)
  const originalText = props.text
  const {
    label,
    create,
    update,
    clear,
    correct,
    editCreate,
    editUpdate,
    editClear,
    editCorrect,
  } = props

  useEffect(() => {
    setText(props.text)
  }, [props.text])

  return (
    <Flex column style={{ width: '98%' }}>
      <Flex style={{ width: '100%' }}>
        <Typography className={classes.textLabel}>{label}</Typography>
        <Flex className={classes.iconGroup}>
          <IconButton
            className={classes.iconButton}
            color={editable ? 'primary' : 'secondary'}
            onClick={() => setEditable(!editable)}
          >
            <EditIcon className={classes.icon} />
          </IconButton>
          {create && (
            <IconButton
              className={classes.iconButton}
              color={'primary'}
              onClick={() => {
                props.handleCreate(text)
                setEditable(false)
              }}
              disabled={!editable && !editCreate}
            >
              <AddIcon className={classes.iconTwo} />
            </IconButton>
          )}
          {update && (
            <IconButton
              className={classes.iconButton}
              color={'primary'}
              onClick={() => {
                props.handleUpdate(text)
                setEditable(false)
              }}
              disabled={!editable && !editUpdate}
            >
              <CheckIcon className={classes.iconTwo} />
            </IconButton>
          )}
          {clear && (
            <IconButton
              className={classes.iconButton}
              color={'secondary'}
              onClick={() => {
                setText(originalText)
                setEditable(false)
              }}
              disabled={!editable && !editClear}
            >
              <ClearIcon className={classes.iconTwo} />
            </IconButton>
          )}
          {correct && (
            <IconButton
              className={classes.iconButton}
              color={'primary'}
              onClick={() => {
                props.handleCorrect(text)
                setEditable(false)
              }}
              disabled={!editable && !editCorrect}
            >
              <ThumbUpIcon className={classes.icon} />
            </IconButton>
          )}
        </Flex>
      </Flex>
      {editable ? (
        <Paper
          component="form"
          elevation={0}
          variant={'outlined'}
          className={classes.leftTextField}
        >
          <InputBase
            className={classes.input}
            placeholder={label}
            value={text}
            multiline
            onChange={event => setText(event.target.value)}
          />
        </Paper>
      ) : (
        <Flex p={'12px 14px'} className={classes.textPreview}>
          {text !== null && <LaTeX latex={text} />}
        </Flex>
      )}
    </Flex>
  )
}

export default EditableTextField
