import React from 'react'
import { useMutation } from '@apollo/client'
import { Button, DialogActions, Flex } from '../../components'
import {
  UPDATE_SUBMISSION,
  GIVE_FREE_POINT,
  ARCHIVE_ISSUE,
  UPDATE_ISSUE,
  REPAIR_SUBMISSION,
} from './queries'

export const EditIssueDialogMain = props => {
  const [archiveIssue] = useMutation(ARCHIVE_ISSUE)
  const [repairSubmission] = useMutation(REPAIR_SUBMISSION)
  const [updateIssue] = useMutation(UPDATE_ISSUE)

  const defaultFixedResponses = [
    'Thank you for reporting. The correction is made.',
    'Really appreciate your report. We made the correction.',
    'Great job for finding this issue. It has been corrected.',
  ]

  const defaultCorrectResponses = [
    'Your answer is correct. Watch class video to learn more',
    'It looks like you were able to figure out the correct answer now, good job!',
    'Nice. You submitted the correct answer. Let me know if you need more help',
    'Good answer. Looks life you found a way to solve this problem. Let me know if you need more help',
  ]

  return (
    <DialogActions>
      <Flex
        className={props.className}
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          alignSelf: 'center',
          alignItems: 'center',
        }}
      >
        <Flex style={{ marginLeft: '2%' }}>
          <Button
            size="large"
            color="secondary"
            onClick={() => {
              if (
                window.confirm('Are you sure you want to archive this issue?')
              ) {
                archiveIssue({
                  variables: {
                    id: props.id,
                  },
                })
                props.updateTable()
                props.handleClose()
              }
            }}
          >
            Archive
          </Button>
          <Button
            size="large"
            variant="outlined"
            autoFocus
            onClick={props.handleClose}
            style={{ marginLeft: '8%' }}
            color="secondary"
          >
            CANCEL
          </Button>
          <Button
            size="large"
            variant="outlined"
            autoFocus
            onClick={props.handleSave}
            style={{ marginLeft: '8%' }}
            color="primary"
            disabled={!props.dateAssigned}
          >
            SAVE
          </Button>
        </Flex>
        <Flex style={{ width: '40%', marginRight: '2.5%' }}>
          <Button
            size="large"
            autoFocus
            color="primary"
            fullWidth
            style={{ marginLeft: '4%' }}
            onClick={() => {
              props.hintRef.current.sendHint()
            }}
          >
            Hint
          </Button>
          <Button
            size="large"
            autoFocus
            color="primary"
            fullWidth
            style={{ marginLeft: '4%' }}
            onClick={() => {
              repairSubmission({
                variables: {
                  submissionId: props.submissionId,
                },
              })
              updateIssue({
                variables: {
                  id: props.id,
                  input: {
                    responsibleEmployeeId: props.employeeId,
                    dateAssigned: new Date().toISOString(),
                    dateResolved: new Date().toISOString(),
                    issueStatus: 'RESOLVED',
                    isRead: false,
                    resolution: props.resolution,
                    response: props.response,
                  },
                },
              })
              props.updateTable()
              props.handleClose()
            }}
          >
            Repair
          </Button>
          <Button
            size="large"
            autoFocus
            color="primary"
            fullWidth
            style={{ marginLeft: '4%' }}
            onClick={() => {
              props.solutionRef.current.sendSolution()
            }}
          >
            Solution
          </Button>
          <Button
            size="large"
            autoFocus
            color="primary"
            fullWidth
            style={{ marginLeft: '4%' }}
            onClick={() => {
              updateIssue({
                variables: {
                  id: props.id,
                  input: {
                    responsibleEmployeeId: props.employeeId,
                    dateAssigned: new Date().toISOString(),
                    dateResolved: new Date().toISOString(),
                    issueStatus: 'RESOLVED',
                    isRead: false,
                    response:
                      defaultCorrectResponses[
                        Math.floor(
                          Math.random() * defaultCorrectResponses.length
                        )
                      ],
                  },
                },
              })
              props.updateTable()
              props.handleClose()
            }}
          >
            Correct
          </Button>
          <Button
            size="large"
            autoFocus
            color="primary"
            fullWidth
            style={{ marginLeft: '4%' }}
            onClick={() => {
              updateIssue({
                variables: {
                  id: props.id,
                  input: {
                    responsibleEmployeeId: props.employeeId,
                    dateAssigned: new Date().toISOString(),
                    dateResolved: new Date().toISOString(),
                    issueStatus: 'RESOLVED',
                    isRead: false,
                    response:
                      defaultFixedResponses[
                        Math.floor(Math.random() * defaultFixedResponses.length)
                      ],
                  },
                },
              })
              props.updateTable()
              props.handleClose()
            }}
          >
            Fixed
          </Button>
          <Button
            size="large"
            autoFocus
            color="primary"
            fullWidth
            style={{ marginLeft: '4%' }}
            onClick={() => {
              updateIssue({
                variables: {
                  id: props.id,
                  input: {
                    responsibleEmployeeId: props.employeeId,
                    dateAssigned: new Date().toISOString(),
                    dateResolved: new Date().toISOString(),
                    issueStatus: 'RESOLVED',
                    isRead: false,
                    resolution: props.resolution,
                    response: props.response,
                  },
                },
              })
              props.updateTable()
              props.handleClose()
            }}
          >
            Resolve
          </Button>
        </Flex>
      </Flex>
    </DialogActions>
  )
}

export const EditIssueDialogDefault = props => {
  const { submissionId, edits } = props

  const [updateSubmission] = useMutation(UPDATE_SUBMISSION)
  const handleResetSubmission = async () => {
    if (!submissionId) {
      window.alert(
        'Error: There is no submission associated with this student.'
      )
      return
    }
    await updateSubmission({
      variables: {
        id: submissionId,
        input: {
          attemptNumber: edits.problem.problemSet.allowedAttempts - 1,
        },
      },
    })
  }

  const handleResetReview = async () => {
    if (!submissionId) {
      window.alert(
        'Error: There is no submission associated with this student.'
      )
      return
    }
    await updateSubmission({
      variables: {
        id: submissionId,
        input: {
          reviewed: false,
        },
      },
    })
    // props.updateTable()
    props.setSubmissionReviewed(false)
  }

  const [giveFreePont] = useMutation(GIVE_FREE_POINT)
  const handleAwardFreePoint = async () => {
    if (
      window.confirm(
        'Are you sure you want to award a free point to all students who submitted a response for this problem?'
      )
    )
      await giveFreePont({
        variables: {
          id: edits.problem && edits.problem.id,
        },
      })
  }

  return (
    <Flex
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Button
        size="large"
        variant="outlined"
        autoFocus
        onClick={handleResetReview}
        color="primary"
      >
        Reset Review
      </Button>
      <Button
        size="large"
        variant="outlined"
        autoFocus
        onClick={handleResetSubmission}
        color="primary"
      >
        Reset Submission
      </Button>
      <Button
        size="large"
        variant="outlined"
        autoFocus
        onClick={handleAwardFreePoint}
        color="primary"
      >
        Award Freepoint
      </Button>
    </Flex>
  )
}

export default EditIssueDialogMain
