import React, { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import { GET_SUBMISSIONS } from './queries'
import { Flex, LoadingMessage } from '../../components'
import SubmissionDialog from './SubmissionDialog'
import SubmissionSidebar from './SubmissionSidebar'
import SubmissionTable from './SubmissionTable'
import { computeRoundedTime } from '../../utils/datetime'
import moment from 'moment-timezone'
import Cookies from 'js-cookie'
import { idName } from '../../config'

/*
startTime, endTime are all Date() object
currentRoundedTime is calculated before the fetching with method computeRoundedTime
*/
const SubmissionTableView = props => {
  const currentRoundedTime = computeRoundedTime(new Date())
  const [open, setOpen] = useState(false)
  const [id, setId] = useState('')
  const [timePeriod, setTimePeriod] = useState(60)
  const [startTime, setStartTime] = useState(
    moment(currentRoundedTime).subtract(1, 'hours')
  )
  const [filterByInstructor, setInstructor] = useState(true)

  const [endTime, setEndTime] = useState(moment(currentRoundedTime))
  const [filter, setFilter] = useState({
    submittedAt: {
      $gteDate: startTime,
      $lteDate: endTime,
    },
  })
  const [submissions, setSubmissions] = useState([])

  const [fetchSubmissions, { loading, data }] = useLazyQuery(GET_SUBMISSIONS, {
    variables: {
      filter,
      sort: { submittedAt: -1 },
    },
    onCompleted: res => {
      let { submissions } = res
      if (submissions.length !== 0) {
        if (filterByInstructor) {
          submissions = data.submissions.filter(
            classroom =>
              !classroom.classroom.instructors.every(
                instructor => instructor.employee.id !== Cookies.get(idName)
              )
          )
        }
        const resolvedData = resolveData(submissions)
        setSubmissions([...resolvedData])
      }
    },
    fetchPolicy: 'network-only',
  })

  // useEffect(() => {
  //   //On initial render, fetch submissions
  //   fetchSubmissions()
  // }, [fetchSubmissions])

  useEffect(() => {}, [setSubmissions])

  const setFilteredByInstructor = value => {
    setInstructor(value)
  }
  const updateTimePeriod = event => {
    setTimePeriod(event.target.value)
    setStartTime(
      moment(currentRoundedTime).subtract(event.target.value, 'minutes')
    )
    setEndTime(moment(currentRoundedTime))
  }

  const updateStartTime = event => {
    setStartTime(event.target.value)
    setTimePeriod('')
  }

  const updateEndTime = event => {
    setEndTime(event.target.value)
    setTimePeriod('')
  }

  const handleFetch = () => {
    setFilter({
      submittedAt: {
        $gteDate: startTime,
        $lteDate: endTime,
      },
    })
    fetchSubmissions()
  }

  const rowClick = rowInfo => {
    if (rowInfo) {
      setOpen(true)
      setId(rowInfo.original.submissionId)
    }
  }

  const handleClose = _ => {
    setOpen(false)
    setId('')
  }

  const resolveData = submissions => {
    const resolvedData = submissions.map(submission => {
      const {
        id,
        classroom,
        lesson,
        startedAt,
        archivedOn,
        student,
        submittedAt,
        overallGrade,
        count,
        sum,
        submittedLate,
        graded,
        reviewed,
        attemptNumber,
        coins,
        totalTimeSpent,
      } = submission
      return {
        submissionId: id,
        status: archivedOn
          ? 'ARCHIVED'
          : submittedAt
          ? 'SUBMITTED'
          : 'IN-PROGRESS',
        submitted: submittedAt,
        startedAt: new Date(startedAt).toLocaleString(),
        fullName: `${student.lastName} ${student.firstName}`,
        submittedAt: submittedAt
          ? new Date(submittedAt).toLocaleString()
          : 'Unsubmitted',
        code: classroom.code,
        classTitle: classroom.title.split('-')[1],
        lessonOrder: lesson.lessonPlan.order,
        count: count,
        overallGrade: overallGrade !== -1 ? `${overallGrade}%` : 'N/A',
        sum: sum,
        submittedLate: submittedLate ? 'True' : 'False',
        reviewed: reviewed ? 'True' : 'False',
        attemptNumber: attemptNumber ? attemptNumber : 0,
        coins: coins ? coins : 0,
        totalTimeSpent: totalTimeSpent,
        student,
        graded: graded ? 'True' : 'False',
      }
    })
    return resolvedData
  }

  return (
    <Flex>
      <SubmissionSidebar
        timePeriod={timePeriod}
        startTime={startTime}
        endTime={endTime}
        updateTimePeriod={updateTimePeriod}
        updateStartTime={updateStartTime}
        updateEndTime={updateEndTime}
        onFetch={handleFetch}
        filterByInstructor={filterByInstructor}
        onFilterByInstructorChanged={setFilteredByInstructor}
      />
      <Flex align="left" column grow={1}>
        {loading ? (
          <LoadingMessage />
        ) : (
          <SubmissionTable
            resolvedSubmissions={submissions}
            rowClick={rowClick}
          />
        )}
      </Flex>
      {open && (
        <SubmissionDialog
          id={id}
          open={open}
          handleClose={handleClose}
          handleFetch={handleFetch}
        />
      )}
    </Flex>
  )
}

export default SubmissionTableView
