import React from 'react'
import { useQuery } from '@apollo/client'
import { Link } from 'react-router-dom'

import { convertToMDY, formatDayTimeRange } from '../../utils/datetime'

import { Box, Paper, Typography } from '@mui/material'

import { ErrorMessage, LoadingMessage, ExpansionPanel } from '../../components'

import { GET_TRIAL } from './queries'
import TrialForm from './TrialForm'
import EvaluationForm from './EvaluationForm'

const TrialDetailView = props => {
  const trialId = props.match.params[0]
  const { loading, error, data } = useQuery(GET_TRIAL, {
    fetchPolicy: 'network-only',
    variables: { id: trialId },
  })
  if (loading) return <LoadingMessage />
  if (error) return <ErrorMessage error={error} />
  if (!data) return null

  const trialInfo = formatTrialInfo(data.trial)
  const trialEvaluation = formatTrialEvaluationInfo(data.trial)
  const { firstName, lastName } = data.trial.opportunity.student
  const title = `Trial for ${firstName} ${lastName}`
  const { classroomCodes } = props
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        textAlign: 'left',
        m: 'auto',
        width: '67%',
        minWidth: '600px',
        maxWidth: '1000px',
      }}
    >
      <Box sx={{ pt: 2 }}>
        <Link to="/trial">{'< back to Trials View'}</Link>
      </Box>
      <Typography sx={{ py: 2 }} variant="h4">
        {title}
      </Typography>
      <Paper>
        <>
          <ExpansionPanel
            expanded
            overflow
            title="Trial Evaluation Info"
            form={
              <EvaluationForm
                trialEvaluation={trialEvaluation}
                classroomCodes={classroomCodes}
              />
            }
          />
          <ExpansionPanel
            expanded
            overflow
            title="Trial Info"
            form={<TrialForm trial={trialInfo} />}
          />
        </>
      </Paper>
    </Box>
  )
}

const formatTrialInfo = ({
  id,
  status,
  notes,
  opportunity,
  followupDate,
  followupEmployee,
  followupResult,
  toLesson,
  followupStatus,
}) => ({
  id: id,
  status: status,
  enrollmentStatus: opportunity.status,
  followupDate: `${convertToMDY(followupDate)}`,
  followupBy: `${followupEmployee.firstName} ${followupEmployee.lastName}`,
  followupStatus: followupStatus ? 'Yes' : 'No',
  toClassTitle: toLesson && toLesson.classroom.title,
  toLessonTitle: `${toLesson.lessonPlan.order}. ${convertToMDY(
    toLesson.startOn
  )}, ${formatDayTimeRange(
    new Date(toLesson.startOn),
    new Date(toLesson.endOn)
  )}`,
  notes: notes,
})

const formatTrialEvaluationInfo = ({
  id,
  evaluationDiscipline,
  evaluationMentalMath,
  evaluationMotivation,
  evaluationProblemSolving,
  evaluationSkillBuilder,
  quizGrade,
  quizScore,
  quizTotal,
  instructorComment,
  recommendation,
  alternateCourseTitle,
  toLesson,
}) => ({
  id: id,
  evaluationMentalMath: evaluationMentalMath && evaluationMentalMath.toString(),
  evaluationSkillBuilder:
    evaluationSkillBuilder && evaluationSkillBuilder.toString(),
  evaluationProblemSolving:
    evaluationProblemSolving && evaluationProblemSolving.toString(),
  evaluationMotivation: evaluationMotivation && evaluationMotivation.toString(),
  evaluationDiscipline: evaluationDiscipline && evaluationDiscipline.toString(),
  quizScore: quizScore ? quizScore : 0,
  quizTotal: quizTotal ? quizTotal : 100,
  quizGrade: quizGrade && quizGrade.toFixed(2),
  instructorComment: instructorComment,
  recommendation: recommendation ? 'Yes' : 'No',
  alternateCourseTitle: alternateCourseTitle,
  toClassCode: toLesson && toLesson.classroom.code,
})

export default TrialDetailView
