import React from 'react'
import { withRouter } from 'react-router-dom'
import Paper from './Paper'
import Tab from './Tab'
import Tabs from './Tabs'
import withStyles from './Styles'
import moment from 'moment'

/**
 *
 * This isn't the best implementation
 * this is just temporary quick solution until the classroom is refactored...
 */

const SubTabBar = props => {
  const changeRoute = path => {
    props.history.push(path)
  }

  const handleClick = value => {
    if (props.tabIndex !== value) {
      switch (value) {
        case 1:
          changeRoute(`/oldclassroom/${props.id}`)
          break
        case 2:
          changeRoute(`/lesson/${props.id}`)
          break
        case 3:
          changeRoute(`/assignment/${props.id}`)
          break
        case 4:
          changeRoute(`/grade/${props.id}`)
          break
        case 5:
          let today = new Date()
          localStorage.setItem('attendeeClassroomId', props.id)
          localStorage.setItem(
            'attendeeDayOfWeek',
            moment(today).format('dddd')
          )
          localStorage.setItem('attendeeLocation', 'ALL')
          changeRoute(`/attendance`)
          break
        default:
          console.error('Invalid Index')
      }
    }
  }

  const tabs = {
    Enrollment: 1,
    Lessons: 2,
    Assignment: 3,
    Grade: 4,
    Attendance: 5,
  }

  const tabNames = [
    'Enrollment',
    'Lessons',
    'Assignment',
    'Grade',
    'Attendance',
  ]

  const { classes } = props

  return (
    <div>
      <Paper>
        <Tabs
          classes={{
            indicator: classes.tabsIndicator,
          }}
          variant="scrollable"
          value={props.tab}
          onChange={(event, value) => {
            handleClick(value)
            return ''
          }}
        >
          {tabNames.map(tabName => (
            <Tab key={tabName} label={tabName} value={tabs[tabName]} />
          ))}
        </Tabs>
      </Paper>
    </div>
  )
}

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabsIndicator: {
    backgroundColor: theme.palette.primary.main,
  },
})

export default withRouter(withStyles(styles)(SubTabBar))
