import React from 'react'
import { Route, Switch } from 'react-router-dom'
import TutorSessionTableView from './TutorSessionView'
import TutorSessionDetailView from './TutorSessionDetailView'

const TuteeViewRouter = props => {
  const url = props.match.url
  return (
    <Switch>
      <Route path={`${url}/*`} component={TutorSessionDetailView} />
      <Route path={url} render={props => <TutorSessionTableView />} />
    </Switch>
  )
}

export default TuteeViewRouter
