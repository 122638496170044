import React from 'react'
import ReactTable from 'react-table-6'
import 'react-table-6/react-table.css'

import {
  CLASS_SCHEDULED,
  TABLE_ROW_BACKGROUND_COLOR,
  TABLE_ROW_HIGHLIGHT_COLOR,
} from '../../config'
import { Box } from '@mui/material'
import { convertToMDY, dateFilterMethod } from '../../utils/datetime'

const trialStatuses = ['SCHEDULED', 'RESCHEDULED', 'NOSHOW', 'ATTENDED']
const opportunityStatuses = [
  'OPEN',
  'ENROLLED',
  'LOST',
  'PENDING',
  'WAITLISTED',
  'CLOSED',
  'REINVITE',
  'LEVELUP',
  'UPPED',
  'STAYED',
]

const TrialsTable = ({
  trials,
  filtered,
  handleFilteredChange,
  handleRowClick,
}) => {
  const defaultSorted = [{ id: 'classCode' }]

  return (
    <Box
      sx={{
        align: 'left',
      }}
    >
      <ReactTable
        data={trials}
        defaultFiltered={filtered}
        onFilteredChange={handleFilteredChange}
        columns={columns}
        filterable={true}
        defaultSorted={defaultSorted}
        className="-highlight"
        style={{ fontSize: '10pt' }}
        defaultPageSize={25}
        defaultFilterMethod={(filter, row) =>
          row[filter.id] &&
          row[filter.id]
            .toString()
            .toLowerCase()
            .includes(filter.value.toString().toLowerCase())
        }
        getTrProps={(state, rowInfo, column) => {
          if (
            rowInfo &&
            rowInfo.row.status === CLASS_SCHEDULED &&
            (rowInfo.row.lessonDate < new Date() ||
              rowInfo.row.followupDate < new Date())
          ) {
            return { style: { background: TABLE_ROW_HIGHLIGHT_COLOR } }
          }
          return { style: { background: TABLE_ROW_BACKGROUND_COLOR } }
        }}
        getTdProps={(state, rowInfo, column, instance) => {
          return {
            onClick: (e, handleOriginal) => {
              if (column.Header !== undefined) {
                handleRowClick(rowInfo)
              }
              if (handleOriginal) {
                handleOriginal()
              }
            },
          }
        }}
        getTheadProps={(state, rowInfo, column, instance) => {
          return {
            style: {
              fontWeight: 'bold',
              borderBottom: '1px solid #E5E5E5',
            },
          }
        }}
      />
    </Box>
  )
}

const columns = [
  {
    Header: 'Status',
    accessor: 'status',
    width: 135,
    Cell: props => <span>{props.value ? props.value : 'SCHEDULED'}</span>,
    filterMethod: (filter, row) => {
      if (filter.value !== 'ALL') {
        return row[filter.id] === filter.value
      }
      if (filter.value === 'UNKNOWN') {
        return row[filter.id] === null
      }
      return true
    },
    Filter: ({ filter, onChange }) => (
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'ALL'}
      >
        <option value="ALL">ALL</option>
        {trialStatuses
          .filter(option => option !== 'RESCHEDULED')
          .map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
      </select>
    ),
  },
  {
    Header: 'Enrollment',
    accessor: 'enrollmentStatus',
    width: 120,
    Cell: props => {
      return (
        <span
          style={{
            color:
              props.value === 'ENROLLED'
                ? 'blue'
                : props.value === 'OPEN'
                ? 'green'
                : props.value === 'LOST'
                ? 'red'
                : 'black',
          }}
        >
          {props.value}
        </span>
      )
    },
    filterMethod: (filter, row) => {
      if (filter.value !== 'ALL') {
        return row[filter.id] === filter.value
      }
      return true
    },
    Filter: ({ filter, onChange }) => (
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'ALL'}
      >
        <option value="ALL">ALL</option>
        {opportunityStatuses.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    ),
  },
  {
    Header: 'Class',
    accessor: 'classCode',
    width: 100,
  },
  {
    Header: 'Lesson',
    accessor: 'lessonNumber',
    width: 64,
    filterMethod: (filter, row) => {
      if (isNaN(filter.value)) {
        if (filter.value === 'ALL') {
          return true
        }
        if (filter.value === '-') {
          return row[filter.id] === null
        }
      }
      return row[filter.id] === parseInt(filter.value, 10)
    },
    Filter: ({ filter, onChange }) => {
      return (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'ALL'}
        >
          <option value="ALL">All</option>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => (
            <option key={`gradeFilter${i}`} value={i}>
              {i}
            </option>
          ))}
          <option value="-">-</option>
        </select>
      )
    },
  },
  {
    Header: 'Day',
    accessor: 'lessonDay',
    width: 80,
  },
  {
    Header: 'Date',
    accessor: 'lessonDate',
    width: 100,
    sortMethod: (date1, date2) => {
      return date1 - date2
    },
    Cell: row => convertToMDY(row.value),
    filterMethod: dateFilterMethod,
  },
  {
    Header: 'Time',
    accessor: 'lessonTime',
    width: 100,
  },
  {
    Header: 'Instructor',
    accessor: 'instructor',
    width: 120,
  },
  {
    Header: 'First Name',
    accessor: 'firstName',
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
    filterMethod: (filter, row) => {
      return (
        row[filter.id] &&
        row[filter.id].toLowerCase().startsWith(filter.value.toLowerCase())
      )
    },
  },
]

export default TrialsTable
