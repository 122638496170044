import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import Cookies from 'js-cookie'
import { idName } from '../../config'

import { Paper } from '@mui/material'
import { ErrorMessage, LoadingMessage } from '../../components'
import {
  formatTime,
  formatDay,
  findCurrentDateRange,
} from '../../utils/datetime'
import { isInstructor } from '../../utils/instructors'
import { GET_TRIALS } from './queries'

import TrialTable from './TrialTable'
import TrialSidebar from './TrialSidebar'

const TrialsView = ({ classroomCodes }) => {
  const history = useHistory()
  const employeeId = Cookies.get(idName)

  const [userAuthorized] = useState(() => {
    const userAuthorized = JSON.parse(localStorage.getItem('userAuthorized'))
    return userAuthorized || false
  })
  const [isAdmin] = useState(() => {
    const isAdmin = JSON.parse(localStorage.getItem('isAdmin'))
    return isAdmin || false
  })

  const [myClassrooms, setMyClassrooms] = useState(true)
  const [filtered, setFiltered] = useState([])

  const [rangeStartDate, rangeEndDate] = findCurrentDateRange()

  const getTrials = useQuery(GET_TRIALS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: {
        toLesson: {
          startOn: {
            $gteDate: rangeStartDate.toISOString(),
          },
          endOn: {
            $lteDate: rangeEndDate.toISOString(),
          },
        },
      },
    },
  })

  if (!userAuthorized) return null

  const { loading, error, data } = getTrials
  if (loading) return <LoadingMessage />
  if (error) return <ErrorMessage error={error} />

  const trials = data.trials
    .filter(
      trial =>
        !myClassrooms || isInstructor(trial.toLesson.classroom, employeeId)
    )
    .map(trial => formatTrial(trial))

  const handleRowClick = rowInfo => {
    history.push(`/trial/${rowInfo.original.id}`)
  }

  const handleFilteredChange = filtered => {
    setFiltered(filtered)
  }

  return (
    <Paper sx={{ display: 'flex' }}>
      <TrialSidebar
        isAdmin={isAdmin}
        trials={trials}
        myClassrooms={myClassrooms}
        handleMyClassrooms={setMyClassrooms}
      />
      <Paper sx={{ width: '100%' }}>
        <TrialTable
          trials={trials}
          classroomCodes={classroomCodes}
          filtered={filtered}
          handleRowClick={handleRowClick}
          handleFilteredChange={handleFilteredChange}
        />
      </Paper>
    </Paper>
  )
}

const formatTrial = ({ id, status, toLesson, opportunity }) => ({
  id: id,
  status: status,
  enrollmentStatus: opportunity.status,
  firstName: opportunity.student && opportunity.student.firstName,
  lastName: opportunity.student && opportunity.student.lastName,
  classCode: toLesson.classroom.code,
  instructor: toLesson.classroom.instructorDisplayNames,
  lessonNumber: toLesson.lessonPlan.order,
  lessonDay: formatDay(new Date(toLesson.startOn)),
  lessonDate: new Date(toLesson.startOn),
  lessonTime: formatTime(new Date(toLesson.startOn)),
})

export default TrialsView
