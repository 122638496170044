import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'

import { Text } from '../components'

import IssueView from '../screens/IssueView/IssueView'
import TrialView from '../screens/TrialView'
import MakeupView from '../screens/MakeupView'
import ResponseView from '../screens/ResponseView/ResponseView'
import SubmissionView from '../screens/SubmissionView/SubmissionView'
import ClassViewRouter from '../screens/ClassView'
import TuteeViewRouter from '../screens/TutorSessionView/TuteeViewRouter'
import Cookies from 'js-cookie'
import { idName } from '../config'
import { version } from '../../package.json'

class MainView extends Component {
  render() {
    if (Cookies.get(idName)) {
      return (
        <Switch>
          <Route path="/trial" component={TrialView} />
          <Route path="/makeup" component={MakeupView} />
          <Route path="/response" component={ResponseView} />
          <Route path="/submission" component={SubmissionView} />
          <Route path="/classroom" component={ClassViewRouter} />
          <Route path="/tutee" component={TuteeViewRouter} />
          <Route path="/issue" component={IssueView} />
          <Route
            path="/"
            render={() => (
              <div>
                <br />
                <Text variant="subtitle1">
                  Home screen currently under construction
                </Text>
                <Text variant="subtitle1">
                  Ardent Instructor Version: {version}
                </Text>
              </div>
            )}
          />
        </Switch>
      )
    } else {
      return (
        <div>
          <br />
          <Text variant="subtitle1">Please Login to use Ardent Instructor</Text>
        </div>
      )
    }
  }
}

export default MainView
