import React, { useEffect, useState, useRef } from 'react'
import { useMutation } from '@apollo/client'
import { Divider, TextField, Tooltip, Typography } from '@mui/material'
import {
  EditIssueDialogMain,
  EditIssueDialogDefault,
} from './EditIssueDialogButtons'
import { HintPanel } from './HintPanel'
import { ProblemPanel } from './ProblemPanel'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Flex,
  MenuItem,
  IconButton,
  LinkIcon,
  LaTeX,
  EditIcon,
  Paper,
  makeStyles,
} from '../../components'
import { UPDATE_ISSUE, GET_STUDENT_TOKEN } from './queries'
import Cookies from 'js-cookie'
import { idName } from '../../config'

import DateAdapter from '@mui/lab/AdapterMoment'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DesktopDatePicker from '@mui/lab/DesktopDatePicker'

const EditIssueDialog = props => {
  const [issueStatus, setIssueStatus] = useState(props.edits.issueStatus || '')
  const [issueType, setIssueType] = useState(props.edits.issueType || '')
  const [dateAssigned, setDateAssigned] = useState(props.edits.dateAssigned)
  const [deadline, setDeadline] = useState(props.edits.deadline)
  const [dateEscalated, setDateEscalated] = useState(props.edits.dateEscalated)
  const [description, setDescription] = useState(props.edits.description || '')
  const [response, setResponse] = useState(props.edits.response || '')
  const [editResponse, setEditResponse] = useState(true)
  const [dateResolved, setDateResolved] = useState(
    props.edits.dateResolved || new Date()
  )
  const [employeeId, setEmployeeId] = useState(
    (props.edits.responsibleEmployee && props.edits.responsibleEmployee.id) ||
      ''
  )
  const [submissionId, setSubmissionId] = useState(props.edits.submission.id)
  const [submissionReviewed, setSubmissionReviewed] = useState(
    props.edits.submission.reviewed
  )
  const sendHintRef = useRef()
  const sendSolutionRef = useRef()
  const { open, edits, employee } = props
  const classes = useStyles()
  const defaultEmployeeId = Cookies.get(idName)

  useEffect(() => {
    setIssueStatus(props.edits.issueStatus)
    setIssueType(props.edits.issueType)
    setResponse(props.edits.response)
    setDateAssigned(props.edits.dateAssigned || new Date().toISOString())
    setDeadline(props.edits.deadline || new Date().toISOString())
    setDateEscalated(props.edits.dateEscalated)
    setDateResolved(props.edits.dateResolved)
    setDescription(props.edits.description)
    setEmployeeId(
      props.edits.responsibleEmployee
        ? props.edits.responsibleEmployee.id
        : defaultEmployeeId
    )
    setSubmissionId(props.edits.submission.id)
    setSubmissionReviewed(props.edits.submission.reviewed)
  }, [
    props.edits.issueStatus,
    props.edits.issueType,
    props.edits.response,
    props.edits.dateAssigned,
    props.edits.deadline,
    props.edits.dateEscalated,
    props.edits.dateResolved,
    props.edits.description,
    props.edits.responsibleEmployee,
    props.edits.submission.id,
    props.edits.submission.reviewed,
    defaultEmployeeId,
  ])

  const handleClose = (cancel = true) => {
    props.updateTable()
    props.closeEditDialog(cancel)
  }

  const [updateIssue] = useMutation(UPDATE_ISSUE)
  const handleSave = async () => {
    await updateIssue({
      variables: {
        id: props.edits.id,
        input: {
          issueStatus,
          issueType,
          response,
          dateAssigned: dateAssigned ? dateAssigned : new Date().toISOString(),
          dateResolved,
          dateEscalated,
          deadline,
          description,
          responsibleEmployeeId: employeeId,
        },
      },
    })
    props.updateTable()
    handleClose(false)
  }

  const [getToken] = useMutation(GET_STUDENT_TOKEN)

  return (
    <Dialog open={open} fullScreen={true} fullWidth={false} maxWidth={'lg'}>
      <DialogTitle className={classes.title}>
        <Typography>Edit Issue</Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Flex className={classes.upDownMargin}>
          <Typography>Student: {edits.reporter} </Typography>

          <Tooltip title={'Login as student'}>
            <IconButton
              variant="outlined"
              color={'primary'}
              className={classes.iconButton}
              onClick={() => {
                getToken({
                  variables: {
                    studentId: edits.student.id,
                  },
                }).then(data => {
                  const token = data.data.getStudentToken.token
                  if (process.env.REACT_APP_NODE_ENV !== 'production')
                    window.open(
                      `https://solve-next.ardentlabs.io/guestLogin?${token}`
                    )
                  else
                    window.open(
                      `https://solve.ardentlabs.io/guestLogin?${token}`
                    )
                })
              }}
            >
              <LinkIcon className={classes.icon} />
            </IconButton>
          </Tooltip>

          <Typography className={classes.margin}>
            Class: {edits.classroomCode}
          </Typography>
          <Typography className={classes.margin}>
            Report Date: {edits.reportDate}
          </Typography>
          <Typography className={classes.margin}>
            Report Time: {edits.reportTime}
          </Typography>
        </Flex>
        <Flex className={classes.upDownMargin}>
          <TextField
            name="status"
            label="Status: "
            variant="standard"
            select
            className={classes.selectorWidth}
            value={issueStatus}
            onChange={event => {
              if (props.edits.dateEscalated === null) {
                if (event.target.value === 'ESCALATED') {
                  setDateEscalated(new Date().toISOString())
                } else {
                  setDateEscalated(props.edits.dateEscalated)
                }
              }
              setIssueStatus(event.target.value)
            }}
          >
            {props.statuses.map(status => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            name="type"
            label="Type: "
            variant="standard"
            select
            className={`${classes.selectorWidth} ${classes.margin}`}
            value={issueType}
            onChange={event => setIssueType(event.target.value)}
          >
            {props.types.map(type => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            name="assigned"
            label="Assigned To: "
            variant="standard"
            select
            className={`${classes.selectorWidth} ${classes.margin}`}
            value={employeeId}
            onChange={event => setEmployeeId(event.target.value)}
          >
            {props.employees.map(employee => (
              <MenuItem key={employee.id} value={employee.id}>
                {`${employee.firstName} ${employee.lastName}`}
              </MenuItem>
            ))}
          </TextField>
          <Flex className={classes.margin}>
            <Flex>
              <Typography className={classes.dateText}>Resolved: </Typography>
            </Flex>
            <Flex>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <DesktopDatePicker
                  inputFormat="MM/DD/YYYY"
                  value={dateResolved}
                  onChange={newValue => setDateResolved(newValue)}
                  renderInput={params => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Flex>
          </Flex>
        </Flex>
        <Divider />
        <Flex className={classes.row}>
          <Flex column className={classes.left}>
            <ProblemPanel
              id={props.edits.id}
              ref={sendSolutionRef}
              edits={edits}
              problem={edits.problem}
              updateTable={props.updateTable}
              handleClose={handleClose}
            />
          </Flex>
          <Flex column className={classes.right}>
            <TextField
              multiline
              rows="4"
              className={classes.upDownMargin}
              variant="outlined"
              label="Issue Description:"
              value={description ? description : ''}
              onChange={event => setDescription(event.target.value)}
            />
            <HintPanel
              ref={sendHintRef}
              id={props.edits.id}
              problemSetTitle={edits.problem.problemSet.title}
              problemId={edits.problem.id}
              videoId={edits.problem.problemSet.videoId}
              order={edits.problemOrder}
              employeeId={employee}
              setResponse={setResponse}
              updateTable={props.updateTable}
              handleClose={handleClose}
              hints={edits.problem.hints}
            />
            <Paper square className={classes.upDownMargin}>
              <Flex style={{ justifyContent: 'flex-end' }}>
                <IconButton
                  className={classes.editIconButton}
                  color={editResponse ? 'primary' : 'secondary'}
                  onClick={() => {
                    setEditResponse(!editResponse)
                  }}
                >
                  <EditIcon className={classes.editIcon} />
                </IconButton>
              </Flex>
              <Flex
                style={{
                  width: '100%',
                  justifyContent: 'center',
                  marginBottom: '2%',
                }}
              >
                {editResponse ? (
                  <TextField
                    multiline
                    rows="4"
                    style={{ width: '96%' }}
                    variant="outlined"
                    label="Response to Student:"
                    value={response ? response : ''}
                    onChange={event => setResponse(event.target.value)}
                  />
                ) : (
                  <Flex p={'12px 14px'} className={classes.textPreview}>
                    {response !== null && <LaTeX latex={response} />}
                  </Flex>
                )}
              </Flex>
            </Paper>
            <EditIssueDialogDefault
              className={classes.buttonGroup}
              updateTable={props.updateTable}
              submissionReviewed={submissionReviewed}
              setSubmissionReviewed={setSubmissionReviewed}
              submissionId={submissionId}
              edits={edits}
            />
          </Flex>
        </Flex>
      </DialogContent>
      <EditIssueDialogMain
        id={props.edits.id}
        hintRef={sendHintRef}
        solutionRef={sendSolutionRef}
        submissionId={submissionId}
        className={classes.buttons}
        employeeId={employee}
        response={response}
        dateAssigned={dateAssigned}
        updateTable={props.updateTable}
        handleClose={handleClose}
        handleSave={handleSave}
      />
    </Dialog>
  )
}

const useStyles = makeStyles(theme => ({
  title: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: '#bdbdbd',
  },
  row: {
    justifyContent: 'space-evenly',
  },
  margin: {
    marginLeft: '7%',
  },
  selectorWidth: {
    minWidth: '160px',
  },
  left: {
    width: '60%',
    margin: '2% 1%',
  },
  right: {
    width: '40%',
    margin: '2% 1%',
  },
  upDownMargin: {
    margin: '1% 0',
  },
  assignTo: {
    marginTop: '20px',
    width: '87%',
  },
  dateText: {
    marginTop: '20px',
    marginRight: '10px',
  },
  buttons: {
    marginBottom: '5%',
  },
  buttonGroup: {
    marginTop: '20px',
  },
  icon: {
    paddingBottom: 5,
    width: 24,
    height: 24,
  },
  iconButton: {
    marginLeft: '.5%',
    padding: 0,
    width: 30,
    height: 30,
  },
  editIconButton: {
    width: 42,
    height: 42,
    marginRight: '1.5%',
    justifyContent: 'flex-end',
  },
  editIcon: {
    width: 20,
    height: 20,
  },
  textPreview: {
    borderRadius: '4px',
    border: '1px solid rgb(187, 187, 187)',
    margin: '1% 0',
    width: '92%',
  },
}))

export default EditIssueDialog
