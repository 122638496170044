import React, { useState } from 'react'

import ReactTable from 'react-table-6'
import EditIssueDialogWrapper from './EditIssueDialogWrapper'
import { Box } from '@mui/material'

const issueTypes = ['NEED_HELP', 'REPORT_PROBLEM', 'ATTENDANCE', 'MESSAGE']
const issueStatus = [
  'ALL',
  'INITIATED',
  'INVESTIGATING',
  'ESCALATED',
  'INPROGRESS',
  'RESOLVED',
]

const IssueTable = ({
  issues,
  employeeId,
  employees,
  page,
  pages,
  pageSize,
  filtered,
  handleFilteredChange,
  handleFetchData,
  handlePageChange,
  updateTable,
}) => {
  const [editOpen, setEditOpen] = useState(false)
  const [issueId, setIssueId] = useState('')

  const defaultSorted = [{ id: 'duration' }]

  const handleEditIssue = editInfo => {
    if (editInfo === undefined) return
    setIssueId(editInfo.id)
    setEditOpen(!editOpen)
  }

  return (
    <Box align="left">
      <ReactTable
        data={issues}
        defaultFiltered={filtered}
        onFilteredChange={handleFilteredChange}
        onPageChange={handlePageChange}
        columns={columns}
        filterable={true}
        defaultSorted={defaultSorted}
        className="-highlight"
        style={{ fontSize: '10pt' }}
        defaultPageSize={25}
        // loading={loading}
        manual
        page={page}
        pageSize={pageSize}
        pages={pages}
        onFetchData={handleFetchData}
        getTdProps={(state, rowInfo, column, instance) => {
          return {
            onClick: (e, handleOriginal) => {
              if (column.Header !== undefined) {
                handleEditIssue(rowInfo && rowInfo.original)
              }
              if (handleOriginal) {
                handleOriginal()
              }
            },
          }
        }}
        getTheadProps={(state, rowInfo, column, instance) => {
          return {
            style: {
              fontWeight: 'bold',
              borderBottom: '1px solid #E5E5E5',
            },
          }
        }}
      />
      {editOpen && (
        <EditIssueDialogWrapper
          issueId={issueId}
          open={editOpen}
          employees={employees}
          employee={employeeId}
          statuses={issueStatus}
          types={issueTypes}
          closeEditDialog={cancel => {
            setEditOpen(false)
          }}
          updateTable={updateTable}
        />
      )}
    </Box>
  )
}

const columns = [
  {
    Header: 'Status',
    accessor: 'issueStatus',
    minWidth: 110,
    maxWidth: 110,
    filterMethod: (filter, row) => {
      if (filter.value !== 'ALL') {
        return row[filter.id] === filter.value
      }
      return true
    },
    Filter: ({ filter, onChange }) => (
      <select
        onChange={event => {
          onChange(event.target.value)
        }}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'ALL'}
      >
        {issueStatus.map(statue => {
          return (
            <option key={statue} value={statue}>
              {statue}
            </option>
          )
        })}
      </select>
    ),
  },
  {
    Header: 'Date',
    accessor: 'reportDate',
    minWidth: 100,
    maxWidth: 100,
  },
  {
    Header: 'Time',
    accessor: 'reportTime',
    minWidth: 100,
    maxWidth: 100,
  },
  {
    Header: 'Type',
    accessor: 'issueType',
    minWidth: 110,
    maxWidth: 150,
    filterMethod: (filter, row) => {
      if (filter.value !== 'ALL') {
        return row[filter.id] === filter.value
      }
      return true
    },
    Filter: ({ filter, onChange }) => (
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'ALL'}
      >
        <option value="ALL">ALL</option>
        {issueTypes.map(type => (
          <option key={type} value={type}>
            {type}
          </option>
        ))}
      </select>
    ),
  },
  {
    Header: 'First Name',
    accessor: 'firstName',
    minWidth: 110,
    maxWidth: 150,
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
    minWidth: 110,
    maxWidth: 150,
  },
  {
    Header: 'Class',
    accessor: 'classroomCode',
    minWidth: 80,
    maxWidth: 80,
  },
  {
    Header: 'LL-AA-PP',
    accessor: 'lapCode',
    minWidth: 80,
    maxWidth: 80,
  },
  {
    Header: 'Employee',
    accessor: 'employee',
    minWidth: 110,
    maxWidth: 150,
  },
  {
    Header: 'Description',
    accessor: 'description',
    minWidth: 110,
  },
]

export default IssueTable
