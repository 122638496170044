import React from 'react'
import ReactTable from 'react-table-6'

const SubmissionTable = props => {
  const { resolvedSubmissions, rowClick } = props

  const columns = [
    {
      Header: 'Status',
      accessor: 'status',
      width: 120,
      Cell: props => {
        return (
          <span
            style={{
              color:
                props.value === 'SUBMITTED'
                  ? '#27AE60'
                  : props.value === 'ARCHIVED'
                  ? '#EB5757'
                  : '#FCE205',
            }}
          >
            {props.value}
          </span>
        )
      },
      filterMethod: (filter, row) => {
        if (filter.value !== 'ALL') {
          return row[filter.id] === filter.value
        }
        return true
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={event => onChange(event.target.value)}
          style={{ width: '100%' }}
          value={filter ? filter.value : 'ALL'}
        >
          <option value="ALL">ALL</option>
          <option value="ARCHIVED">ARCHIVED</option>
          <option value="IN-PROGRESS">IN-PROGRESS</option>
          <option value="SUBMITTED">SUBMITTED</option>
        </select>
      ),
    },
    {
      Header: 'Student',
      accessor: 'fullName',
      width: 200,
    },
    {
      Header: 'Class',
      accessor: 'code',
      width: 100,
    },
    {
      Header: 'Lesson',
      accessor: 'lessonOrder',
      width: 65,
    },
    {
      Header: 'Started At',
      accessor: 'startedAt',
    },
    {
      Header: 'Submitted At',
      accessor: 'submittedAt',
    },
    {
      Header: 'Count',
      accessor: 'count',
      width: 65,
    },
    {
      Header: 'Sum',
      accessor: 'sum',
      width: 65,
    },
    {
      Header: 'Grade',
      accessor: 'overallGrade',
      width: 65,
    },
    {
      Header: 'Attempts',
      accessor: 'attemptNumber',
      width: 65,
    },
    {
      Header: 'Late',
      accessor: 'submittedLate',
      width: 65,
    },
    {
      Header: 'Graded',
      accessor: 'graded',
      width: 65,
    },
    {
      Header: 'Reviewed',
      accessor: 'reviewed',
      width: 65,
    },
    {
      Header: 'Coins',
      accessor: 'coins',
      width: 65,
    },
    {
      Header: 'Time',
      accessor: 'totalTimeSpent',
      width: 100,
    },
  ]

  return (
    <div align="left">
      <ReactTable
        columns={columns}
        data={resolvedSubmissions}
        filterable={true}
        className="-highlight"
        defaultSorted={[{ id: 'code' }]}
        style={{ fontSize: '10pt' }}
        defaultPageSize={25}
        getTdProps={(state, rowInfo, column, instance) => {
          return {
            onClick: (e, handleOriginal) => {
              rowClick(rowInfo)
            },
          }
        }}
        getTheadProps={(_state, _rowInfo, _column, _instance) => {
          return {
            style: {
              fontWeight: 'bold',
              borderBottom: '1px solid #E5E5E5',
            },
          }
        }}
      />
    </div>
  )
}

export default SubmissionTable
