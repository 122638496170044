import React from 'react'

import {
  ClassroomSwitcher,
  Divider,
  SidebarText,
  SidebarContainer,
  withStyles,
} from '../../components'
import { sideBarStyles } from '../../utils/style'

const ClassSidebar = ({
  classrooms,
  isAdmin,
  myClassrooms,
  handleMyClassrooms,
  classes,
}) => {
  const totalClasses = classrooms.length

  const totalStudents = classrooms.reduce((accumulator, classroom) => {
    return (
      accumulator +
      (classroom.enrollmentCount !== null ? classroom.enrollmentCount : 0)
    )
  }, 0)

  const mathOStudents = classrooms.reduce((accumulator, classroom) => {
    return (
      accumulator +
      ((classroom.code.startsWith('M2') || classroom.code.startsWith('M3')) &&
      classroom.enrollmentCount !== null
        ? classroom.enrollmentCount
        : 0)
    )
  }, 0)

  const mathAStudents = classrooms.reduce((accumulator, classroom) => {
    return (
      accumulator +
      (classroom.code.startsWith('M1') && classroom.enrollmentCount !== null
        ? classroom.enrollmentCount
        : 0)
    )
  }, 0)

  const biologyStudents = classrooms.reduce((accumulator, classroom) => {
    return (
      accumulator +
      (classroom.code.startsWith('B') && classroom.enrollmentCount !== null
        ? classroom.enrollmentCount
        : 0)
    )
  }, 0)

  const chemistryStudents = classrooms.reduce((accumulator, classroom) => {
    return (
      accumulator +
      (classroom.code.startsWith('C') && classroom.enrollmentCount !== null
        ? classroom.enrollmentCount
        : 0)
    )
  }, 0)

  const physicsStudents = classrooms.reduce((accumulator, classroom) => {
    return (
      accumulator +
      (classroom.code.startsWith('N') && classroom.enrollmentCount !== null
        ? classroom.enrollmentCount
        : 0)
    )
  }, 0)

  const scienceStudents = classrooms.reduce((accumulator, classroom) => {
    return (
      accumulator +
      (classroom.code.startsWith('S') && classroom.enrollmentCount !== null
        ? classroom.enrollmentCount
        : 0)
    )
  }, 0)

  const codingStudents = classrooms.reduce((accumulator, classroom) => {
    return (
      accumulator +
      (classroom.code.startsWith('P') && classroom.enrollmentCount !== null
        ? classroom.enrollmentCount
        : 0)
    )
  }, 0)

  const techStudents = classrooms.reduce((accumulator, classroom) => {
    return (
      accumulator +
      (classroom.code.startsWith('T') && classroom.enrollmentCount !== null
        ? classroom.enrollmentCount
        : 0)
    )
  }, 0)

  const englishStudents = classrooms.reduce((accumulator, classroom) => {
    return (
      accumulator +
      (classroom.code.startsWith('E') && classroom.enrollmentCount !== null
        ? classroom.enrollmentCount
        : 0)
    )
  }, 0)

  const speechStudents = classrooms.reduce((accumulator, classroom) => {
    return (
      accumulator +
      (classroom.code.startsWith('D') && classroom.enrollmentCount !== null
        ? classroom.enrollmentCount
        : 0)
    )
  }, 0)

  const researchStudents = classrooms.reduce((accumulator, classroom) => {
    return (
      accumulator +
      (classroom.code.startsWith('A') && classroom.enrollmentCount !== null
        ? classroom.enrollmentCount
        : 0)
    )
  }, 0)

  return (
    <SidebarContainer>
      <ClassroomSwitcher
        title={'Classes'}
        disabled={!isAdmin}
        checked={!myClassrooms}
        handleMyClassrooms={handleMyClassrooms}
      />
      <SidebarText
        classes={{
          root: classes.headline,
        }}
        title="Summary View"
      />
      <Divider />
      <SidebarText
        classes={{ root: classes.subheadingOne }}
        title="Total Classes:"
        value={totalClasses}
      />
      <SidebarText
        classes={{ root: classes.subheadingOne }}
        title="Total Students:"
        value={totalStudents}
      />
      <Divider />
      <SidebarText title="Math Olympiad:" value={mathOStudents} />
      <SidebarText title="Math Acceleration:" value={mathAStudents} />
      <SidebarText title="Biology:" value={biologyStudents} />
      <SidebarText title="Chemistry:" value={chemistryStudents} />
      <SidebarText title="Physics:" value={physicsStudents} />
      <SidebarText title="Science:" value={scienceStudents} />
      <SidebarText title="Coding:" value={codingStudents} />
      <SidebarText title="Technology:" value={techStudents} />
      <SidebarText title="English:" value={englishStudents} />
      <SidebarText title="Speech & Debate:" value={speechStudents} />
      <SidebarText title="Research:" value={researchStudents} />
    </SidebarContainer>
  )
}

export default withStyles(sideBarStyles)(ClassSidebar)
