import React from 'react'
import { makeStyles } from '@mui/styles'
import { MenuItem, TextField } from '.'

const useStyles = makeStyles({
  menu: {
    width: '20%',
  },
  asterisk: {
    color: 'red',
  },
})

function StatusSelector(props) {
  const classes = useStyles()
  return (
    <TextField
      required
      InputProps={{
        readOnly: props.readOnly,
      }}
      name="status"
      label={props.label}
      variant="standard"
      value={props.selectedOptionId}
      select
      onChange={props.handleChange}
      disabled={props.readOnly}
      SelectProps={{
        MenuProps: {
          className: classes.menu,
        },
      }}
      InputLabelProps={{
        formlabelclasses: {
          asterisk: classes.asterisk,
        },
        shrink: !!props.status,
      }}
    >
      {props.statusOptions
        .filter(status => status !== 'RESCHEDULED')
        .map(option => (
          <MenuItem
            key={option
              .replace(/(\B)[^ ]*/g, match => match.toLowerCase())
              .replace(/_/g, ' ')
              .replace(/^[^ ]/g, match => match.toUpperCase())}
            value={option.toUpperCase().replace(/ /g, '_')}
          >
            {option
              .replace(/(\B)[^ ]*/g, match => match.toLowerCase())
              .replace(/_/g, ' ')
              .replace(/^[^ ]/g, match => match.toUpperCase())}
          </MenuItem>
        ))}
    </TextField>
  )
}

export default StatusSelector
