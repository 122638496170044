import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { Button, TextField, Typography } from '@mui/material'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '../../components'

import { SEND_EMAIL_TO_FAMILY_MEMBER } from './queries'

const EmailMessage = props => {
  const [message, setMessage] = useState('')
  const [subject, setSubject] = useState('')

  const [EmailMutation] = useMutation(SEND_EMAIL_TO_FAMILY_MEMBER)

  const handleSend = async sendEmail => {
    props.setSnackbar({
      open: true,
      message: 'Sending Email Messages',
      messageType: 'loading',
    })

    const { familyMemberIds } = props
    const sendEmailPromises = await Promise.all(
      familyMemberIds.map(async familyMemberId => {
        return await sendEmail({
          variables: {
            familyMemberId: familyMemberId,
            subject: subject,
            textMessage: message.replace(/<[^>]*>?/gm, ''),
            htmlMessage: message,
          },
        })
      })
    )
    const success = sendEmailPromises.every(
      data => data.data.sendEmailToFamilyMember.message === 'SUCCESS'
    )
    props.setSnackbar({
      open: true,
      message: success ? 'Email Sent ✔' : 'error',
      messageType: success ? 'success' : 'error',
    })
    props.handleClose()
  }

  const students = props.checkedStudents
    .map(student => `${student.firstName}, ${student.lastName}`)
    .join('; ')
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      width="70%"
      maxWidth="lg"
    >
      <DialogTitle id="form-dialog-title">
        Email Parents of Following Students:
      </DialogTitle>
      <DialogContent>
        {<Typography>{students}</Typography>}
        <DialogContentText
          sx={{
            my: 2,
          }}
        >
          Enter a message you would like to send
        </DialogContentText>
        <TextField
          id="subject"
          label="Subject"
          variant="outlined"
          fullWidth
          multiline
          rows={1}
          style={{ paddingBottom: '10px' }}
          value={subject}
          onChange={e => setSubject(e.target.value)}
        />
        <TextField
          id={'message'}
          label={'Email Message'}
          value={message}
          fullWidth
          multiline
          rows={6}
          style={{ paddingBottom: '10px' }}
          onChange={e => setMessage(e.target.value)}
        />

        <DialogActions>
          <Button
            variant="contained"
            onClick={props.handleClose}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            disabled={subject.length === 0 || message.length === 0}
            onClick={async () => {
              handleSend(EmailMutation)
            }}
          >
            Send
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default EmailMessage
