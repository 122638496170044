import React from 'react'
import { Flex, SyncIcon, Divider } from '../../../components'
import { Button, Tooltip } from '@mui/material'
import { useMutation } from '@apollo/client'
import { REPAIR_ATTENDEE } from '../queries'

export const AttendanceHeader = React.memo(({ classId }) => {
  const [repairAttendance] = useMutation(REPAIR_ATTENDEE)
  return (
    <React.Fragment>
      <Divider orientation="vertical" flexItem style={{ marginLeft: 'auto' }} />

      {() => (
        <Tooltip title={'Repair Attendee Records'}>
          <Button
            variant="text"
            style={{ width: '6em' }}
            color="primary"
            onClick={async () => {
              await repairAttendance({ variables: { id: classId } })
            }}
          >
            <Flex column>
              <Flex style={{ marginBottom: '.5em', justifyContent: 'center' }}>
                <SyncIcon />
              </Flex>
            </Flex>
          </Button>
        </Tooltip>
      )}
    </React.Fragment>
  )
})

export default AttendanceHeader
