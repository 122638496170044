import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import {
  Avatar,
  Checkbox,
  Flex,
  IconMaker,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
} from '../../components'
import { online_icon } from '../../components/assests'

const StudentColumn = React.memo(
  ({ students, viewType, checked, handleSingleCheck }) => {
    const classes = useStyles()
    const hideRowFork = viewType === 'enrollment' || viewType === 'attendance'
    return (
      <Flex grow={1}>
        <Table>
          <TableHead>
            {!hideRowFork && <RowFork viewType={viewType} />}
            <TableRow>
              {checked && (
                <TableCell
                  variant={'head'}
                  className={classes.defaultCellStyle}
                />
              )}
              <TableCell
                variant={'head'}
                align={'center'}
                className={classes.StudentNameCellStyle}
              >
                Student
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {students.map((student, index) => (
              <StudentRow
                key={student.id}
                student={student}
                check={checked[index]}
                index={index}
                handleSingleCheck={handleSingleCheck}
              />
            ))}
          </TableBody>
        </Table>
      </Flex>
    )
  }
)

const RowFork = React.memo(({ viewType }) => {
  const viewTypeHeading = viewType[0].toUpperCase() + viewType.substring(1)
  const classes = useStyles()
  return (
    <Fragment>
      <TableRow>
        <TableCell
          variant={'head'}
          align={'center'}
          colSpan={2}
          className={classes.StudentNameCellStyle}
        >
          {viewTypeHeading}
        </TableCell>
      </TableRow>
    </Fragment>
  )
})

const StudentRow = React.memo(
  ({ student, check, index, handleSingleCheck }) => {
    const classes = useStyles()
    return (
      <TableRow key={student.studentId} hover>
        <TableCell className={classes.defaultCellStyle} style={{ width: 28 }}>
          <Checkbox
            color="primary"
            value={check}
            onClick={e => handleSingleCheck(index)}
          />
        </TableCell>
        <TableCell
          className={classes.StudentNameCellStyle}
          style={{ width: '100%' }}
        >
          <Flex>
            <Avatar
              src={student.imageUrl}
              alt={'student-image'}
              style={{
                marginLeft: '0.5em',
                backgroundColor:
                  student.gender === 'MALE' ? 'lightblue' : 'pink',
              }}
            >
              {student.firstName[0] + student.lastName[0]}
            </Avatar>
            <Flex
              style={{
                marginLeft: '1em',
                marginRight: '1em',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <Flex style={{ width: 'max-content', alignItems: 'center' }}>
                <Link
                  target="_blank"
                  to={`/student/${student.studentId}` || '/#'}
                  style={{ textDecoration: 'none' }}
                >
                  <Text>
                    {student.firstName} {student.lastName}
                  </Text>
                </Link>
              </Flex>
            </Flex>
            {student.active && (
              <IconMaker
                src={online_icon}
                height={'22px'}
                width={'22px'}
                alt={'icon'}
                style={{ marginRight: '1em', alignSelf: 'center' }}
              />
            )}
          </Flex>
        </TableCell>
      </TableRow>
    )
  }
)

const useStyles = makeStyles(theme => ({
  mainTable: {
    marginTop: '15px',
  },
  defaultCellStyle: {
    height: '3em',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'lightgrey',
    padding: '5px 5px 5px 5px',
    align: 'center',
    width: '100%',
  },
  StudentNameCellStyle: {
    height: '3em',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRightWidth: 0,
    borderColor: 'lightgrey',
    padding: '5px 5px 5px 5px',
    align: 'center',
    width: '100%',
  },
  GradebookHeaderCellStyle: {
    maxHeight: '3em',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'lightgrey',
    padding: '5px 5px 5px 5px',
    alignItems: 'center',
    justifyContent: 'center',
    shrink: 1,
    grow: 1,
  },
  HalfCellStyle: {
    height: '3em',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRightWidth: 0,
    borderColor: 'lightgrey',
    padding: '5px 5px 5px 5px',
    align: 'center',
    width: '50%',
  },
  bottomNavigation: {
    root: {
      width: '100%',
      position: 'fixed',
      bottom: 0,
    },
  },
  tableContainer: {
    maxHeight: '100%',
    overflow: 'auto',
  },
  studentNameHeader: {
    width: '20em',
  },
  iconButton: {
    width: 20,
    height: 20,
  },
}))

export default StudentColumn
