import React from 'react'
import { Link } from 'react-router-dom'

import { Box, Tab, Tabs } from '@mui/material'

const TabBar = props => {
  const matchedPath = props.path.match(/^\/[\w]*/)[0].substring(1)
  const tabs = [
    {
      name: 'home',
      displayText: 'Home',
      path: '/home',
    },
    {
      name: 'trial',
      displayText: 'Trial',
      path: '/trial',
    },
    {
      name: 'makeup',
      displayText: 'Makeup',
      path: '/makeup',
    },
    {
      name: 'classroom',
      displayText: 'Class',
      path: '/classroom',
    },
    {
      name: 'tutee',
      displayText: 'Tutee',
      path: '/tutee',
    },
    // {
    //   name: 'submission',
    //   displayText: 'Submission',
    //   path: '/submission',
    // },
    // {
    //   name: 'response',
    //   displayText: 'Response',
    //   path: '/response'
    // },
    {
      name: 'issue',
      displayText: 'Issue',
      path: '/issue',
    },
  ]

  const determineTabValue = () => {
    const tabIndex = tabs.findIndex(tab => tab.name === matchedPath)
    return tabIndex >= 0 ? tabIndex : 0
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={determineTabValue()} variant="scrollable">
          {tabs.map((tab, index) => (
            <Tab
              label={tab.displayText}
              component={Link}
              key={tab.name}
              to={tab.path}
            />
          ))}
        </Tabs>
      </Box>
    </Box>
  )
}

export default TabBar
