// locaton date is the date at the location where a class is offered
// currently, we assume all classes are offered at PST
// TODO (ZLI): make it a generic module for all projects
import moment from 'moment-timezone'

const DEFAULT_TIMEZONE = 'America/Los_Angeles'

const locationDate = localDate => {
  const centerLocationOffset = -8 // TODO (DRLI): Hard code for PST, replace with local office offset and day light saving
  return new Date(
    localDate.getTime() +
      localDate.getTimezoneOffset() * 60000 +
      centerLocationOffset * 3600000
  )
}

export const formatDateHMA = (start, end) => {
  return `${moment(start).format('hh:mm a')} - ${moment(end).format('hh:mm a')}`
}

export const formatDayDMY = day => {
  return moment(day).format('dddd, MMMM Do YYYY')
}

export const convertToMDY = utcDate => {
  const localDate = moment.tz(utcDate, DEFAULT_TIMEZONE)
  if (!localDate) return null

  return localDate.format('L')
}

export const formatMMDD = day => {
  return moment(day).format('MM/DD')
}

export const convertToHMA = utcDate => {
  const localDate = moment.tz(utcDate, DEFAULT_TIMEZONE)
  if (!localDate) return null

  return localDate.format('LT')
}
export const convertToDateTimeString = utcDate => {
  const localDate = moment.tz(utcDate, DEFAULT_TIMEZONE)
  if (!localDate) return null

  return localDate.format('LLLL')
}

export const convertToYMD = utcDate => {
  const localDate = new Date(utcDate)
  if (!localDate) return null

  let month = '' + (localDate.getUTCMonth() + 1)
  let day = '' + localDate.getUTCDate()
  let year = '' + localDate.getUTCFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day
  while (year.length < 4) year = '0' + year

  return [year, month, day].join('-')
}

export const convertToMDYA = utcDate => {
  const localDate = new Date(utcDate)
  return moment(localDate).format('MMM DD, YYYY hh:mm A')
}

export const convertToDateString = utcDate => {
  const localDate = new Date(utcDate)
  if (!localDate) return null

  return locationDate(localDate).toDateString()
}

export const convertToCenterDate = utcDate => {
  const localDate = new Date(utcDate)
  if (!localDate) return null
  return locationDate(localDate)
}

export const formatTime = utcDate => {
  const localDate = moment.tz(utcDate, DEFAULT_TIMEZONE)
  if (!localDate) return null

  return localDate.format('hh:mm A')
}

export const formatDuration = ms => {
  if (ms < 0) return null
  let seconds = ms / 1000
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds - 3600 * hours) / 60)
  const HH = hours < 9 ? '0' + hours : hours
  const MM = minutes < 9 ? '0' + minutes : minutes
  return HH + ':' + MM
}

// formatTimeRange takes two Date objects and returns a time range string.
// (e.g. "4:20 PM — 4:40 PM")
export const formatTimeRange = (utcStartDate, utcEndDate) => {
  return formatTime(utcStartDate) + ' - ' + formatTime(utcEndDate)
}

export const formatDay = utcDate => {
  const localDate = moment.tz(utcDate, DEFAULT_TIMEZONE)
  switch (localDate.day()) {
    case 0:
      return 'SUN'
    case 1:
      return 'MON'
    case 2:
      return 'TUE'
    case 3:
      return 'WED'
    case 4:
      return 'THU'
    case 5:
      return 'FRI'
    case 6:
      return 'SAT'
    default:
      return ''
  }
}

export const formatDayTimeRange = (utcStartDate, utcEndDate) => {
  const dayOfWeek = formatDay(utcStartDate)
  const timeRange = formatTimeRange(utcStartDate, utcEndDate)
  return dayOfWeek + ' ' + timeRange
}

const treatAsUTC = date => {
  const result = new Date(date)
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset())
  return result
}

export const daysBetween = (startDate, endDate) => {
  const millisecondsPerDay = 24 * 60 * 60 * 1000
  return Math.round(
    (treatAsUTC(startDate) - treatAsUTC(endDate)) / millisecondsPerDay
  )
}

export const dateFilterMethod = (filter, row) =>
  row[filter.id] &&
  convertToMDY(row[filter.id])
    .toLowerCase()
    .startsWith(filter.value)

export const computeRoundedTime = date => {
  date.setMinutes(Math.round(date.getMinutes() / 10) * 10)
  date.setSeconds(0)
  return date
}

export const isThisWeek = date => {
  const now = moment()
  return now.isoWeek() === moment(date).isoWeek()
}

export const isBetweenDate = (current, previous, end) => {
  return moment(current).isBetween(moment(previous), moment(end))
}

export const findCurrentDateRange = () => {
  let currentYear = new Date().getFullYear()
  let lastYear = currentYear - 1
  let nextYear = currentYear + 1
  let currentMonth = new Date().getMonth() + 1
  return 1 <= currentMonth && currentMonth <= 2
    ? [new Date('Nov 25 ' + lastYear), new Date('Mar 31 ' + currentYear)]
    : 3 <= currentMonth && currentMonth <= 5
    ? [new Date('Feb 20 ' + currentYear), new Date('May 31 ' + currentYear)]
    : 6 <= currentMonth && currentMonth <= 7
    ? [new Date('Jun 16 ' + currentYear), new Date('Aug 31 ' + currentYear)]
    : currentMonth === 8
    ? [new Date('Aug 1 ' + currentYear), new Date('Sep 30 ' + currentYear)]
    : 9 <= currentMonth && currentMonth <= 10
    ? [new Date('Sep 1 ' + currentYear), new Date('Nov 30 ' + currentYear)]
    : currentMonth === 11
    ? [new Date('Sep 1 ' + currentYear), new Date('Dec 31 ' + currentYear)]
    : currentMonth === 12
    ? [new Date('Nov 25 ' + currentYear), new Date('Mar 1 ' + nextYear)]
    : []
}
