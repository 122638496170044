import React from 'react'
import { useQuery } from '@apollo/client'
import { convertToMDY, formatTime } from '../../utils/datetime'

import { ErrorMessage, LoadingMessage } from '../../components'
import { GET_ISSUE } from './queries'
import EditIssueDialog from './EditIssueDialog'

const EditIssueDialogWrapper = props => {
  const { issueId } = props

  const getIssueDetails = useQuery(GET_ISSUE, {
    fetchPolicy: 'network-only',
    variables: {
      id: issueId,
    },
    context: {
      headers: {
        'cache-control': 'no-cache',
      },
    },
  })
  const { loading, error, data } = getIssueDetails
  if (loading) return <LoadingMessage />
  if (error) return <ErrorMessage error={error} />

  const lapCodeArray = data.issueDetail.issue.lapCode.split('-')
  const issueDetail = formatIssueDetail(data.issueDetail, lapCodeArray)

  return <EditIssueDialog {...props} edits={issueDetail} />
}

const formatIssueDetail = (
  { issue, problem, problemResponse, courseId, lessonPlanId, problemSetId },
  lapCodeArray
) => ({
  ...issue,
  problem: problem,
  problemResponse: problemResponse,
  courseId: courseId,
  lessonPlanId: lessonPlanId,
  problemSetId: problemSetId,
  submission: problemResponse.submission,
  lessonPlanOrder: parseInt(lapCodeArray[0]),
  problemSetOrder: parseInt(lapCodeArray[1]),
  problemOrder: parseInt(lapCodeArray[2]),
  classroomCode: issue.classroom.code,
  reporter: `${issue.student.firstName} ${issue.student.lastName}`,
  reportDate: issue.createdOn && convertToMDY(issue.createdOn),
  reportTime: issue.createdOn && formatTime(issue.createdOn),
  employee:
    issue.responsibleEmployee &&
    `${issue.responsibleEmployee.firstName} ${issue.responsibleEmployee.lastName}`,
})

export default EditIssueDialogWrapper
