import React from 'react'

import {
  ClassroomSwitcher,
  Divider,
  SidebarText,
  SidebarContainer,
  withStyles,
} from '../../components'
import { sideBarStyles } from '../../utils/style'

const MakeupSidebar = ({
  makeups,
  isAdmin,
  myClassrooms,
  handleMyClassrooms,
  classes,
}) => {
  const scheduledMakeups = makeups.reduce((accumulator, makeup) => {
    return accumulator + (makeup.status === 'SCHEDULED' ? 1 : 0)
  }, 0)

  const attendedMakeups = makeups.reduce((accumulator, makeup) => {
    return accumulator + (makeup.status === 'ATTENDED' ? 1 : 0)
  }, 0)

  const absentMakeups = makeups.reduce((accumulator, makeup) => {
    return accumulator + (makeup.status === 'ABSENT' ? 1 : 0)
  }, 0)

  return (
    <SidebarContainer>
      <ClassroomSwitcher
        title={'Classes'}
        disabled={!isAdmin}
        checked={!myClassrooms}
        handleMyClassrooms={handleMyClassrooms}
      />
      <SidebarText
        classes={{
          root: classes.headline,
        }}
        title="Detail View"
      />
      <Divider />
      <SidebarText
        classes={{ root: classes.subheadingOne }}
        title="Total Makeups:"
        value={makeups.length}
      />
      <SidebarText title="Scheduled:" value={scheduledMakeups} />
      <SidebarText title="Attended:" value={attendedMakeups} />
      <SidebarText title="Absent:" value={absentMakeups} />
    </SidebarContainer>
  )
}

export default withStyles(sideBarStyles)(MakeupSidebar)
