import React from 'react'

import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

const CustomAccordionSummary = ({
  title,
  expandedIcon,
  style,
  children,
  ...otherProps
}) => (
  <AccordionSummary
    expandIcon={expandedIcon ? expandedIcon : <ExpandMoreIcon />}
    style={style ? style : { backgroundColor: '#f0f0f0' }}
    {...otherProps}
  >
    {children}
  </AccordionSummary>
)

export {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ExpandMoreIcon,
  CustomAccordionSummary,
}
