import { gql } from '@apollo/client'

export const COUNT_ISSUES = gql`
  query countIssues($filter: Filters) {
    countIssues(filter: $filter)
  }
`

export const GET_ISSUES = gql`
  query GetIssues($filter: Filters, $sort: Sorts, $page: Pagination) {
    issues(filter: $filter, sort: $sort, page: $page) {
      id
      issueStatus
      createdOn
      dateResolved
      issueType
      student {
        id
        firstName
        lastName
      }
      classroom {
        id
        code
        instructors {
          employee {
            id
          }
        }
      }
      lapCode
      responsibleEmployee {
        id
        firstName
        lastName
      }
      description
    }
  }
`

export const GET_ISSUE = gql`
  query GetIssue($id: ID!) {
    issueDetail(issueId: $id) {
      courseId
      lessonPlanId
      problemSetId
      issue {
        id
        issueStatus
        issueType
        createdOn
        dateAssigned
        dateEscalated
        dateResolved
        deadline
        description
        resolution
        response
        lapCode
        student {
          id
          firstName
          lastName
        }
        responsibleEmployee {
          id
        }
        classroom {
          id
          code
          title
        }
      }
      problem {
        id
        order
        answer
        problemType
        choices {
          id
          order
          correct
          text
        }
        hints {
          id
          text
          image
          order
          video
        }
        questionText
        questionImageUrl
        solutionText
        solutionImageUrl
        problemSet {
          id
          order
          title
          allowedAttempts
        }
      }
      problemResponse {
        id
        submission {
          id
          reviewed
        }
        value
        count
      }
    }
  }
`

export const RESOLVE_ISSUES = gql`
  mutation resolveIssues($employeeId: String!) {
    resolveIssues(employeeId: $employeeId) {
      success
      message
    }
  }
`

export const GET_STUDENT_TOKEN = gql`
  mutation studentToken($studentId: String!) {
    getStudentToken(studentId: $studentId) {
      success
      message
      token
    }
  }
`

export const UPDATE_ISSUE = gql`
  mutation updateIssues($id: ID!, $input: UpdateIssueInput!) {
    updateIssue(id: $id, input: $input) {
      id
    }
  }
`

export const UPDATE_PROBLEM = gql`
  mutation updateProblem($id: ID!, $input: UpdateProblemInput!) {
    updateProblem(id: $id, input: $input) {
      id
    }
  }
`

export const UPDATE_CHOICE = gql`
  mutation updateChoice($id: ID!, $input: UpdateChoiceInput!) {
    updateChoice(id: $id, input: $input) {
      id
    }
  }
`

export const UPDATE_PROBLEM_RESPONSE = gql`
  mutation updateProblemResponse(
    $id: ID!
    $input: UpdateProblemResponseInput!
  ) {
    updateProblemResponse(id: $id, input: $input) {
      id
    }
  }
`

export const CREATE_HINT = gql`
  mutation createHints($input: CreateHintInput!) {
    createHint(input: $input) {
      id
      text
      image
      order
    }
  }
`

export const UPDATE_HINT = gql`
  mutation updateHint($id: ID!, $input: UpdateHintInput!) {
    updateHint(id: $id, input: $input) {
      id
      text
      image
      order
    }
  }
`

export const UPDATE_SUBMISSION = gql`
  mutation updateSubmission($id: ID!, $input: UpdateSubmissionInput!) {
    updateSubmission(id: $id, input: $input) {
      id
    }
  }
`

export const GET_TECH_EMPLOYEES = gql`
  query GetTechEmployees {
    employees(filter: { isTechSupport: true, status: "ACTIVE" }) {
      id
      firstName
      lastName
    }
  }
`

export const GIVE_FREE_POINT = gql`
  mutation GiveFreePoint($id: ID!) {
    giveFreePoint(problemId: $id)
  }
`

export const ARCHIVE_ISSUE = gql`
  mutation archiveIssue($id: ID!) {
    archiveIssue(id: $id) {
      id
    }
  }
`

export const REPAIR_SUBMISSION = gql`
  mutation repairSubmission($submissionId: String!) {
    repairStudentSubmission(submissionId: $submissionId) {
      success
      message
    }
  }
`
