import React, { useState, useEffect } from 'react'
import { useLazyQuery } from '@apollo/client'
import Cookies from 'js-cookie'
import { idName } from '../../config'

import { Paper } from '@mui/material'
import IssueSidebar from './IssueSidebar'
import IssueTable from './IssueTable'
import { convertToMDY, formatTime } from '../../utils/datetime'
import { arrayToObjectFilter } from './utils'
import { GET_ISSUES, COUNT_ISSUES, GET_TECH_EMPLOYEES } from './queries'

const IssueView = props => {
  const employeeId = Cookies.get(idName)

  const [myClassrooms, setMyClassrooms] = useState(true)

  const [filtered, setFiltered] = useState([
    { id: 'issueStatus', value: 'INITIATED' },
  ])
  const [filter, setFilter] = useState(arrayToObjectFilter(filtered))

  const [employees, setEmployees] = useState([])
  const [issues, setIssues] = useState([])
  const [page, setPage] = useState(0)
  const [pages, setPages] = useState(1)
  const [pageSize] = useState(25)
  const [issueCount, setIssueCount] = useState(0)

  const [getEmployees] = useLazyQuery(GET_TECH_EMPLOYEES, {
    onCompleted: res => {
      setEmployees(res.employees)
    },
  })

  const [getPages] = useLazyQuery(COUNT_ISSUES, {
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        ...filter,
        $not: {
          issueType: 'ATTENDANCE',
        },
      },
    },
    onCompleted: res => {
      const pages = Math.ceil(res.countIssues / pageSize)
      setPages(pages)
      setIssueCount(res.countIssues)
    },
  })

  const [getIssues] = useLazyQuery(GET_ISSUES, {
    fetchPolicy: 'network-only',
    variables: {
      filter: {
        ...filter,
        $not: {
          issueType: 'ATTENDANCE',
        },
      },
      sort: { createdOn: -1 },
      page: { skip: page * pageSize, limit: pageSize },
    },
    onCompleted: res => {
      const issues = res.issues.map(issue => formatIssue(issue))
      setIssues(issues)
    },
  })

  useEffect(() => {
    getEmployees()
    getPages()
    getIssues()
  }, [getEmployees, getPages, getIssues])

  const handleFetchData = state => {
    getPages()
    getIssues()
  }

  const handleFilteredChange = filtered => {
    let filter = arrayToObjectFilter(filtered)
    setFilter(filter)
    setFiltered(filtered)
    getIssues()
  }

  const handlePageChange = pageIndex => {
    setPage(pageIndex)
    getIssues()
  }

  return (
    <Paper sx={{ display: 'flex' }}>
      <IssueSidebar
        // isAdmin={isAdmin}
        issues={issues}
        issueCount={issueCount}
        myClassrooms={!myClassrooms}
        handleMyClassrooms={setMyClassrooms}
      />
      <Paper sx={{ width: '100%' }}>
        <IssueTable
          showMyStudent={myClassrooms}
          employees={employees}
          employeeId={employeeId}
          issues={issues}
          updateTable={handleFetchData}
          filtered={filtered}
          page={page}
          pages={pages}
          pageSize={pageSize}
          handleFilteredChange={handleFilteredChange}
          handlePageChange={handlePageChange}
        />
      </Paper>
    </Paper>
  )
}

const formatIssue = ({
  id,
  issueStatus,
  dateResolved,
  createdOn,
  issueType,
  student,
  classroom,
  lapCode,
  responsibleEmployee,
  description,
}) => ({
  id: id,
  issueStatus: issueStatus,
  createdOn: createdOn,
  reportDate: createdOn && convertToMDY(createdOn),
  reportTime: createdOn && formatTime(createdOn),
  issueType: issueType,
  firstName: student ? student.firstName : '',
  lastName: student ? student.lastName : '',
  classroomCode: classroom ? classroom.code : null,
  lapCode: lapCode,
  employee: responsibleEmployee
    ? `${responsibleEmployee.firstName} ${responsibleEmployee.lastName}`
    : '',
  description: description,
})

export default IssueView
