import React, { useState } from 'react'
import {
  Flex,
  EditIcon,
  CheckIcon,
  ClearIcon,
  IconButton,
  InputBase,
  LaTeX,
  Divider,
  Avatar,
  makeStyles,
} from '../../components'

const useStyles = makeStyles(theme => ({
  outline: {
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'center',
    height: '60px',
    //width: 'calc(100px + 30vw)',
    width: '98%',
    border: '1px solid rgb(187, 187, 187)',
    borderRadius: '15px',
  },
  avatar: {
    marginLeft: 10,
    marginRight: 25,
  },
  input: {
    fontSize: 15,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 0,
  },
  icon: {
    width: 20,
    height: 20,
  },
  iconTwo: {
    width: 24,
    height: 24,
  },
}))

export const Choice = props => {
  const [choiceText, setChoiceText] = useState(props.choiceText)
  const [editable, setEditable] = useState(false)
  const originalText = props.choiceText
  const classes = useStyles()

  return (
    <Flex className={classes.outline}>
      <Avatar
        className={classes.avatar}
        style={{ backgroundColor: props.correct ? '#06bd40' : '#f50057' }}
      >
        {props.encoded}
      </Avatar>
      {editable ? (
        <InputBase
          className={classes.input}
          value={choiceText}
          onChange={event => setChoiceText(event.target.value)}
          disabled={!editable}
        />
      ) : (
        <Flex className={classes.input}>
          {choiceText !== null && <LaTeX latex={choiceText} />}
        </Flex>
      )}
      <IconButton
        color={editable ? 'primary' : 'secondary'}
        onClick={() => setEditable(!editable)}
      >
        <EditIcon className={classes.icon} />
      </IconButton>
      <Divider className={classes.divider} orientation="vertical" />
      <IconButton
        color={'primary'}
        onClick={() => {
          props.handleUpdate(props.id, choiceText)
          setEditable(false)
        }}
        disabled={!editable}
      >
        <CheckIcon className={classes.iconTwo} />
      </IconButton>
      <Divider className={classes.divider} orientation="vertical" />
      <IconButton
        color={'secondary'}
        onClick={() => setChoiceText(originalText)}
        disabled={!editable}
      >
        <ClearIcon className={classes.iconTwo} />
      </IconButton>
    </Flex>
  )
}

export default Choice
