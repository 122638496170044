import React, { Fragment, useState, useEffect } from 'react'
import {
  Flex,
  LoadingMessage,
  ErrorMessage,
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '../../../components'
import { compareStudents } from '../utils'
import Tooltip from '@mui/material/Tooltip'
import {
  convertToMDY,
  isThisWeek,
  isBetweenDate,
} from '../../../utils/datetime'
import { useQuery } from '@apollo/client'
import { GET_LESSONS } from '../queries'

const useStyles = makeStyles(theme => ({
  defaultCellStyle: {
    height: '3em',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'lightgrey',
    padding: '5px 5px 5px 5px',
    align: 'center',
  },
  defaultHeaderCellStyle: {
    height: '3em',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'lightgrey',
    padding: '5px 5px 5px 5px',
    align: 'center',
    alignItems: 'center',
  },
  //lazy ctrl v
  ACell: {
    backgroundColor: '#b9f6ca',
    height: '3em',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'lightgrey',
    padding: '5px 5px 5px 5px',
    align: 'center',
    alignItems: 'center',
  },
  BCell: {
    backgroundColor: '#fff9c4',
    height: '3em',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'lightgrey',
    padding: '5px 5px 5px 5px',
    align: 'center',
    alignItems: 'center',
  },
  CCell: {
    backgroundColor: '#ffecb3',
    height: '3em',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'lightgrey',
    padding: '5px 5px 5px 5px',
    align: 'center',
    alignItems: 'center',
  },
  FCell: {
    backgroundColor: '#ffccbc',
    height: '3em',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'lightgrey',
    padding: '5px 5px 5px 5px',
    align: 'center',
    alignItems: 'center',
  },
  CurrentWeekCell: {
    backgroundColor: '#bbdefb',
    height: '3em',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'lightgrey',
    padding: '5px 5px 5px 5px',
    align: 'center',
    alignItems: 'center',
  },
  PreviousWeekCell: {
    backgroundColor: '#b3e5fc',
    height: '3em',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'lightgrey',
    padding: '5px 5px 5px 5px',
    align: 'center',
    alignItems: 'center',
  },
}))

export const LessonTableView = props => {
  const [colorCoded, setColorCoded] = useState(true)
  const { fireAction, handleSiblingAction } = props
  /**
   * don't add any dependencies except for fireAction
   */
  useEffect(() => {
    if (fireAction) {
      switch (fireAction) {
        case 'colorize':
          handleSiblingAction(() => {
            setColorCoded(!colorCoded)
          })
          break
        default:
          break
      }
    }
  }, [fireAction])

  const processLesson = classroomData => {
    const lessons = [...classroomData.lessons]
    const lessonArray = []
    const studentMap = new Map()

    classroomData.enrollments.forEach(enrollment => {
      studentMap.set(enrollment.student.id, {
        id: enrollment.student.id,
        firstName: enrollment.student.firstName,
        lastName: enrollment.student.lastName,
        lessons: new Map(),
      })
    })
    lessons
      .sort((a, b) => a.lessonPlan.order - b.lessonPlan.order)
      .forEach((lesson, index) => {
        studentMap.forEach((v, k) => {
          studentMap.get(k).lessons.set(lesson.id, {
            completion: 0,
          })
        })
        let lessonObj = {
          id: lesson.id,
          lessonPlanId: lesson.lessonPlan.id,
          startOn: lesson.startOn,
          endOn: lesson.dueOn,
          title: lesson.lessonPlan.title,
          order: lesson.lessonPlan.order,
          avgGrade: lesson.lessonPlan.averageGrade,
        }
        lesson.attendees.forEach((attendee, index) => {
          let student = studentMap.get(attendee.student.id)
          if (student) {
            const overallGrade =
              attendee.overallGrade === null
                ? 'N/A'
                : `${attendee.overallGrade}%`
            const percentCompleted =
              attendee.percentCompleted === null
                ? 'N/A'
                : `${attendee.percentCompleted}%`
            student.lessons.get(lesson.id).completedLesson =
              overallGrade + ' / ' + percentCompleted
            student.lessons.get(lesson.id).overallGrade = attendee.overallGrade
          }
        })
        lessonArray.push(lessonObj)
      })
    return { lessons: lessonArray, studentMap: studentMap }
  }

  const { data, loading, error } = useQuery(GET_LESSONS, {
    variables: { id: props.classId },
  })
  if (loading) return <LoadingMessage />
  if (error) return <ErrorMessage error={error} />
  const lessonData = processLesson(data.classroom)

  return (
    <Flex align="left" column grow={2}>
      <LessonTable lessonData={lessonData} colorCoded={colorCoded} />
    </Flex>
  )
}

const LessonTable = ({ lessonData, colorCoded }) => {
  const classes = useStyles()
  const today = new Date()

  const gradeCellClassName = value => {
    if (colorCoded) {
      if (value === 0 || value === null) return classes.defaultHeaderCellStyle
      else if (value >= 90) return classes.ACell
      else if (value < 90 && value >= 80) return classes.BCell
      else if (value < 80 && value >= 70) return classes.CCell
      else return classes.FCell
    } else {
      return classes.defaultHeaderCellStyle
    }
  }

  const weekCellClassName = (today, start, end) => {
    if (isThisWeek(start)) return classes.CurrentWeekCell
    else if (isBetweenDate(today, start, end)) return classes.PreviousWeekCell
    else return classes.defaultHeaderCellStyle
  }

  const routeToLesson = e => {
    window.alert('TODO: this will route to assignment')
  }

  return (
    <Table>
      <TableHead>
        <TableRow onClick={routeToLesson}>
          {lessonData.lessons.map((lesson, index) => (
            <Tooltip key={index} title={lesson.title}>
              <TableCell
                key={`${lesson.id}-${lesson.lessonPlanId}`}
                variant={'head'}
                align={'center'}
                style={{ width: '5em' }}
                colSpan={2}
                className={weekCellClassName(
                  today,
                  lesson.startOn,
                  lesson.endOn
                )}
              >
                Lesson {lesson.order}
              </TableCell>
            </Tooltip>
          ))}
        </TableRow>
        <TableRow>
          {lessonData.lessons.map((lesson, index) => (
            <Fragment key={index}>
              <TableCell
                key={`${lesson.id}-${lesson.order}`}
                variant={'head'}
                align={'center'}
                colSpan={2}
                style={{ width: '3em' }}
                className={weekCellClassName(
                  today,
                  lesson.startOn,
                  lesson.endOn
                )}
              >
                {convertToMDY(lesson.startOn)}
              </TableCell>
            </Fragment>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {[...lessonData.studentMap.values()]
          .sort((a, b) =>
            compareStudents(a.lastName, b.lastName, a.firstName, b.firstName)
          )
          .map((student, index) => (
            <TableRow hover key={`${student.id}-completion-row`}>
              {lessonData.lessons.map((lesson, index) => {
                let overallGrade = student.lessons.get(lesson.id).overallGrade
                const completedLesson = student.lessons.get(lesson.id)
                  .completedLesson
                return (
                  <Fragment key={index}>
                    <TableCell
                      align={'center'}
                      colSpan={2}
                      className={gradeCellClassName(overallGrade)}
                      style={{ width: '2.5em' }}
                      key={`${student.id}-${lesson.id}-completed-percent-cell`}
                    >
                      {completedLesson}
                    </TableCell>
                  </Fragment>
                )
              })}
            </TableRow>
          ))}
      </TableBody>
    </Table>
  )
}

export default LessonTableView
