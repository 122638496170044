import { gql } from '@apollo/client'

export const GET_CONTACT_METHOD_OPTIONS_QUERY = gql`
  query getContactMethodOptions {
    __type(name: "ContactMethod") {
      enumValues {
        name
      }
    }
  }
`

export const GET_EMPLOYEE = gql`
  query getEmployee($id: ID!) {
    employee(id: $id) {
      id
      firstName
      lastName
      workEmail
      isInstructorUser
      isAdminOfSOLVE
      isTechSupport
      isTeacher
      isTutor
    }
  }
`

export const SEND_SMS = gql`
  mutation sendSMS($phoneNumber: String!, $message: String!) {
    sendSMS(phoneNumber: $phoneNumber, message: $message) {
      success
      message
    }
  }
`

export const SEND_EMAIL_TO_FAMILY_MEMBER = gql`
  mutation sendEmailToFamilyMember(
    $familyMemberId: ID!
    $subject: String!
    $textMessage: String!
    $htmlMessage: String
    $scheduledDate: DateTime
    $testRecipient: String
    $ccAddresses: [String]
    $bccAddresses: [String]
  ) {
    sendEmailToFamilyMember(
      familyMemberId: $familyMemberId
      subject: $subject
      textMessage: $textMessage
      htmlMessage: $htmlMessage
      scheduledDate: $scheduledDate
      testRecipient: $testRecipient
      ccAddresses: $ccAddresses
      bccAddresses: $bccAddresses
    ) {
      message
      familyMember {
        id
        email
      }
    }
  }
`
