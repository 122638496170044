import { gql } from '@apollo/client'

export const GET_TUTOR_SESSIONS = gql`
  query getTutorSessions($filter: Filters!) {
    tutorSessions(filter: $filter) {
      id
      status
      student {
        firstName
        lastName
      }
      classroom {
        code
      }
      order
      startDateTime
      duration
      tutor {
        id
        firstName
        lastName
      }
    }
  }
`

export const GET_TUTOR_SESSION = gql`
  query getTutorSession($id: ID!) {
    tutorSession(id: $id) {
      student {
        firstName
        lastName
        phoneNumber
      }
      startDateTime
      duration
      classroom {
        code
      }
      sessionStatus
    }
  }
`

export const UPDATE_TUTOR_SESSION_STATUS = gql`
  mutation changeTutorSessionStatus(
    $tutorSessionId: ID!
    $sessionStatus: sessionStatus!
  ) {
    changeTutorSessionStatus(
      tutorSessionId: $tutorSessionId
      sessionStatus: $sessionStatus
    ) {
      tutorSession {
        sessionStatus
      }
      success
    }
  }
`
