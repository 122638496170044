import { gql } from '@apollo/client'

export const GET_CLASSROOMS = gql`
  query getClasses($filter: Filters!) {
    classrooms(filter: $filter) {
      id
      status
      startDate
      endDate
      quarter
      year
      code
      title
      enrollmentCount
      meets
      startTime
      endTime
      centerLocation {
        id
        name
      }
      instructors {
        id
        isAssistant
        displayName
        employee {
          id
          firstName
          lastName
        }
      }
    }
  }
`

export const GET_STUDENTS_IN_CLASS = gql`
  query getStudents($id: ID!) {
    classroom(id: $id) {
      id
      code
      title
      meets
      endTime
      enrollments {
        id
        status
        createdOn
        student {
          id
          gradeUpdated
          firstName
          lastName
          imageUrl
          username
          gender
          active
          birthdate
          lastLogin
          familyAccount {
            id
            primaryFamilyMember {
              id
              firstName
              lastName
              phoneNumber
              email
            }
          }
        }
        overallGrade
      }
    }
  }
`

export const RECAL_GRADES_BY_CLASSROOM = gql`
  mutation RecalcGrades($classroomId: ID!, $studentId: ID) {
    recalcGrades(classroomId: $classroomId, studentId: $studentId) {
      success
    }
  }
`

export const UPDATE_SUBMISSION = gql`
  mutation updateSubmission($id: ID!, $input: UpdateSubmissionInput!) {
    updateSubmission(id: $id, input: $input) {
      id
    }
  }
`

export const CREATE_SUBMISSION = gql`
  mutation createSubmission($input: CreateSubmissionInput!) {
    createSubmission(input: $input) {
      id
    }
  }
`

export const GET_LESSONS = gql`
  query getLessons($id: ID!) {
    classroom(id: $id) {
      id
      enrollments {
        id
        student {
          id
          firstName
          lastName
        }
      }
      lessons(filter: { lessonPlan: { lessonPlanType: "INSTRUCTION" } }) {
        id
        startOn
        dueOn
        attendees {
          id
          overallGrade
          percentCompleted
          status
          note
          timeAdjustment
          isMakeup
          student {
            id
            firstName
            lastName
            imageUrl
            image
          }
        }
        lessonPlan {
          id
          title
          order
        }
      }
    }
  }
`

export const GET_ASSIGNMENTS = gql`
  query getAssignments($classroomId: String!, $required: Boolean!) {
    gradesForClassroomAssignment(
      classroomId: $classroomId
      required: $required
    ) {
      submissionRecords {
        studentId
        firstName
        lastName
        results {
          submitted
          grade
        }
      }
      assignmentLabels {
        kind
        index
        title
      }
    }
  }
`

export const GET_STUDENTS_FROM_ASSIGNMENT = gql`
  query getStudentsFromAssignment($id: ID!) {
    gradesForAssignment(
      id: $id
      fillEmpty: true
      fillUngraded: true
      fillIncomplete: true
    ) {
      student {
        id
        firstName
        lastName
      }
      submission {
        id
        createdOn
        submittedAt
        sum
        attemptNumber
        excused
        count
        overallGrade
        coins
        totalTimeSpent
        graded
        reviewed
      }
    }
  }
`

export const GET_GRADES_FROM_ASSIGNMENT = gql`
  query getGrades($id: ID!, $studentId: ID, $attendeeFilter: Filters) {
    assignment(id: $id) {
      id
      lesson {
        id
        classroom {
          id
        }
      }
      problemSet {
        id
        autoGrading
        title
        order
        problems(sort: { order: 1 }) {
          id
          answer
          correctAnswer
          order
          points
          problemType
        }
      }
    }
    gradesForAssignment(
      id: $id
      studentId: $studentId
      fillEmpty: true
      fillUngraded: true
      fillIncomplete: true
    ) {
      student {
        id
        firstName
        lastName
        username
        imageUrl
        active
        attendees(filter: $attendeeFilter) {
          id
          status
          isMakeup
          lesson {
            id
          }
        }
      }
      submission {
        id
        createdOn
        submittedAt
        submittedLate
        graded
        overallGrade
        coins
        count
        sum
        attemptNumber
        excused
        responses {
          id
          sum
          count
          value
          percentCorrect
          scratchpadUrl
          attachmentUrl
          value
          comment
          problem {
            id
            order
            points
          }
        }
      }
    }
  }
`

export const GET_SIMPLE_LESSONS = gql`
  query getLessons($id: ID!) {
    classroom(id: $id) {
      id
      lessons {
        id
        startOn
        lessonPlan {
          id
          title
          order
        }
      }
    }
  }
`

export const GET_SIMPLE_ASSIGNMENTS = gql`
  query getAssignments($id: ID!) {
    lesson(id: $id) {
      assignments {
        id
        problemSet {
          id
          title
          order
          kind
        }
      }
    }
  }
`

export const SUBMIT_GRADES = gql`
  mutation($aid: ID!, $pids: [ID], $input: [GradeAssignmentInput!]) {
    gradeAssignment(assignmentId: $aid, problemIds: $pids, inputs: $input) {
      success
      message
    }
  }
`

export const UPDATE_ATTENDEE_STATUS = gql`
  mutation($id: ID!, $input: UpdateAttendeeInput!) {
    updateAttendee(id: $id, input: $input) {
      id
      status
    }
  }
`

export const GET_STATUS_OPTIONS = gql`
  query status {
    __type(name: "attendeeStatus") {
      enumValues {
        name
      }
    }
  }
`

export const REPAIR_ATTENDEE = gql`
  mutation repairAttendanceRecord($id: String!) {
    createAttendeeRecords(classroomId: $id) {
      success
      errorMessage
    }
  }
`

export const GET_ATTENDEE = gql`
  query attendee($id: ID!) {
    attendee(id: $id) {
      id
      status
      note
      timeAdjustment
      student {
        id
        imageUrl
        image
        firstName
        lastName
        grade
        school
      }
    }
  }
`

export const REPAIR_SUBMISSION = gql`
  mutation repairSubmission($submissionId: String!) {
    repairStudentSubmission(submissionId: $submissionId) {
      success
      message
    }
  }
`

export const LIVE_ASSIGNMENT = gql`
  subscription subscribeAssignment($aid: ID!) {
    liveAssignment(assignmentId: $aid) {
      reason
      submission {
        id
        submittedAt
        submittedLate
        graded
        student {
          id
        }
      }
      problemResponse {
        id
        sum
        count
        value
        percentCorrect
        problem {
          id
          order
          points
        }
      }
    }
  }
`

export const SET_STUDENT_PASSWORD = gql`
  mutation updateStudent($id: ID!, $input: UpdateStudentInput!) {
    updateStudent(id: $id, input: $input) {
      id
    }
  }
`

export const UPDATE_PROBLEM_RESPONSE = gql`
  mutation updateProblemResponse(
    $id: ID!
    $input: UpdateProblemResponseInput!
  ) {
    updateProblemResponse(id: $id, input: $input) {
      id
      comment
    }
  }
`

export const RESET_PASSWORD = gql`
  mutation resetPassword($username: String, $email: String) {
    resetPassword(username: $username, email: $email) {
      success
      message
    }
  }
`

export const UPDATE_ATTENDEE = gql`
  mutation updateAttendee($id: ID!, $input: UpdateAttendeeInput!) {
    updateAttendee(id: $id, input: $input) {
      id
      status
    }
  }
`

export const UPDATE_STUDENT = gql`
  mutation updateStudent($id: ID!, $input: UpdateStudentInput!) {
    updateStudent(id: $id, input: $input) {
      id
    }
  }
`

export const SEND_SMS = gql`
  mutation sendSMS($phoneNumber: String!, $message: String!) {
    sendSMS(phoneNumber: $phoneNumber, message: $message) {
      success
      message
    }
  }
`

export const SEND_EMAIL_TO_FAMILY_MEMBER = gql`
  mutation sendEmailToFamilyMember(
    $familyMemberId: ID!
    $subject: String!
    $textMessage: String!
    $htmlMessage: String
    $scheduledDate: DateTime
    $testRecipient: String
    $ccAddresses: [String]
    $bccAddresses: [String]
  ) {
    sendEmailToFamilyMember(
      familyMemberId: $familyMemberId
      subject: $subject
      textMessage: $textMessage
      htmlMessage: $htmlMessage
      scheduledDate: $scheduledDate
      testRecipient: $testRecipient
      ccAddresses: $ccAddresses
      bccAddresses: $bccAddresses
    ) {
      message
      familyMember {
        id
        email
      }
    }
  }
`
