import React, { Fragment, useState } from 'react'

import { useMutation } from '@apollo/client'
import { UPDATE_MAKEUP } from './queries'

import { Button, Flex, StatusSelector, TextField } from '../../components'
import withStyles from '../../components/Styles'
import { expansionBarColor } from '../../config'

const makeupStatuses = ['SCHEDULED', 'ATTENDED', 'ABSENT']

const MakeupForm = ({ classes, makeup }) => {
  const makeupId = makeup.id
  const [status, setStatus] = useState(makeup.status)
  const [notes, setNotes] = useState(makeup.notes)
  const [makeupInfoChanged, setMakeupInfoChanged] = useState(false)

  const [handleSaveMakeupInfo] = useMutation(UPDATE_MAKEUP, {
    variables: {
      id: makeupId,
      input: {
        status: status,
        notes: notes,
      },
    },
    onCompleted: () => {
      setMakeupInfoChanged(false)
    },
  })

  const handleChange = event => {
    const { name, value } = event.target
    if (name === 'status') setStatus(value)
    if (name === 'notes') setNotes(value)
    setMakeupInfoChanged(true)
  }

  const {
    fromClassTitle,
    fromLessonTitle,
    toLessonTitle,
    toClassTitle,
  } = makeup

  return (
    <Flex column style={{ align: 'center', flexGrow: 1 }}>
      <Flex justify="space-evenly" className={classes.root}>
        <Flex grow={2} m="20px" style={{ maxWidth: '400px' }} column>
          <ToLesson classTitle={toClassTitle} lessonTitle={toLessonTitle} />
          <br />
          <FromLesson
            classTitle={fromClassTitle}
            lessonTitle={fromLessonTitle}
          />
        </Flex>

        <Flex grow={2} m={'20px'} style={{ maxWidth: '400px' }} column>
          <StatusSelector
            label="Status"
            status={status}
            statusOptions={makeupStatuses}
            styles={classes}
            handleChange={handleChange}
            selectedOptionId={status}
          />
          <br />
          <TextField
            name="notes"
            label="Notes"
            multiline
            rowsmax={4}
            value={notes || ''}
            onChange={handleChange}
          />
        </Flex>
      </Flex>
      <Flex direction={'row-reverse'} align={'center'}>
        <Button onClick={handleSaveMakeupInfo} disabled={!makeupInfoChanged}>
          Save Changes
        </Button>
      </Flex>
    </Flex>
  )
}

const ToLesson = ({ classTitle, lessonTitle }) => {
  return (
    <>
      <TextField
        name="toClassroom"
        label="Makeup Class"
        disabled={true}
        value={classTitle}
      />
      <br />
      <TextField
        name="toLesson"
        label="Makeup Lesson"
        disabled={true}
        value={lessonTitle || ''}
      />
    </>
  )
}

const FromLesson = ({ classTitle, lessonTitle }) => {
  return (
    <>
      <TextField
        name="fromClassroom"
        label="From Class"
        disabled={true}
        value={classTitle}
      />
      <br />
      <TextField
        name="fromLesson"
        label="From Lesson"
        disabled={true}
        value={lessonTitle || ''}
      />
    </>
  )
}

const styles = theme => ({
  menu: {
    width: '20%',
  },
  expSummary: {
    backgroundColor: expansionBarColor,
  },
  asterisk: {
    color: 'red',
  },
})

export default withStyles(styles)(MakeupForm)
