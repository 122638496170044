import React from 'react'
import ReactTable from 'react-table-6'

import { dateFilterMethod } from '../../utils/datetime'
import { Box } from '@mui/material'

const TutorSessionTable = ({
  tutorSessions,
  filtered,
  handleFilteredChange,
  handleRowClick,
}) => {
  const defaultSorted = [{ id: 'code' }]

  return (
    <Box align="left">
      <ReactTable
        data={tutorSessions}
        defaultFiltered={filtered}
        onFilteredChange={handleFilteredChange}
        columns={columns}
        filterable={true}
        defaultSorted={defaultSorted}
        className="-highlight"
        style={{ fontSize: '10pt' }}
        defaultPageSize={25}
        defaultFilterMethod={(filter, row) =>
          row[filter.id] &&
          row[filter.id]
            .toString()
            .toLowerCase()
            .includes(filter.value.toString().toLowerCase())
        }
        getTdProps={(state, rowInfo, column, instance) => {
          return {
            onClick: (e, handleOriginal) => {
              if (column.Header !== undefined) {
                handleRowClick(rowInfo)
              }
              if (handleOriginal) {
                handleOriginal()
              }
            },
          }
        }}
        getTheadProps={(state, rowInfo, column, instance) => {
          return {
            style: {
              fontWeight: 'bold',
              borderBottom: '1px solid #E5E5E5',
            },
          }
        }}
      />
    </Box>
  )
}

const columns = [
  {
    Header: 'Status',
    accessor: 'status',
    width: 100,
    Cell: props => {
      return <span>{props.value}</span>
    },
    filterMethod: (filter, row) => {
      if (filter.value !== 'ALL') {
        return row[filter.id] === filter.value
      }
      return true
    },
    Filter: ({ filter, onChange }) => (
      <select
        onChange={event => onChange(event.target.value)}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'ALL'}
      >
        <option value="ALL">ALL</option>
        <option value="ONTIME">ONTIME</option>
        <option value="ABSENT">ABSENT</option>
        <option value="LATE">LATE</option>
        <option value="EARLY">EARLY</option>
        <option value="UNKNOWN">UNKNOWN</option>
        <option value="SCHEDULED">SCHEDULED</option>
        <option value="NOSHOW">NO SHOW</option>
      </select>
    ),
  },
  {
    Header: 'First Name',
    accessor: 'firstName',
    width: 100,
  },
  {
    Header: 'Last Name',
    accessor: 'lastName',
    width: 100,
  },
  {
    Header: 'Class Code',
    accessor: 'code',
    width: 100,
  },
  {
    Header: 'Date',
    accessor: 'startDate',
    width: 100,
    filterMethod: dateFilterMethod,
  },
  {
    Header: 'Time',
    accessor: 'duration',
    width: 50,
    style: { textAlign: 'center' },
  },
  {
    Header: 'Tutor',
    accessor: 'tutor',
    width: 150,
  },
]

export default TutorSessionTable
