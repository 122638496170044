import { gql } from '@apollo/client'

export const GET_SUBMISSIONS = gql`
  query getSubmissions($filter: Filters) {
    submissions(filter: $filter) {
      id
      startedAt
      submittedAt
      archivedOn
      student {
        id
        firstName
        lastName
      }
      classroom {
        title
        code
        instructors {
          id
          isAssistant
          employee {
            id
            firstName
            lastName
          }
        }
      }
      lesson {
        lessonPlan {
          order
        }
      }
      count
      sum
      overallGrade
      submittedLate
      graded
      reviewed
      attemptNumber
      coins
      totalTimeSpent
    }
  }
`

export const GET_SUBMISSION = gql`
  query getSubmission($id: ID!) {
    submission(id: $id) {
      id
      startedAt
      submittedAt
      archivedOn
      student {
        id
        firstName
        lastName
      }
      assignment {
        id
        problemSet {
          id
          problems {
            id
            points
            problemType
            order
            answer
            questionText
            questionImage
            questionImageUrl
            questionVideo
          }
        }
      }
      responses {
        id
        count
        sum
        scratchpadUrl
        attachmentUrl
        percentCorrect
        value
        problem {
          id
          order
        }
      }
      count
      sum
      overallGrade
      submittedLate
      graded
      reviewed
      attemptNumber
      coins
      totalTimeSpent
    }
  }
`

export const SUBMIT_GRADES = gql`
  mutation($aid: ID!, $pids: [ID], $input: [GradeAssignmentInput!]) {
    gradeAssignment(assignmentId: $aid, problemIds: $pids, inputs: $input) {
      success
      message
    }
  }
`
