import React, { useState } from 'react'

import {
  Box,
  Button,
  FormControl,
  FormLabel,
  FormControlLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material'

import Select from 'react-select'

import { useMutation } from '@apollo/client'
import { UPDATE_TRIAL } from './queries'

const EvaluationForm = ({ classes, trialEvaluation, classroomCodes }) => {
  const { toClassCode } = trialEvaluation
  const alternateCourses = getAlternateCourses(toClassCode, classroomCodes)

  const [evaluationChanged, setEvaluationChanged] = useState(false)
  const [trial, setTrial] = useState(trialEvaluation)

  const handleChange = name => event => {
    const value =
      name === 'alternateCourseTitle' ? event.value : event.target.value
    const newTrial = {
      ...trial,
      [name]: value,
    }
    setEvaluationChanged(true)
    setTrial(newTrial)
  }

  const [handleSaveTrialEvaluation] = useMutation(UPDATE_TRIAL, {
    variables: {
      id: trial.id,
      input: {
        evaluationMentalMath: parseInt(trial.evaluationMentalMath, 10),
        evaluationSkillBuilder: parseInt(trial.evaluationSkillBuilder, 10),
        evaluationProblemSolving: parseInt(trial.evaluationProblemSolving, 10),
        evaluationMotivation: parseInt(trial.evaluationMotivation, 10),
        evaluationDiscipline: parseInt(trial.evaluationDiscipline, 10),
        quizScore: parseInt(trial.quizScore, 10),
        quizTotal: parseInt(trial.quizTotal, 10),
        quizGrade: parseFloat(trial.quizGrade),
        instructorComment: trial.instructorComment,
        recommendation:
          trial.recommendation === 'Yes'
            ? true
            : trial.recommendation === 'No'
            ? false
            : null,
        alternateCourseTitle: trial.alternateCourseTitle
          ? trial.alternateCourseTitle
          : '',
      },
    },
    onCompleted: () => {
      setEvaluationChanged(false)
    },
  })

  const readOnly = false
  const {
    alternateCourseTitle,
    instructorComment,
    quizScore,
    quizTotal,
    recommendation,
  } = trial

  const courseOptions = alternateCourses.map(course => ({
    value: course,
    label: course,
  }))

  const evaluationOptions = [
    {
      title: 'Mental Math',
      name: 'evaluationMentalMath',
      value: trial.evaluationMentalMath,
    },
    {
      title: 'Skill Builder',
      name: 'evaluationSkillBuilder',
      value: trial.evaluationSkillBuilder,
    },
    {
      title: 'Problem Solving',
      name: 'evaluationProblemSolving',
      value: trial.evaluationProblemSolving,
    },
    {
      title: 'Motivation',
      name: 'evaluationMotivation',
      value: trial.evaluationMotivation,
    },
    {
      title: 'Discipline',
      name: 'evaluationDiscipline',
      value: trial.evaluationDiscipline,
    },
  ]
  return (
    <Box
      column
      id="evaluationForm"
      sx={{
        alignItems: 'center',
        flexGrow: 1,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-evenly',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 2,
            m: '20px',
            maxWidth: '400px',
          }}
        >
          {evaluationOptions.map(evaluation => (
            <Criterion
              key={evaluation.name}
              name={evaluation.name}
              value={evaluation.value}
              title={evaluation.title}
              readOnly={readOnly}
              handleChange={handleChange}
            />
          ))}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 2,
            m: '20px',
            maxWidth: '400px',
          }}
        >
          <QuizGrade
            quizScore={quizScore}
            quizTotal={quizTotal}
            readOnly={readOnly}
            handleChange={handleChange}
          />
          <InstructorComments
            comment={instructorComment}
            readOnly={readOnly}
            handleChange={handleChange}
          />
          <Recommendation
            readOnly={readOnly}
            courseOptions={courseOptions}
            recommended={recommendation}
            alternateCourseTitle={alternateCourseTitle}
            handleChange={handleChange}
          />
        </Box>
      </Box>
      <Box display="flex" flexDirection={'row-reverse'} alignContent={'center'}>
        <Button
          variant="contained"
          onClick={handleSaveTrialEvaluation}
          disabled={!evaluationChanged}
        >
          Save Changes
        </Button>
      </Box>
    </Box>
  )
}

const QuizGrade = ({ quizScore, quizTotal, readOnly, handleChange }) => {
  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { py: 1, width: '100%' },
      }}
      noValidate
      autoComplete="off"
    >
      <FormControl
        component="fieldset"
        style={{ paddingLeft: '10px', paddingTop: '10px' }}
      >
        <FormLabel component="legend">Quiz Grade</FormLabel>
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <TextField
            id="quizScore"
            label="Correct"
            variant="standard"
            InputProps={{
              endAdornment: (
                <InputAdornment variant="filled" position="end">
                  /
                </InputAdornment>
              ),
            }}
            value={quizScore || 0}
            onChange={handleChange('quizScore')}
            type="number"
            style={{ maxWidth: '80px', paddingRight: '10px' }}
            disabled={readOnly}
          />
          <TextField
            id="quizTotal"
            label="Total"
            variant="standard"
            value={quizTotal || 100}
            onChange={handleChange('quizTotal')}
            type="number"
            style={{ maxWidth: '80px' }}
            disabled={readOnly}
          />
          <Box display="flex" style={{ paddingLeft: '30px' }}>
            <TextField
              id="percent"
              label="Percent"
              variant="outlined"
              style={{ maxWidth: '80px' }}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment variant="filled" position="end">
                    %
                  </InputAdornment>
                ),
              }}
              value={
                quizScore && quizTotal
                  ? ((quizScore / quizTotal) * 100).toFixed(2)
                  : 0
              }
              onChange={handleChange('quizGrade')}
            />
          </Box>
        </Box>
      </FormControl>
    </Box>
  )
}

const InstructorComments = ({ comment, readOnly, handleChange }) => {
  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { py: 1, width: '45ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <FormControl component="fieldset" style={{ paddingLeft: '10px' }}>
        <TextField
          id="instructorComments"
          label="Instructor's Comment"
          variant="standard"
          InputProps={{
            readOnly: readOnly,
          }}
          multiline
          maxrows="4"
          value={comment || ''}
          onChange={handleChange('instructorComment')}
        />
      </FormControl>
    </Box>
  )
}

const Criterion = ({ name, value, title, readOnly, handleChange }) => {
  return (
    <Box>
      <FormControl key={name} component="fieldset">
        <FormLabel component="legend">{title}</FormLabel>
        <RadioGroup
          aria-label={name}
          name={name}
          value={value}
          onChange={handleChange(name)}
          style={{ display: 'flex', flexDirection: 'row' }}
          disabled={readOnly}
        >
          {[...Array(5).keys()].map(num => (
            <FormControlLabel
              key={`${name}${num}`}
              value={(num + 1).toString()}
              control={<Radio color="primary" />}
              label={(num + 1).toString()}
            />
          ))}
          <FormControlLabel
            value="-1"
            control={<Radio color="primary" />}
            label="N/A"
          />
        </RadioGroup>
      </FormControl>
    </Box>
  )
}

const Recommendation = ({
  readOnly,
  courseOptions,
  recommended,
  alternateCourseTitle,
  handleChange,
}) => {
  return (
    <Box
      sx={{
        '& > :not(style)': { py: 1, width: '45ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <FormControl component="fieldset" style={{ paddingLeft: '10px' }}>
        <FormLabel component="legend">Recommended?</FormLabel>
        <RadioGroup
          aria-label="recommendation"
          name="recommendation"
          value={recommended}
          onChange={handleChange('recommendation')}
          style={{ display: 'flex', flexDirection: 'row' }}
          disabled={readOnly}
        >
          <FormControlLabel
            value="Yes"
            control={<Radio color="primary" />}
            label="Yes"
          />
          <FormControlLabel
            value="No"
            control={<Radio color="secondary" />}
            label="No"
          />
        </RadioGroup>
      </FormControl>
      <FormControl
        component="fieldset"
        style={{
          display: recommended === 'Yes' ? 'none' : 'inline-flex',
          paddingLeft: '10px',
        }}
      >
        <FormLabel component="legend">Alternate Course</FormLabel>
        <Select
          onChange={handleChange('alternateCourseTitle')}
          options={courseOptions}
          placeholder="Search..."
          value={
            alternateCourseTitle
              ? {
                  value: alternateCourseTitle,
                  label: alternateCourseTitle,
                }
              : null
          }
        />
      </FormControl>
    </Box>
  )
}

const getAlternateCourses = (toClassCode, classrooms) => {
  const alternateCourseSet = new Set()
  for (const classroom of classrooms) {
    const subjectCode = toClassCode[0]
    if (subjectCode !== classroom.code[0]) continue
    if (['OnDemand', 'Tutoring'].includes(classroom.quarter)) continue
    if (toClassCode === classroom.code) continue
    const toLevel = toClassCode.slice(1, 2)
    const newLevel = classroom.code.slice(1, 2)
    const toGrade = parseInt(toClassCode.slice(2, 4))
    const newGrade = parseInt(classroom.code.slice(2, 4))

    if (newLevel > toLevel) continue
    if (newLevel === toLevel && newGrade >= toGrade && subjectCode !== 'P')
      continue

    if (subjectCode === 'M') {
      if (newLevel === toLevel && newGrade < toGrade - 1) continue
      if (
        newLevel < toLevel &&
        (newGrade < toGrade - 1 || newGrade > toGrade + 1)
      )
        continue
    }

    if (['E', 'S'].includes(subjectCode) && newGrade < toGrade - 2) continue

    alternateCourseSet.add(
      `${classroom.course.code} - ${classroom.course.title}`
    )
  }

  return [...alternateCourseSet].sort()
}

export default EvaluationForm
