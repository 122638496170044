import React, { useState, useEffect } from 'react'
import { Button } from '@mui/material'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '../../components'

const CommentDialog = props => {
  const { open, handleClose, handleSaveComment } = props
  const [comment, setComment] = useState(props.comment || '')

  useEffect(() => {
    setComment(props.comment || '')
  }, [props.id, props.comment])

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={'lg'}>
      <DialogTitle id="form-dialog-title">Add/Edit Comment</DialogTitle>
      <DialogContent>
        <TextField
          label="Comment"
          fullWidth
          required
          multiline
          rows={5}
          style={{ width: '30em', paddingBottom: '10px' }}
          value={comment || ''}
          onChange={e => setComment(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={async () => {
            handleSaveComment(props.id, comment, props.index)
            handleClose()
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}
export default CommentDialog
