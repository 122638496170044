import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import Cookies from 'js-cookie'
import { idName } from '../../config'

import { Paper } from '@mui/material'
import { ErrorMessage, LoadingMessage } from '../../components'
import { formatDayTimeRange, findCurrentDateRange } from '../../utils/datetime'
import { isInstructor } from '../../utils/instructors'
import { GET_MAKEUPS } from './queries'

import MakeupTable from './MakeupTable'
import MakeupSidebar from './MakeupSidebar'

const MakeupTableView = ({ classroomCodes }) => {
  const history = useHistory()
  const employeeId = Cookies.get(idName)

  const [userAuthorized] = useState(() => {
    const userAuthorized = JSON.parse(localStorage.getItem('userAuthorized'))
    return userAuthorized || false
  })
  const [isAdmin] = useState(() => {
    const isAdmin = JSON.parse(localStorage.getItem('isAdmin'))
    return isAdmin || false
  })

  const [myClassrooms, setMyClassrooms] = useState(true)
  const [filtered, setFiltered] = useState([])

  const [rangeStartDate, rangeEndDate] = findCurrentDateRange()

  const getMakeups = useQuery(GET_MAKEUPS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: {
        fromLesson: {
          startOn: {
            $gteDate: rangeStartDate.toISOString(),
          },
          endOn: {
            $lteDate: rangeEndDate.toISOString(),
          },
        },
      },
    },
  })

  if (!userAuthorized) return null

  const { loading, error, data } = getMakeups
  if (loading) return <LoadingMessage />
  if (error) return <ErrorMessage error={error} />

  const makeups = data.makeups
    .filter(
      makeup =>
        !myClassrooms || isInstructor(makeup.toLesson.classroom, employeeId)
    )
    .map(makeup => formatMakeups(makeup))

  const handleRowClick = rowInfo => {
    history.push(`/makeup/${rowInfo.original.id}`)
  }

  const handleFilteredChange = filtered => {
    setFiltered(filtered)
  }

  return (
    <Paper sx={{ display: 'flex' }}>
      <MakeupSidebar
        isAdmin={isAdmin}
        makeups={makeups}
        myClassrooms={myClassrooms}
        handleMyClassrooms={setMyClassrooms}
      />
      <Paper sx={{ width: '100%' }}>
        <MakeupTable
          makeups={makeups}
          classroomCodes={classroomCodes}
          // filterable={showFilters}
          // sorted={sorted}
          filtered={filtered}
          handleRowClick={handleRowClick}
          handleFilteredChange={handleFilteredChange}
        />
      </Paper>
    </Paper>
  )
}

const formatMakeups = ({
  id,
  createdBy,
  createdOn,
  fromLesson,
  status,
  student,
  toLesson,
}) => ({
  id: id,
  createdOn: new Date(createdOn),

  status: status,
  firstName: student && student.firstName,
  lastName: student && student.lastName,
  fromClass: fromLesson.classroom && fromLesson.classroom.code,
  fromLesson: fromLesson.lessonPlan && fromLesson.lessonPlan.order,
  fromDate: new Date(fromLesson.startOn),

  toClass: toLesson.classroom && toLesson.classroom.code,
  toLesson: toLesson.lessonPlan && toLesson.lessonPlan.order,
  toDate: new Date(toLesson.startOn),
  toTime: formatDayTimeRange(
    new Date(toLesson.startOn),
    new Date(toLesson.endOn)
  ),
})

export default MakeupTableView
