// @flow
export const sideBarStyles = theme => ({
  headline: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#277897',
    paddingTop: '23px',
    paddingBottom: '5px',
  },
  headlineTwo: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#277897',
    paddingBottom: '10px',
    paddingTop: '5px',
  },
  subheadingOne: {
    color: '#277897',
    paddingTop: '5px',
  },
  subheadingTwo: {
    color: '#277897',
    paddingBottom: '5px',
  },
  selectorWithMargin: {
    margin: '20px 0px',
    width: '100%',
  },
})

export const sideBarShow = {
  width: '15.38%',
  borderRight: `'1px' ${'#aaaaaa'} solid`,
  maxWidth: '15.38%',
  overflowX: undefined,
  flexShrink: 0,
  textAlign: 'left',
  backgroundColor: '#e5e5e5',
  minHeight: '1200px',
}

export const sideBarNotShow = {
  width: 0,
  borderRight: `0 ${'#aaaaaa'} solid`,
  maxWidth: 0,
  overflowX: 'hidden',
  flexShrink: 0,
  textAlign: 'left',
  backgroundColor: '#e5e5e5',
  minHeight: '1200px',
}
