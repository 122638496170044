export const arrayToObjectFilter = array =>
  array.reduce((obj, item) => {
    switch (item.id) {
      case 'issueStatus':
        if (item.value === 'ALL') {
          obj = { ...obj }
        } else if (item.value === 'RESOLVED') {
          let date = new Date()
          date.setDate(date.getDate() - 7)
          obj = {
            ...obj,
            dateResolved: {
              $gteDate: date.toISOString(),
            },
          }
        } else {
          obj[item.id] = item.value
        }
        return obj
      case 'issueType':
      case 'issueSeverity':
      case 'issuePriority':
        if (item.value !== 'ALL') {
          obj[item.id] = item.value
        }
        return obj
      case 'createdOn':
        if (item.value !== null) {
          const today = new Date(item.value)
          today.setHours(0, 0, 0, 0)

          const tomorrow = new Date(item.value)
          tomorrow.setHours(24, 0, 0, 0)

          obj[item.id] = {
            $and: [
              {
                $gteDate: today.toISOString(),
              },
              { $ltDate: tomorrow.toISOString() },
            ],
          }
        }
        return obj
      case 'code':
        obj = {
          ...obj,
          assignment: {
            lesson: {
              classroom: { code: { $match: `(?i)${item.value}` } },
            },
          },
        }
        return obj
      case 'lessonPlanOrder':
        obj = {
          ...obj,
          assignment: {
            lesson: {
              lessonPlan: { order: parseInt(item.value, 10) },
            },
          },
        }
        return obj
      case 'problemSetOrder':
        obj = {
          ...obj,
          problem: {
            problemSet: { order: parseInt(item.value, 10) },
          },
        }
        return obj
      case 'problemOrder':
        obj = {
          ...obj,
          problem: {
            order: parseInt(item.value, 10),
          },
        }
        return obj
      case 'employee':
        obj = {
          ...obj,
          responsibleEmployee: {
            firstName: { $match: `(?i)${item.value}` },
          },
        }
        return obj
      case 'assignment':
        obj = {
          ...obj,
          assignment: {
            id: {
              $in: item.value,
            },
          },
        }
        return obj
      default:
        obj[item.id] = { $match: `(?i)${item.value}` }
        return obj
    }
  }, {})

export const arrayToObjectSort = array =>
  array.reduce((obj, item) => {
    switch (item.id) {
      case 'firstName':
        obj = {
          ...obj,
          student: { firstName: item.desc ? -1 : 1 },
        }
        return obj
      case 'lastName':
        obj = {
          ...obj,
          student: { lastName: item.desc ? -1 : 1 },
        }
        return obj
      case 'code':
        obj = {
          ...obj,
          assignment: {
            lesson: {
              classroom: { code: item.desc ? -1 : 1 },
            },
          },
        }
        return obj
      case 'lessonPlanOrder':
        obj = {
          ...obj,
          assignment: {
            lesson: {
              lessonPlan: { order: item.desc ? -1 : 1 },
            },
          },
        }
        return obj
      case 'problemSetOrder':
        obj = {
          ...obj,
          problem: {
            problemSet: { order: item.desc ? -1 : 1 },
          },
        }
        return obj
      case 'problemOrder':
        obj = {
          ...obj,
          problem: {
            order: item.desc ? -1 : 1,
          },
        }
        return obj
      case 'employee':
        obj = {
          ...obj,
          responsibleEmployee: {
            firstName: item.desc ? -1 : 1,
          },
        }
        return obj
      default:
        obj[item.id] = item.desc ? -1 : 1
        return obj
    }
  }, {})
