import React, { Component } from 'react'
import './App.css'
import 'typeface-roboto'

import { ApolloProvider } from '@apollo/client'
import { ApolloClient } from '@apollo/client'
import { createHttpLink } from '@apollo/client'
import { WebSocketLink } from '@apollo/client/link/ws'
import { split } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'
import { InMemoryCache } from '@apollo/client/cache'
import { BrowserRouter, Route } from 'react-router-dom'
import { onError } from '@apollo/client/link/error'

import { theme } from './theme'
import { ThemeProvider } from '@mui/material/styles'
import { apiUrl, tokenName } from './config'

import NavBar from './components/NavBar'
import TabBar from './components/TabBar'
import MainView from './components/MainView'

import { redirect } from '../src/components/NavBar'

const httpLink = createHttpLink({
  uri: apiUrl + '/graphql',
})

const regex = new RegExp(`${tokenName}=([\\w.-]*)`)

const getToken = () => {
  const cookies = document.cookie.toString()
  return regex.exec(cookies)[1]
}

let token
const authLink = setContext((_, { headers }) => {
  if (!token) token = getToken()

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  }
})

const resetToken = onError(({ networkError }) => {
  if (
    networkError &&
    networkError.name === 'ServerError' &&
    networkError.statusCode === 401
  ) {
    // remove cached token on 401 from the server
    redirect()
  }
})

const wsLink = new WebSocketLink({
  uri:
    apiUrl === 'https://ardent-api-next.ardentlabs.io'
      ? 'wss://ardent-api-next.ardentlabs.io/graphql'
      : 'wss://ardent-api.ardentlabs.io/graphql',
  options: {
    reconnect: true,
    connectionParams: () => {
      if (!token) token = getToken()
      return {
        reconnect: true,
        timeout: 30000,
        Authorization: token ? `Bearer ${token}` : '',
        authToken: token,
      }
    },
  },
})

export const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  httpLink
)

export const client = new ApolloClient({
  link: authLink.concat(resetToken).concat(link),
  cache: new InMemoryCache(),
})

class InnerApp extends Component {
  render() {
    return (
      <>
        <NavBar />
        <TabBar path={this.props.location.pathname} />
        <MainView />
      </>
    )
  }
}

class App extends Component {
  render() {
    return (
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Route component={InnerApp} />
          </BrowserRouter>
        </ThemeProvider>
      </ApolloProvider>
    )
  }
}

export default App
