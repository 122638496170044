import React, { useMemo } from 'react'
import {
  Flex,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Text,
} from '../../../components'
import { convertToMDY } from '../../../utils/datetime'
import { convertPhoneNumber } from '../../../utils'

const useStyles = makeStyles(theme => ({
  defaultHeaderCellStyle: {
    height: '3em',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'lightgrey',
    padding: '5px 5px 5px 5px',
    align: 'center',
    alignItems: 'center',
  },
  enrolledCell: {
    color: '#27AE60',
    height: '3em',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'lightgrey',
    padding: '5px 5px 5px 5px',
    align: 'center',
    alignItems: 'center',
  },
  trialCell: {
    color: '#EB5757',
    height: '3em',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'lightgrey',
    padding: '5px 5px 5px 5px',
    align: 'center',
    alignItems: 'center',
  },
  qualifyingCell: {
    color: '#00ADEF',
    height: '3em',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'lightgrey',
    padding: '5px 5px 5px 5px',
    align: 'center',
    alignItems: 'center',
  },
  makeUpCell: {
    color: '#00ADEF',
    height: '3em',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'lightgrey',
    padding: '5px 5px 5px 5px',
    align: 'center',
    alignItems: 'center',
  },
}))

export const EnrollmentTableView = React.memo(({ classId, students }) => {
  const enrollments = students.map(
    ({
      id,
      type,
      gradeUpdated,
      username,
      birthdate,
      gender,
      primaryGuardian,
      email,
      phoneNumber,
      lastLogin,
      enrollDate,
      overallGrade,
    }) => ({
      id,
      type,
      gradeUpdated,
      username,
      birthdate,
      gender,
      primaryGuardian,
      email,
      phoneNumber,
      lastLogin,
      enrollDate,
      overallGrade,
    })
  )

  return (
    <Flex align="left" column grow={2}>
      <EnrollmentTable enrollments={enrollments} classId={classId} />
    </Flex>
  )
})

const EnrollmentTable = React.memo(({ enrollments, classId }) => {
  const classes = useStyles()

  const CellFork = useMemo(
    () => ({ header, value, id }) => {
      let fixedValue
      let textColor
      if (header === 'type') {
        switch (value) {
          case 'PAID':
            fixedValue = 'Enrolled'
            textColor = classes.enrolledCell
            break
          case 'TRIAL':
            fixedValue = 'Trial'
            textColor = classes.trialCell
            break
          case 'QUALIFY':
            fixedValue = 'Qualifying'
            textColor = classes.qualifyingCell
            break
          case 'MAKEUP':
            fixedValue = 'Make-up'
            textColor = classes.makeUpCell
            break
          default:
            break
        }
      }
      switch (header) {
        case 'id':
          return <></>
        case 'type':
          return (
            <TableCell
              className={textColor}
              style={{ width: 100 }}
              key={`${id}_${header}`}
            >
              <Text align={'center'}>{fixedValue}</Text>
            </TableCell>
          )
        case 'gradeUpdated':
          return (
            <TableCell
              className={classes.defaultHeaderCellStyle}
              key={`${id}_${header}`}
            >
              <Text align={'center'}>{value ? 'TRUE' : 'FALSE'}</Text>
            </TableCell>
          )
        case 'birthdate':
          const today = convertToMDY(new Date()).slice(0, 5)
          const birthdate = convertToMDY(value).slice(0, 5)
          return (
            <TableCell
              className={classes.defaultHeaderCellStyle}
              style={{
                width: 100,
                backgroundColor: today !== birthdate ? 'White' : 'yellow',
              }}
              key={`${id}_${header}`}
            >
              <Text align={'center'}>{value && convertToMDY(value)}</Text>
            </TableCell>
          )
        case 'gender':
          return (
            <TableCell
              className={classes.defaultHeaderCellStyle}
              style={{ width: 60 }}
              key={`${id}_${header}`}
            >
              <Text align={'center'}>
                {value === 'MALE' ? 'M' : value === 'FEMALE' ? 'F' : '-'}
              </Text>
            </TableCell>
          )
        case 'phone':
          return (
            <TableCell
              className={classes.defaultHeaderCellStyle}
              key={`${id}_${header}`}
            >
              <Text align={'center'}>{convertPhoneNumber(value)}</Text>
            </TableCell>
          )
        case 'email':
          return (
            <TableCell
              className={classes.defaultHeaderCellStyle}
              key={`${id}_${header}`}
            >
              <Text align={'center'}>{value}</Text>
            </TableCell>
          )
        case 'lastLogin':
          const time = new Date(value).toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          })
          return (
            <TableCell
              className={classes.defaultHeaderCellStyle}
              style={{ width: 200 }}
              key={`${id}_${header}`}
            >
              <Text align={'center'}>
                {value ? convertToMDY(value).toString() + ', ' + time : 'hello'}
              </Text>
            </TableCell>
          )
        case 'enrollDate':
          return (
            <TableCell
              className={classes.defaultHeaderCellStyle}
              style={{ width: 100 }}
              key={`${id}_${header}`}
            >
              <Text align={'center'}>{value && convertToMDY(value)}</Text>
            </TableCell>
          )
        case 'overallGrade':
          return (
            <TableCell
              key={`${id}_${header}`}
              className={classes.defaultHeaderCellStyle}
              style={{
                width: 60,
                backgroundColor:
                  value === null || value === undefined
                    ? 'White'
                    : 90 <= value && value <= 100
                    ? '#b9f6ca'
                    : 80 <= value && value < 90
                    ? '#fff9c4'
                    : 70 <= value && value < 80
                    ? '#ffe082'
                    : value && '#ffccbc',
              }}
            >
              <Text align={'center'}>
                {value === null || value === undefined ? 'N/A' : value}
              </Text>
            </TableCell>
          )
        default:
          return (
            <TableCell
              key={`${id}_${header}`}
              className={classes.defaultHeaderCellStyle}
            >
              <Text align={'center'}>{value}</Text>
            </TableCell>
          )
      }
    },
    [classes]
  )

  const columns = [
    {
      Header: 'Type',
      accessor: 'type',
    },
    {
      Header: 'Updated',
      accessor: 'gradeUpdated',
    },
    {
      Header: 'Username',
      accessor: 'username',
    },
    {
      Header: 'Birthdate',
      accessor: 'birthdate',
    },
    {
      Header: 'Gender',
      accessor: 'gender',
    },
    {
      Header: 'Primary Guardian',
      accessor: 'primaryGuardian',
      align: 'left',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Phone Number',
      accessor: 'phoneNumber',
    },
    {
      Header: 'Last Login',
      accessor: 'lastLogin',
    },
    {
      Header: 'Enroll Date',
      accessor: 'createdOn',
    },
    {
      Header: 'Grade',
      accessor: 'overallGrade',
    },
  ]

  return (
    <Table>
      <TableHead>
        <TableRow key={classId}>
          {columns.map(head => (
            <TableCell
              key={head.Header}
              variant={'head'}
              align={'center'}
              className={classes.defaultHeaderCellStyle}
            >
              {head.Header}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {enrollments.map((enrollment, eIndex) => {
          return (
            <TableRow hover key={eIndex}>
              {Object.entries(enrollment).map((entry, index) => {
                return (
                  <CellFork
                    key={index}
                    header={entry[0]}
                    value={entry[1]}
                    id={enrollment.id}
                  />
                )
              })}
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
})

export default EnrollmentTableView
