import React from 'react'

import Icon from '@mui/material/Icon'
import IconButton from '@mui/material/IconButton'

import AddIcon from '@mui/icons-material/Add'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import AssignmentIcon from '@mui/icons-material/Assignment'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import AttachmentIcon from '@mui/icons-material/Attachment'
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory'
import CheckIcon from '@mui/icons-material/Check'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ClearIcon from '@mui/icons-material/Clear'
import ColorizeIcon from '@mui/icons-material/Colorize'
import DeleteIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit'
import EmailIcon from '@mui/icons-material/Email'
import ErrorIcon from '@mui/icons-material/Error'
import HelpIcon from '@mui/icons-material/Help'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import InfoIcon from '@mui/icons-material/Info'
import LinkIcon from '@mui/icons-material/Link'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import PasswordIcon from '@mui/icons-material/Password'
import PermPhoneMsgIcon from '@mui/icons-material/PermPhoneMsg'
import PersonIcon from '@mui/icons-material/Person'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked'
import SaveAltOutlinedIcon from '@mui/icons-material/SaveAltOutlined'
import SettingsIcon from '@mui/icons-material/SettingsApplications'
import SpellcheckIcon from '@mui/icons-material/Spellcheck'
import SyncIcon from '@mui/icons-material/Sync'
import TextsmsIcon from '@mui/icons-material/Textsms'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import {
  onTime,
  absent,
  leaveEarly,
  arriveLate,
  unknown,
  exempt,
  // note,
} from './assests'

const IconMaker = ({ src, width, height, ...rest }) => (
  <img src={src} width={width} height={height} alt={rest.alt} {...rest} />
)

const StatusIcon = ({ status, height = '30px', width = '30px' }) => {
  const img = getImg(status)
  return (
    <IconMaker src={img} height={height} width={width} alt={status + ' icon'} />
  )
}
const getImg = status => {
  switch (status) {
    case 'ONTIME':
      return onTime
    case 'ABSENT':
      return absent
    case 'EXEMPT':
      return exempt
    case 'EARLY':
      return leaveEarly
    case 'LATE':
      return arriveLate
    default:
      return unknown
  }
}

export {
  Icon,
  IconButton,
  IconMaker,
  StatusIcon,
  CheckBoxIcon,
  CheckBoxOutlineBlankIcon,
  CheckCircleOutlineIcon,
  CheckCircleIcon,
  DeleteIcon,
  ErrorIcon,
  HighlightOffIcon,
  InfoIcon,
  PermPhoneMsgIcon,
  RadioButtonUncheckedIcon,
  RadioButtonCheckedIcon,
  SettingsIcon,
  SpellcheckIcon,
  EditIcon,
  SaveAltOutlinedIcon,
  CheckIcon,
  ClearIcon,
  AddIcon,
  LinkIcon,
  ChangeHistoryIcon,
  PersonIcon,
  MoreVertIcon,
  EmailIcon,
  AlternateEmailIcon,
  SyncIcon,
  TextsmsIcon,
  AssignmentIcon,
  AssignmentTurnedInIcon,
  ColorizeIcon,
  ThumbUpIcon,
  AttachmentIcon,
  HelpIcon,
  PasswordIcon,
}
