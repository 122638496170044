import { gql } from '@apollo/client'

export const GET_TRIALS = gql`
  query getTrials($filter: Filters!) {
    trials(filter: $filter) {
      id
      status
      toLesson {
        id
        lessonPlan {
          id
          order
        }
        classroom {
          id
          code
          instructors {
            id
            isAssistant
            displayName
            employee {
              id
              firstName
              lastName
            }
          }
          instructorDisplayNames
        }
        startOn
        endOn
      }
      opportunity {
        id
        status
        student {
          id
          firstName
          lastName
        }
      }
    }
  }
`

export const GET_TRIAL = gql`
  query getTrial($id: ID!) {
    trial(id: $id) {
      id
      status
      followupStatus
      followupDate
      followupEmployee {
        id
        firstName
        lastName
      }
      followupResult
      instructorComment
      quizGrade
      quizScore
      quizTotal
      evaluationMentalMath
      evaluationSkillBuilder
      evaluationProblemSolving
      evaluationMotivation
      evaluationDiscipline
      recommendation
      notes
      alternateCourseTitle
      toLesson {
        id
        lessonPlan {
          id
          title
          order
        }
        classroom {
          id
          code
          title
        }
        startOn
        endOn
      }
      opportunity {
        id
        status
        student {
          id
          familyAccount {
            id
            primaryFamilyMember {
              id
              phoneNumber
              email
            }
          }
          firstName
          lastName
        }
      }
    }
  }
`

export const UPDATE_TRIAL = gql`
  mutation updateTrial($id: ID!, $input: UpdateTrialInput!) {
    updateTrial(id: $id, input: $input) {
      id
    }
  }
`

export const GET_CLASSCODES = gql`
  query getClasses($filter: Filters!) {
    classrooms(filter: $filter) {
      id
      code
      quarter
      course {
        code
        title
      }
    }
  }
`
