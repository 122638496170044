import React, { useState } from 'react'
import {
  AttachmentIcon,
  Button,
  CheckCircleOutlineIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LaTeX,
  ErrorMessage,
  LoadingMessage,
  Flex,
  HighlightOffIcon,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  RadioButtonUncheckedIcon,
} from '../../components'
import { GET_SUBMISSION, SUBMIT_GRADES } from './queries'

import { cloneDeep } from 'lodash'
import { useQuery } from '@apollo/client'
import { withApollo } from '@apollo/client/react/hoc'

const cellStyle = {
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: 'lightgrey',
  padding: '5px 5px 5px 5px',
  align: 'center',
}

const SubmissionDialog = ({
  id,
  open,
  handleClose,
  handleFetch,
  client,
  issue,
}) => {
  const resolveData = submission => {
    const { assignment, responses, student } = submission
    //Sort problemSet by order
    const problemSet = assignment.problemSet.problems.sort(
      (a, b) => a.order - b.order
    )
    //Sort studentResponses by problemSet order
    const studentResponses = new Array(problemSet.length).fill(null)
    const studentGrades = new Array(problemSet.length).fill(null)
    responses.forEach(response => {
      studentResponses[response.problem.order - 1] = response

      const correct = 1
      const incorrect = 0
      const noSubmission = null
      if (
        studentResponses[response.problem.order - 1] === null ||
        studentResponses[response.problem.order - 1] === undefined
      ) {
        studentGrades[response.problem.order - 1] = noSubmission
      } else if (
        studentResponses[response.problem.order - 1].percentCorrect > 0
      ) {
        studentGrades[response.problem.order - 1] = correct
      } else {
        studentGrades[response.problem.order - 1] = incorrect
      }

      //If the problem is type DISPLAY, we should not be able to edit
      //the points
      problemSet[response.problem.order - 1].isDisplay =
        'DISPLAY' === problemSet[response.problem.order - 1].problemType
    })

    return {
      studentId: student.id,
      problemSet,
      studentResponses,
      studentGrades,
    }
  }
  const { error, data, loading } = useQuery(GET_SUBMISSION, {
    variables: { id: id },
    fetchPolicy: 'network-only',
  })

  if (loading) {
    return <LoadingMessage />
  }
  if (error) {
    return <ErrorMessage error={error} />
  }
  const resolvedData = resolveData(data.submission)
  return (
    <InnerDialog
      open={open}
      handleClose={handleClose}
      resolvedData={resolvedData}
      rawData={data.submission}
      client={client}
      handleFetch={handleFetch}
    />
  )
}

const InnerDialog = props => {
  const {
    open,
    handleClose,
    resolvedData,
    rawData,
    client,
    handleFetch,
  } = props
  const [grades, setGrades] = useState(cloneDeep(resolvedData.studentGrades))

  const handleGradeChange = (grade, i) => {
    if (!resolvedData.problemSet[i].isDisplay) {
      if (grade === null) {
        grades[i] = 1
      } else if (grade) {
        grades[i] = 0
      } else {
        grades[i] = null
      }
      setGrades([...grades])
    }
  }

  const handleReset = e => {
    setGrades([...resolvedData.studentGrades])
  }

  const handleSave = async e => {
    const aid = rawData.assignment.id
    const pids = resolvedData.problemSet.map(problem => problem.id)
    const input = {
      studentId: resolvedData.studentId,
      responses: grades,
    }
    const {
      data: {
        gradeAssignment: { success },
      },
    } = await client.mutate({
      mutation: SUBMIT_GRADES,
      variables: { aid, pids, input },
    })
    if (success) {
      handleClose()
      handleFetch()
    }
  }

  const columnHeaders = [
    {
      header: 'Problem',
      align: 'center',
      width: '5%',
    },
    {
      header: 'Correct Answer',
      width: '40%',
      align: 'center',
    },
    {
      header: 'Student Answer',
      width: '40%',
      align: 'center',
    },
    {
      header: <CheckCircleOutlineIcon color="primary" />,
      width: '5%',
      align: 'center',
    },
    {
      header: 'Attachment',
      width: '5%',
      align: 'center',
    },
    {
      header: 'Scratchpad',
      width: '5%',
      align: 'center',
    },
  ]

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'lg'}>
      <DialogTitle>
        Grading submission for {rawData.student.firstName}{' '}
        {rawData.student.lastName}
      </DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              {columnHeaders.map((header, idx) => (
                <TableCell
                  key={idx}
                  align={cellStyle.align}
                  width={header.width}
                  style={cellStyle}
                >
                  {header.header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {resolvedData.problemSet.map((problem, i) => {
              const studentResponse = resolvedData.studentResponses[i]
              let problemAnswer = problem.answer
              if (
                problemAnswer === '' ||
                problemAnswer === null ||
                problemAnswer === undefined
              ) {
                problemAnswer = 'N/A'
              }
              return (
                <TableRow hover style={{ height: 20 }} key={`problem_${i}`}>
                  <TableCell
                    align={cellStyle.align}
                    width={'10%'}
                    style={cellStyle}
                  >
                    {problem.order}
                  </TableCell>
                  <TableCell
                    align={cellStyle.align}
                    width={'35%'}
                    style={cellStyle}
                  >
                    {problem.problemType === 'SCIENTIFIC_UNIT' ? (
                      <LaTeX latex={problemAnswer} />
                    ) : (
                      `${problemAnswer}`
                    )}
                  </TableCell>
                  <TableCell
                    align={cellStyle.align}
                    width={'35%'}
                    style={cellStyle}
                  >
                    {problem.problemType === 'SCIENTIFIC_UNIT' &&
                    studentResponse &&
                    studentResponse.value !== null &&
                    studentResponse.value !== undefined ? (
                      <LaTeX latex={studentResponse.value} />
                    ) : studentResponse && studentResponse.value ? (
                      studentResponse.value
                    ) : (
                      'No Submission'
                    )}
                  </TableCell>
                  <TableCell
                    align={cellStyle.align}
                    width={'10%'}
                    style={cellStyle}
                  >
                    {grades[i] === null || problem.problemType === 'DISPLAY' ? (
                      <RadioButtonUncheckedIcon
                        color="disabled"
                        onClick={() => handleGradeChange(grades[i], i)}
                      />
                    ) : grades[i] ? (
                      <CheckCircleOutlineIcon
                        color="primary"
                        onClick={() => handleGradeChange(grades[i], i)}
                      />
                    ) : (
                      <HighlightOffIcon
                        color="secondary"
                        onClick={() => handleGradeChange(grades[i], i)}
                      />
                    )}
                  </TableCell>
                  <TableCell
                    align={cellStyle.align}
                    width={'10%'}
                    style={cellStyle}
                  >
                    {studentResponse && studentResponse.attachmentUrl ? (
                      <IconButton
                        onClick={() =>
                          window.open(studentResponse.attachmentUrl)
                        }
                      >
                        <AttachmentIcon color="primary" />
                      </IconButton>
                    ) : (
                      <IconButton disabled>
                        <AttachmentIcon color="disabled" />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell
                    align={cellStyle.align}
                    width={'10%'}
                    style={cellStyle}
                  >
                    {studentResponse && studentResponse.scratchpadUrl ? (
                      <IconButton
                        onClick={() =>
                          window.open(studentResponse.scratchpadUrl)
                        }
                      >
                        <AttachmentIcon color="primary" />
                      </IconButton>
                    ) : (
                      <IconButton disabled>
                        <AttachmentIcon color="disabled" />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Flex
          style={{
            margin: '16px',
            width: '100%',
          }}
        >
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleReset}
            color="primary"
            style={{ marginLeft: 'auto' }}
          >
            Reset
          </Button>
          <Button
            onClick={() => {
              const aid = rawData.assignment.id
              const sid = rawData.student.id
              if (process.env.REACT_APP_NODE_ENV !== 'production') {
                window.open(
                  `https://instructor-next.ardentlabs.io/grade/${aid}/${sid}`
                )
              } else {
                window.open(
                  `https://instructor.ardentlabs.io/grade/${aid}/${sid}`
                )
              }
            }}
            color="primary"
            style={{ marginLeft: '16px' }}
          >
            Details
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            style={{ marginLeft: '16px' }}
          >
            Save
          </Button>
        </Flex>
      </DialogActions>
    </Dialog>
  )
}

export default withApollo(SubmissionDialog)
