import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Flex,
  LoadingMessage,
  ErrorMessage,
} from '../../../components'
import { ButtonIcon } from '../Icons'
import {
  cleanUpData,
  getTitles,
  NonIconColumns,
  compareStudents,
} from '../utils'
import AttendanceDialog from '../AttendanceDialog'
import { useQuery } from '@apollo/client'
import { GET_LESSONS } from '../queries'

const HeaderCellStyle = {
  height: '3em',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: 'lightgrey',
  padding: '5px 5px 5px 5px',
  align: 'center',
  alignItems: 'center',
  borderRightWidth: '0px',
  borderBottomWidth: '0px',
}

const DefaultCellStyle = {
  padding: '5px',
  width: '2.5em',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: 'lightgrey',
  borderRightWidth: '0px',
  borderBottomWidth: '0px',
}

export const AttendanceTableView = React.memo(({ classId }) => {
  const { loading, error, refetch, data } = useQuery(GET_LESSONS, {
    variables: { id: classId },
  })
  if (loading) return <LoadingMessage />
  if (error) return <ErrorMessage error={error} />

  return (
    <Flex align="left" column grow={2}>
      <AttendanceTable data={data} id={classId} refetch={refetch} />
    </Flex>
  )
})

const AttendanceTable = props => {
  const [open, setOpen] = React.useState(false)
  const [id, setId] = React.useState('')
  const handleClickOpen = id => {
    setOpen(true)
    setId(id)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const { classroom } = props.data
  const lessons = [...classroom.lessons]
  lessons.sort((a, b) => a.lessonPlan.order - b.lessonPlan.order)

  const data = cleanUpData(lessons)
  const titles = getTitles(lessons)
  const lightArdentBlue = '#E7F0FD'

  //Sorting students by last name
  data.sort((a, b) =>
    compareStudents(
      a.student.lastName,
      b.student.lastName,
      a.student.firstName,
      b.student.firstName
    )
  )
  return (
    <Table stickyHeader style={{ width: 'auto' }}>
      <TableHead>
        <TableRow>
          {titles.map(column => {
            if (column.Header !== 'Student Name')
              return (
                <TableCell
                  key={column.accessor}
                  align={column.align}
                  style={HeaderCellStyle}
                >
                  {column.Header}
                </TableCell>
              )
            else return null
          })}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row, index) => (
          <TableRow key={index}>
            {titles.map(column => {
              const value = row[column.accessor]
              if (column.accessor === 'student') return null
              if (NonIconColumns(column))
                return (
                  <TableCell
                    key={column.accessor}
                    align={column.align}
                    style={{ ...DefaultCellStyle }}
                  >
                    {value}
                  </TableCell>
                )
              return (
                <TableCell
                  key={column.accessor}
                  align={column.align}
                  style={{
                    backgroundColor: value.isMakeup && lightArdentBlue,
                    ...DefaultCellStyle,
                  }}
                >
                  <ButtonIcon
                    attendeeId={value.id}
                    status={value.status}
                    onClick={() => handleClickOpen(value.id)}
                    notePresent={value.note === null ? false : true}
                    padding={'0px'}
                  />
                </TableCell>
              )
            })}
          </TableRow>
        ))}
      </TableBody>
      {open && (
        <AttendanceDialog
          open={open}
          handleClose={handleClose}
          attendeeId={id}
          classroomId={props.id}
          data={data}
          refetch={props.refetch}
        />
      )}
    </Table>
  )
}

export default AttendanceTableView
