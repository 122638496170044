import React, { useEffect, useState, useCallback } from 'react'
import Tooltip from '@mui/material/Tooltip'
import { Redirect } from 'react-router-dom'
import { Query, Subscription } from '@apollo/client/react/components'
import { withApollo } from '@apollo/client/react/hoc'
import { useMutation } from '@apollo/client'

import {
  Flex,
  LoadingMessage,
  ErrorMessage,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  CheckCircleOutlineIcon,
  HighlightOffIcon,
  RadioButtonUncheckedIcon,
  CheckBoxIcon,
  CheckBoxOutlineBlankIcon,
  SpellcheckIcon,
  StatusIcon,
  HelpIcon,
} from '../../../components'
import { compareStudents } from '../utils'
import {
  GET_GRADES_FROM_ASSIGNMENT,
  SUBMIT_GRADES,
  UPDATE_ATTENDEE_STATUS,
  LIVE_ASSIGNMENT,
} from '../queries'

const useStyles = makeStyles(() => ({
  defaultHeaderCellStyle: {
    height: '3em',
    maxHeight: '3em',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'lightgrey',
    padding: '5px 5px 5px 5px',
    alignItems: 'center',
    justifyContent: 'center',
    shrink: 1,
    grow: 1,
  },
  headerInnerContainer: {
    height: '100%',
    width: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    shrink: 1,
    grow: 1,
  },
}))

const cellStyle = {
  borderStyle: 'solid',
  borderWidth: '1px',
  borderColor: 'lightgrey',
  padding: '5px 5px 5px 5px',
  align: 'center',
  height: '3em',
  minWidth: '70px',
}

export const GradeTableView = ({
  classId,
  fireAction,
  handleSiblingAction,
  lesson,
  assignment,
  client,
  setSnackbar,
}) => {
  const processGrades = data => {
    const problems = [...data.assignment.problemSet.problems]
      .sort((a, b) => a.order - b.order)
      .map(problem => ({
        ...problem,
        answered: 0,
        answeredPoints: 0,
        total: 0,
      }))
    let numSubmissions = 0

    let grades = data.gradesForAssignment.map(grade => {
      const attendee = grade.student.attendees.find(
        attendee => attendee.lesson.id === lesson
      )
      return {
        ...grade,
        excused: grade.submission && grade.submission.excused,
        graded:
          grade.submission &&
          grade.submission.submittedAt &&
          grade.submission.graded,
        submittedLate: grade.submission && grade.submission.submittedLate,
        attendeeStatus: attendee ? attendee.status : 'UNKNOWN',
        attendeeId: attendee ? attendee.id : '',
      }
    })

    const orderedGrades = grades.sort((a, b) =>
      compareStudents(
        a.student.lastName,
        b.student.lastName,
        a.student.firstName,
        b.student.firstName
      )
    )

    for (const [i, { submission }] of Object.entries(orderedGrades)) {
      orderedGrades[i].changed = new Array(problems.length).fill(null)
      orderedGrades[i].submission = new Array(problems.length).fill(null)
      orderedGrades[i].studentAnswer = new Array(problems.length).fill(null)
      orderedGrades[i].submissionMaxPoints = problems.map(
        problem => problem.points
      )
      if (submission === null) continue
      numSubmissions++
      if (submission.responses === undefined) {
        orderedGrades[i].submission = submission
        continue
      }
      orderedGrades[i].graded = submission.graded
      orderedGrades[i].overallGrade =
        submission.graded && submission.overallGrade
      let allCorrect =
        submission.responses.length === problems.length ? true : false
      for (const response of submission.responses) {
        orderedGrades[i].submission[
          response.problem && response.problem.order - 1
        ] = response.sum
        orderedGrades[i].studentAnswer[
          response.problem && response.problem.order - 1
        ] = response.value
        problems[
          response.problem && response.problem.order - 1
        ].answered += !!response.sum
        problems[
          response.problem && response.problem.order - 1
        ].percentCorrect += response.percentCorrect

        //answeredPoints is the total amount of points that the students answered for this problem
        //null submissions are not counted
        problems[
          response.problem && response.problem.order - 1
        ].answeredPoints += response.sum

        //total is the total amount of points that all students can get for this problem
        //null submissions are not counted
        problems[response.problem && response.problem.order - 1].total +=
          response.count
        if (response.percentCorrect !== 100) {
          allCorrect = false
        }
      }
      orderedGrades[i].allCorrect = allCorrect
    }
    grades = calculatePercentages(orderedGrades)
    const unmodifiedProblems = JSON.parse(JSON.stringify(problems))
    const unmodifiedGrades = JSON.parse(JSON.stringify(grades)) //makes deep copy

    return {
      grades: grades,
      problems: problems,
      autoGrading: data.assignment.problemSet.autoGrading,
      assignmentTitle: data.assignment.title,
      numSubmissions,
      unmodifiedGrades,
      unmodifiedProblems,
    }
  }

  const calculatePercentages = grades => {
    const newGrades = grades.map(grade => {
      let unanswered = 0,
        correct = 0,
        incorrect = 0,
        points = 0,
        maxPoints = 0
      for (let i = 0; i < grade.submission.length; i++) {
        if (grade.submission[i] === null) {
          unanswered++
          maxPoints += grade.submissionMaxPoints[i]
        } else if (grade.submission[i] <= 0) {
          incorrect++
          maxPoints += grade.submissionMaxPoints[i]
        } else {
          correct++
          maxPoints += grade.submissionMaxPoints[i]
          points += grade.submission[i]
        }
      }
      const percent = ((points / maxPoints) * 100).toFixed(0)
      return {
        ...grade,
        unanswered,
        correct,
        incorrect,
        points,
        percent: percent ? `${percent}%` : 0,
      }
    })
    return newGrades
  }

  return (
    <React.Fragment>
      {lesson && assignment && (
        <Query
          query={GET_GRADES_FROM_ASSIGNMENT}
          variables={{
            id: assignment,
            attendeeFilter: {
              lesson: {
                id: lesson,
              },
            },
          }}
          fetchPolicy={'network-only'}
          context={{
            headers: {
              'cache-control': 'no-cache',
            },
          }}
        >
          {({ error, loading, data }) => {
            if (loading) return <LoadingMessage />
            if (error) return <ErrorMessage error={error} />
            return (
              <GradesTable
                {...processGrades(data)}
                client={client}
                classId={classId}
                lessonId={lesson}
                assignmentId={assignment}
                assignmentTitle={data.assignment.problemSet.title}
                fireAction={fireAction}
                handleSiblingAction={handleSiblingAction}
                calculatePercentages={calculatePercentages}
                setSnackbar={setSnackbar}
              />
            )
          }}
        </Query>
      )}
    </React.Fragment>
  )
}

const GradesTable = props => {
  const [unmodifiedData, setUnmodifiedData] = useState({
    grades: props.unmodifiedGrades,
    problems: props.unmodifiedProblems,
  })
  const [grades, setGrades] = useState(props.grades)
  const [problems, setProblems] = useState(props.problems)
  const [redirect, setRedirect] = useState(null)
  const classes = useStyles()

  useEffect(() => {
    setGrades(props.grades)
    setProblems(props.problems)
    setUnmodifiedData({
      grades: props.unmodifiedGrades,
      problems: props.unmodifiedProblems,
    })
  }, [props.assignmentId])

  /**
   * fires action on fireAction trigger
   *
   * don't add the dependencies
   */
  useEffect(() => {
    if (props.fireAction) {
      switch (props.fireAction) {
        case 'submit':
          props.handleSiblingAction(async () => {
            await handleSubmit(grades, problems)
          })
          break
        case 'reset':
          props.handleSiblingAction(handleReset)
          break
        default:
          console.log('Undefined Action')
          break
      }
    }
  }, [props.fireAction])

  const getGradesAssignmentInputs = useCallback(grades => {
    return grades.map(grade => {
      return {
        studentId: grade.student.id,
        submittedLate: grade.submittedLate,
        responses: grade.submission,
      }
    })
  }, [])

  const [updateAttendeeStatus] = useMutation(UPDATE_ATTENDEE_STATUS)
  const handleChangeAttendance = async (attendeeId, attendeeStatus) => {
    await updateAttendeeStatus({
      variables: {
        id: attendeeId,
        input: { status: attendeeStatus },
      },
    })
  }

  const handleSubmit = useCallback(
    async (grades, problems) => {
      props.setSnackbar({
        open: true,
        message: 'Grade Submission in Progress',
        messageType: 'loading',
      })
      if (grades) {
        let {
          data: {
            gradeAssignment: { success },
          },
        } = await props.client.mutate({
          mutation: SUBMIT_GRADES,
          variables: {
            aid: props.assignmentId,
            pids: problems.map(problem => problem.id),
            input: getGradesAssignmentInputs(grades),
          },
          refetchQueries: [
            {
              query: GET_GRADES_FROM_ASSIGNMENT,
              variables: {
                id: props.assignmentId,
                lessonId: props.lessonId,
              },
            },
          ],
        })
        props.setSnackbar({
          open: true,
          message: success ? 'Grades Submitted ✔' : 'error',
          messageType: success ? 'success' : 'error',
        })
        //New unmodified data since we made new changes
        setUnmodifiedData({
          grades: [...JSON.parse(JSON.stringify(grades))],
          problems: [...JSON.parse(JSON.stringify(problems))],
        })
      } else {
        console.log('Missing/Invalid Grades')
      }
    },
    [
      props.client,
      props.assignmentId,
      props.lessonId,
      unmodifiedData.grades,
      getGradesAssignmentInputs,
    ]
  )

  const handleReset = () => {
    setGrades([...JSON.parse(JSON.stringify(unmodifiedData.grades))])
    setProblems([...JSON.parse(JSON.stringify(unmodifiedData.problems))])
  }

  const onChange = (rowNum, problemNum, nodeValue, key = '') => {
    let newGrades = [...grades]
    let newProblems = [...problems]
    switch (key) {
      case 'username':
        //Save current lesson and assignment before redirect
        localStorage.setItem('lesson', props.lessonId)
        localStorage.setItem('assignment', props.assignmentId)
        setRedirect(
          `/classroom/${props.classId}/grade/${props.assignmentId}/${nodeValue.id}`
        )
        break
      case 'attendeeStatus':
        const currentStatus = newGrades[rowNum].attendeeStatus
        let newStatus
        //Cycle statuses : UNKNOWN->ONTIME->ABSENT->LATE->EARLY->EXEMPT->UNKNOWN
        switch (currentStatus) {
          case 'UNKNOWN':
            newStatus = 'ONTIME'
            break
          case 'ONTIME':
            newStatus = 'ABSENT'
            break
          case 'ABSENT':
            newStatus = 'LATE'
            break
          case 'LATE':
            newStatus = 'EARLY'
            break
          case 'EARLY':
            newStatus = 'EXEMPT'
            break
          case 'EXEMPT':
            newStatus = 'UNKNOWN'
            break
          default:
            newStatus = 'UNKNOWN'
            break
        }
        newGrades[rowNum].attendeeStatus = newStatus
        handleChangeAttendance(newGrades[rowNum].attendeeId, newStatus)
        break
      case 'allCorrect':
        if (!nodeValue) {
          //If we set to allCorrect
          newGrades[rowNum].submission = grades[rowNum].submission.map(
            (sub, index) => {
              if (grades[rowNum].submission[index] !== null) {
                newProblems[index].answeredPoints -=
                  grades[rowNum].submission[index]
              } else {
                newProblems[index].total +=
                  grades[rowNum].submissionMaxPoints[index]
              }
              newProblems[index].answeredPoints +=
                newGrades[rowNum].submissionMaxPoints[index]
              sub = newGrades[rowNum].submissionMaxPoints[index]
              return sub
            }
          )
        } else {
          newGrades[rowNum].submission = newGrades[rowNum].submission.map(
            (sub, index) => {
              if (sub) {
                newProblems[index].total -=
                  grades[rowNum].submissionMaxPoints[index]
                newProblems[index].answeredPoints -= sub
              }
              sub = null
              return sub
            }
          )
        }
        newGrades[rowNum].allCorrect = !nodeValue
        break
      case 'submission':
        //correct -> incorrect -> null -> correct
        if (nodeValue === null) {
          //null -> correct
          newProblems[problemNum].answeredPoints +=
            newGrades[rowNum].submissionMaxPoints[problemNum]
          newProblems[problemNum].total +=
            newGrades[rowNum].submissionMaxPoints[problemNum]
          newGrades[rowNum].submission[problemNum] +=
            newGrades[rowNum].submissionMaxPoints[problemNum]
          newProblems[problemNum].answered += 1

          //Check if the problems are now all correct after this nodeValue changes
          //If it is, set allCorrect to true
          newGrades[rowNum].allCorrect = newGrades[rowNum].submission.every(
            (sub, index) => {
              return sub && sub === newGrades[rowNum].submissionMaxPoints[index]
            }
          )
        } else if (nodeValue > 0) {
          //correct -> incorrect
          newProblems[problemNum].answeredPoints -=
            newGrades[rowNum].submission[problemNum]
          newGrades[rowNum].submission[problemNum] = 0
          newGrades[rowNum].allCorrect = false
        } else {
          //incorrect -> null
          newProblems[problemNum].total -=
            newGrades[rowNum].submissionMaxPoints[problemNum]
          newGrades[rowNum].submission[problemNum] = null
          newProblems[problemNum].answered -= 1
          newGrades[rowNum].allCorrect = false
        }
        break
      default:
        break
    }
    setGrades(props.calculatePercentages(newGrades))
    setProblems(newProblems)
  }

  const titleColumns = [
    {
      Header: `${props.assignmentTitle}`,
      accessor: 'student',
      align: 'center',
      width: '500em',
      colSpan: 4,
    },
    {
      Header: `Submitted: ${props.numSubmissions}/${grades.length}`,
      accessor: 'submissions',
      align: 'center',
      colSpan: 2,
    },
    {
      Header: `Attendance`,
      accessor: 'attendance',
      align: 'center',
      colSpan: 1,
    },
    {
      Header: `100%`,
      accessor: 'perfect',
      align: 'center',
      colSpan: 1,
    },
    ...problems.map(problem => {
      const percent = problem.total
        ? ((problem.answeredPoints / problem.total) * 100).toFixed(0)
        : 0
      return {
        Header: percent + '%',
        accessor: `${problem.id}`,
        align: 'center',
        colSpan: 1,
        style: {
          backgroundColor:
            percent <= 50
              ? percent > 0
                ? 'lightcoral'
                : 'white'
              : percent <= 75
              ? 'lightyellow'
              : 'limegreen',
        },
      }
    }),
  ]

  const columns = [
    {
      Header: 'Username',
      accessor: 'username',
      align: 'center',
      width: '167em',
    },
    {
      Header: <SpellcheckIcon color="secondary" />,
      accessor: 'graded',
      align: 'center',
    },
    {
      Header: <CheckCircleOutlineIcon color="primary" />,
      accessor: 'correct',
      align: 'center',
    },
    {
      Header: <HighlightOffIcon color="secondary" />,
      accessor: 'incorrect',
      align: 'center',
    },
    {
      Header: <RadioButtonUncheckedIcon color="disabled" />,
      accessor: 'unanswered',
      align: 'center',
    },
    {
      Header: <i className="material-icons">Grade</i>,
      accessor: 'percent',
      align: 'center',
    },
    {
      Header: <StatusIcon height="24px" width="24px" status="LATE" />,
      accessor: 'attendeeStatus',
      align: 'center',
    },
    {
      Header: <CheckBoxIcon color="primary" />,
      accessor: 'allCorrect',
      align: 'center',
    },
    ...problems.map((problem, index) => ({
      Header: `${index + 1}`,
      accessor: `${problem.id}`,
      align: 'center',
    })),
  ]

  return (
    <Flex grow={1}>
      {redirect && <Redirect push to={redirect} />}
      <Subscription
        subscription={LIVE_ASSIGNMENT}
        variables={{ aid: props.assignmentId }}
        onSubscriptionData={opts => {
          let data = opts.subscriptionData.data.liveAssignment
          const { problemResponse, submission } = data
          let studentIndex = grades.findIndex(
            grade => grade.student.id === submission.student.id
          )
          //Could be buggy
          //May have to findIndex instead of using order - 1 as index...
          if (problemResponse) {
            let problemIndex = problemResponse.problem.order - 1
            let previousScore = grades[studentIndex].submission[problemIndex]
            if (previousScore === null) {
              problems[problemIndex].total += problemResponse.count
            } else {
              grades[studentIndex].points -= previousScore
              grades[studentIndex].submission[problemIndex] =
                problemResponse.sum
              problems[problemIndex].answeredPoints -= previousScore
            }

            problems[problemIndex].answeredPoints += problemResponse.sum
            grades[studentIndex].submission[problemIndex] = problemResponse.sum
            grades[studentIndex].changed[problemIndex] = true
            grades[studentIndex].points += problemResponse.sum
            problems[problemIndex].answered++

            grades[studentIndex].studentAnswer[problemIndex] =
              problemResponse.value

            setGrades(props.calculatePercentages([...grades]))
            setProblems([...problems])
            //New unmodified data since we made new changes
            setUnmodifiedData({
              grades: [...JSON.parse(JSON.stringify(grades))],
              problems: [...JSON.parse(JSON.stringify(problems))],
            })
          }
        }}
        skip={false}
      >
        {() => {
          return null
        }}
      </Subscription>
      <Table>
        <TableHead>
          <TableRow>
            {titleColumns.map(data => (
              <TableCell
                key={data.accessor}
                align={'center'}
                className={classes.defaultHeaderCellStyle}
                style={{ ...data.style }}
                colSpan={data.colSpan}
              >
                {data.Header}
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            {columns.map(data => (
              <TableCell
                key={data.accessor}
                align={'center'}
                className={classes.defaultHeaderCellStyle}
              >
                {data.Header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {grades.map((grade, row) => (
            <TableRow
              key={row}
              hover={true}
              style={{
                backgroundColor:
                  grade.attendeeStatus === 'ABSENT' ? '#fad1d1' : null,
              }}
            >
              {[
                { key: 'username', value: grade.student },
                { key: 'graded', value: grade.graded },
                { key: 'correct', value: `${grade.correct}` },
                { key: 'incorrect', value: `${grade.incorrect}` },
                { key: 'unanswered', value: `${grade.unanswered}` },
                { key: 'percent', value: `${grade.percent}` },
                { key: 'attendeeStatus', value: grade.attendeeStatus },
                { key: 'allCorrect', value: grade.allCorrect },
              ].map(column => {
                return (
                  <TableCell
                    key={column.key}
                    style={cellStyle}
                    align={cellStyle.align}
                    onClick={() =>
                      //!this.props.readOnly &&
                      onChange(row, -1, column.value, column.key)
                    }
                  >
                    {column.key === 'attendeeStatus' ? (
                      <StatusIcon
                        height="24px"
                        width="24px"
                        status={grade.attendeeStatus}
                      />
                    ) : column.key === 'allCorrect' ? (
                      column.value ? (
                        <CheckBoxIcon color="primary" />
                      ) : (
                        <CheckBoxOutlineBlankIcon color="disabled" />
                      )
                    ) : column.key === 'graded' ? (
                      column.value === null ? (
                        <CheckBoxOutlineBlankIcon color="disabled" />
                      ) : column.value ? (
                        <CheckBoxIcon color="primary" />
                      ) : (
                        <HelpIcon color="secondary" />
                      )
                    ) : column.key === 'username' ? (
                      column.value.username
                    ) : (
                      column.value
                    )}
                  </TableCell>
                )
              })}
              {grade.submission.map((res, probNum) => {
                return (
                  <Tooltip
                    key={probNum}
                    title={grades[row].studentAnswer[probNum] || 'N/A'}
                  >
                    <TableCell
                      onClick={() =>
                        //!this.props.readOnly &&
                        onChange(row, probNum, res, 'submission')
                      }
                      key={probNum}
                      style={cellStyle}
                      color={grade.changed[probNum] && 'primary'}
                      align={cellStyle.align}
                    >
                      {res === null ? (
                        <RadioButtonUncheckedIcon color={'disabled'} />
                      ) : res === 0 ? (
                        <HighlightOffIcon
                          htmlColor={
                            grade.changed[probNum] ? '#ffb833' : '#f50057'
                          }
                        />
                      ) : (
                        <CheckCircleOutlineIcon
                          htmlColor={
                            grade.changed[probNum] ? '#ffb833' : '#00adef'
                          }
                        />
                      )}
                    </TableCell>
                  </Tooltip>
                )
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Flex>
  )
}

export default withApollo(GradeTableView)
