import React, { Component } from 'react'

import {
  Collapse,
  Accordion,
  AccordionDetails,
  Typography,
  CustomAccordionSummary,
} from '.'

class ExpansionPanel extends Component {
  constructor(props) {
    super(props)
    this.state = { expanded: props.expanded ? true : false }
  }

  handleChange = () => {
    const { expanded } = this.state
    this.setState({ expanded: !expanded })
  }

  render() {
    const { className, title, form, overflow } = this.props
    const { expanded } = this.state
    return (
      <Accordion
        expanded
        collapseprops={{ style: overflow && { overflow: 'visible' } }}
      >
        <CustomAccordionSummary
          onClick={this.handleChange}
          className={className}
        >
          <Typography>{title}</Typography>
        </CustomAccordionSummary>

        <Collapse in={expanded} timeout="auto" style={{ overflow: 'visible' }}>
          <AccordionDetails>{form}</AccordionDetails>
        </Collapse>
      </Accordion>
    )
  }
}

export default ExpansionPanel
