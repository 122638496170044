import React, { useState } from 'react'
import {
  Flex,
  Slider,
  ReactPlayer,
  CheckIcon,
  IconButton,
  AddIcon,
} from '../../components'

export const VideoHandler = props => {
  const [innerDuration, setInnerDuration] = useState([
    props.startTime,
    props.endTime,
  ])
  const { endTime, videoId } = props

  const handleCommitedChange = (event, newValue) => {
    props.setDuration(newValue)
  }

  //Handy quickfix for laggy slider change later
  const handleChange = (event, newValue) => {
    setInnerDuration(newValue)
  }

  return (
    <Flex column>
      <ReactPlayer
        url={`https://player.vimeo.com/video/${videoId}?title=0&portrait=0&byline=0`}
        onDuration={response => {
          if (!endTime) props.setEndTime(response)
        }}
        style={{
          marginTop: '2%',
          marginBottom: '2%',
        }}
      />
      {endTime && (
        <Flex row style={{ alignItems: 'center' }}>
          <Slider
            value={innerDuration}
            min={0}
            max={endTime}
            onChange={handleChange}
            onChangeCommitted={handleCommitedChange}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
          />
          <IconButton
            color={'primary'}
            style={{
              width: 42,
              height: 42,
            }}
            onClick={() => {
              props.handleCreate('')
            }}
            disabled={!endTime}
          >
            <AddIcon style={{ width: 24, height: 24 }} />
          </IconButton>
          <IconButton
            color={'primary'}
            style={{
              width: 42,
              height: 42,
            }}
            onClick={() => {
              props.handleUpdate('')
            }}
            disabled={!endTime}
          >
            <CheckIcon style={{ width: 24, height: 24 }} />
          </IconButton>
        </Flex>
      )}
    </Flex>
  )
}

export default VideoHandler
