import { Box, MenuItem, Select } from '@mui/material'

const TimePeriodSelector = ({
  name,
  options,
  selectedTime,
  onSelectedTimeChange,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'left',
        flexDirection: 'row',
      }}
    >
      <Select
        variant="standard"
        key={`time-range-selector`}
        fullWidth={true}
        value={selectedTime}
        onChange={e => onSelectedTimeChange(e.target.value)}
      >
        {options.map(option => (
          <MenuItem key={option.text} value={option}>
            {option.text}
          </MenuItem>
        ))}
      </Select>
    </Box>
  )
}

export default TimePeriodSelector
