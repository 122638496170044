import React from 'react'
import { sideBarStyles } from '../../utils/style'
import {
  Button,
  Divider,
  Flex,
  InputLabel,
  MenuItem,
  Select,
  ClassroomSwitcher,
  withStyles,
} from '../../components'

import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import DateAdapter from '@mui/lab/AdapterMoment'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import DateTimePicker from '@mui/lab/DateTimePicker'

const SubmissionSidebar = props => {
  const {
    timePeriod,
    startTime,
    endTime,
    updateTimePeriod,
    updateStartTime,
    updateEndTime,
    onFetch,
    filterByInstructor,
    onFilterByInstructorChanged,
  } = props

  const timePeriods = [
    { key: 'tenMin', value: 10, text: '10 minutes' },
    { key: 'thirtyMin', value: 30, text: '30 minutes' },
    { key: 'oneHour', value: 60, text: '1 hour' },
    { key: 'oneHalfHour', value: 90, text: '1.5 hours' },
    { key: 'twoHours', value: 120, text: '2 hours' },
    { key: 'threeHours', value: 180, text: '3 hours' },
  ]

  return (
    <Flex
      column
      grow={0}
      shrink={0}
      overflow="hidden"
      p="10px"
      style={{
        width: '15.38%',
        borderRight: '1px #aaaaaa solid',
        maxWidth: '200px',
        overflowX: undefined,
        flexShrink: 0,
        textAlign: 'left',
        backgroundColor: '#e5e5e5',
      }}
    >
      <Flex column grow={1} justify="space-between">
        <Flex column>
          <Flex align="center">
            {/* <Fragment>
              <Text style={{ fontSize: '12px' }}>My Classes</Text>
              <Switch
                color="default"
                checked={!filterByInstructor}
                onChange={(_, checked) => onFilterByInstructorChanged(!checked)}
              />
              <Text style={{ fontSize: '12px' }}>All Classes</Text>
            </Fragment> */}
            <ClassroomSwitcher
              title={'Classes'}
              checked={!filterByInstructor}
              handleMyClassrooms={onFilterByInstructorChanged()}
            />
          </Flex>
          <InputLabel shrink>Recent Time Period</InputLabel>
          <Select
            name="timePeriod"
            value={timePeriod}
            onChange={updateTimePeriod}
          >
            {timePeriods.map(item => (
              <MenuItem key={item.text} value={item.value}>
                {item.text}
              </MenuItem>
            ))}
          </Select>
          <br />
          <Divider />
          <LocalizationProvider dateAdapter={DateAdapter}>
            <Stack spacing={3}>
              <DateTimePicker
                name="startTime"
                label="Start Time"
                value={startTime || null}
                onChange={updateStartTime}
                renderInput={params => <TextField {...params} />}
              />
              <DateTimePicker
                name="endTime"
                label="End Time"
                value={endTime || null}
                onChange={updateEndTime}
                renderInput={params => <TextField {...params} />}
              />
            </Stack>
          </LocalizationProvider>
          <Button onClick={onFetch}>FETCH</Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default withStyles(sideBarStyles)(SubmissionSidebar)
