import React, { useState } from 'react'
import { useQuery } from '@apollo/client'
import ResponseSidebar from './ResponseSidebar'
import ResponseTable from './ResponseTable'
import { GET_PROBLEM_RESPONSES } from './queries'
import { ErrorMessage, Flex, LoadingMessage } from '../../components'
import moment from 'moment-timezone'
import { computeRoundedTime } from '../../utils/datetime'

const ResponseTableView = props => {
  const currentRoundedTime = computeRoundedTime(new Date())
  const [timePeriod, setTimePeriod] = useState(60)
  const [startTime, setStartTime] = useState(
    moment(currentRoundedTime).subtract(1, 'hours')
  )
  const [endTime, setEndTime] = useState(moment(currentRoundedTime))
  const [filter, setFilter] = useState({
    createdOn: {
      $gteDate: startTime,
      $lteDate: endTime,
    },
  })

  const updateTimePeriod = event => {
    setTimePeriod(event.target.value)
    setStartTime(
      moment(currentRoundedTime).subtract(event.target.value, 'minutes')
    )
    setEndTime(moment(currentRoundedTime))
  }

  const updateStartTime = event => {
    setStartTime(event.target.value)
    setTimePeriod('')
  }

  const updateEndTime = event => {
    setEndTime(event.target.value)
    setTimePeriod('')
  }

  const handleFetch = () => {
    setFilter({
      createdOn: {
        $gteDate: startTime,
        $lteDate: endTime,
      },
    })
  }

  const { data, loading, error } = useQuery(GET_PROBLEM_RESPONSES, {
    variables: {
      filter,
      sort: { createdOn: -1 },
    },
  })

  if (loading) return <LoadingMessage />
  if (error) return <ErrorMessage error={error} />

  const problemResponses = data.problemResponses.map(problemResponse => {
    return {
      id: problemResponse.id,
      createdOn: problemResponse.createdOn,
      student:
        problemResponse.submission &&
        `${problemResponse.submission.student.firstName} 
      ${problemResponse.submission.student.lastName}`,
      class: problemResponse.submission.classroom.code,
      lesson: problemResponse.submission.lesson.lessonPlan.order,
      assignment: problemResponse.submission.assignment.problemSet.order,
      problem: problemResponse.problem.order,
      percentCorrect: problemResponse.percentCorrect,
      timeSpent: problemResponse.timeSpent,
    }
  })

  return (
    <Flex>
      <ResponseSidebar
        timePeriod={timePeriod}
        startTime={startTime}
        endTime={endTime}
        updateTimePeriod={updateTimePeriod}
        updateStartTime={updateStartTime}
        updateEndTime={updateEndTime}
        onFetch={handleFetch}
      />
      <Flex pl={'0px'} align="left" column grow={1}>
        <ResponseTable problemResponses={problemResponses} />
      </Flex>
    </Flex>
  )
}

export default ResponseTableView
