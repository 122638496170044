import React, { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'

import { GET_CLASSCODES } from './queries'
import TrialsView from './TrialsView'
import TrialDetailView from './TrialDetailView'

const TrialView = props => {
  const { url } = props.match

  const [classroomCodes, setClassroomCodes] = useState([])

  const [getClassroomCodes] = useLazyQuery(GET_CLASSCODES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      filter: {
        $or: [{ status: 'OPENED' }, { status: 'PUBLISHED' }],
        $not: {
          quarter: 'Tutoring',
        },
      },
    },
    onCompleted: res => {
      setClassroomCodes(res.classrooms)
    },
  })

  useEffect(() => {
    getClassroomCodes()
  }, [getClassroomCodes])

  return (
    <Switch>
      <Route
        path={`${url}/*`}
        render={props => (
          <TrialDetailView {...props} classroomCodes={classroomCodes} />
        )}
      />
      <Route
        path={url}
        render={props => <TrialsView classroomCodes={classroomCodes} />}
      />
    </Switch>
  )
}

export default TrialView
