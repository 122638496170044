import React from 'react'
import {
  Flex,
  AssignmentIcon,
  ColorizeIcon,
  Divider,
} from '../../../components'
import { Button, Tooltip } from '@mui/material'

export const AssignmentHeader = React.memo(({ handleActionChange }) => {
  // const handleAssignmentIcon = useCallback(() => {
  //   if (secondToggle) {
  //     return <AssignmentTurnedInIcon />
  //   } else {
  //     return <AssignmentIcon />
  //   }
  // }, [secondToggle])

  return (
    <React.Fragment>
      <Divider orientation="vertical" flexItem style={{ marginLeft: 'auto' }} />
      <Tooltip title={'Colorize Grades'}>
        <Button
          variant="text"
          style={{ width: '6em' }}
          color="primary"
          value="colorize"
          onClick={handleActionChange}
        >
          <Flex column>
            <Flex style={{ marginBottom: '.5em', justifyContent: 'center' }}>
              <ColorizeIcon />
            </Flex>
          </Flex>
        </Button>
      </Tooltip>
      <Divider orientation="vertical" flexItem />
      <Tooltip title={'Toggle Required'}>
        <Button
          variant="text"
          style={{ width: '6em' }}
          color="primary"
          value="required"
          onClick={handleActionChange}
        >
          <Flex column>
            <Flex style={{ marginBottom: '.5em', justifyContent: 'center' }}>
              <AssignmentIcon />
            </Flex>
          </Flex>
        </Button>
      </Tooltip>
    </React.Fragment>
  )
})

export default AssignmentHeader
