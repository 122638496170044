import React from 'react'
import { useMutation, useQuery } from '@apollo/client'
import {
  Accordion,
  AccordionDetails,
  Button,
  CustomAccordionSummary,
  ErrorMessage,
  Flex,
  LoadingMessage,
  Paper,
  Text,
  Typography,
} from '../../components'
import { Link } from 'react-router-dom'
import { GET_TUTOR_SESSION, UPDATE_TUTOR_SESSION_STATUS } from './queries'
import { formatDateHMA } from '../../utils/datetime'
import { Step, StepLabel, Stepper } from '@mui/material'

const TutorSessionDetailView = props => {
  const id = props.match.params[0]
  const { loading, error, data } = useQuery(GET_TUTOR_SESSION, {
    fetchPolicy: 'network-only',
    variables: {
      id: id,
    },
  })
  const [updateTutorSession, { data: updatedData }] = useMutation(
    UPDATE_TUTOR_SESSION_STATUS
  )

  if (loading) return <LoadingMessage />
  if (error) return <ErrorMessage error={error} />

  const tutorSession = data.tutorSession
  const currentStatus = updatedData
    ? updatedData.changeTutorSessionStatus.tutorSession.sessionStatus
    : tutorSession.sessionStatus
  const incrementSessionProgress = async () => {
    const nextStatus = incStatus(currentStatus)
    if (currentStatus !== nextStatus) {
      await updateTutorSession({
        variables: {
          tutorSessionId: id,
          sessionStatus: nextStatus,
        },
      })
    }
  }

  const { student, classroom, startDateTime, duration } = tutorSession
  const MS_PER_MIN = 60000
  const startTime = new Date(startDateTime)
  const endTime = new Date(startTime.getTime() + duration * MS_PER_MIN)

  return (
    <Flex
      column
      grow={1}
      style={{
        textAlign: 'left',
        margin: 'auto',
        width: '67%',
        minWidth: '600px',
        maxWidth: '1000px',
      }}
    >
      <br />
      <Link to="/tutee">{'< back to Tutor Sessions'}</Link>
      <br />
      <Flex justify="space-between">
        <Text variant="h4">{`Tutor Session for ${student.firstName} ${student.lastName}`}</Text>
      </Flex>

      <br />

      <Flex>
        <Button style={{ marginRight: 10 }}>Text Guardian</Button>
        <Button style={{ marginRight: 10 }}>Contact Care</Button>
      </Flex>

      <br />

      <Paper>
        <Accordion defaultExpanded>
          <CustomAccordionSummary>
            <Typography>Tutor Session Info</Typography>
          </CustomAccordionSummary>
          <AccordionDetails>
            <Flex grow={1} p="10px">
              <Flex grow={1} column>
                <Text>Class Code: {classroom.code}</Text>
                <Text>Phone: {student.phoneNumber}</Text>
              </Flex>
              <Flex grow={1} column>
                <Text>Time: {formatDateHMA(startTime, endTime)}</Text>
                <Text>Location: </Text>
                <Text>Zoom ID: </Text>
              </Flex>
            </Flex>
          </AccordionDetails>
        </Accordion>
      </Paper>

      <br />

      <Paper>
        <Accordion defaultExpanded>
          <CustomAccordionSummary>
            <Typography>Session Progress</Typography>
          </CustomAccordionSummary>
          <AccordionDetails>
            <Flex grow={1} column>
              <Stepper activeStep={getSessionStatusStep(currentStatus)}>
                {[
                  'Session Ready to Start',
                  'Tutee is Here',
                  'Session Completed',
                  'Report Emailed',
                ].map(label => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <Flex direction="row-reverse" p="10px">
                <Button onClick={incrementSessionProgress}>Next</Button>
              </Flex>
            </Flex>
          </AccordionDetails>
        </Accordion>
      </Paper>
    </Flex>
  )
}

const getSessionStatusStep = sessionProgress => {
  switch (sessionProgress) {
    case 'READY':
      return 0
    case 'STARTED':
      return 1
    case 'ENDED':
      return 2
    case 'REPORT':
      return 3
    default:
      return -1
  }
}

const incStatus = sessionProgress => {
  switch (sessionProgress) {
    case null:
      return 'READY'
    case 'READY':
      return 'STARTED'
    case 'STARTED':
      return 'ENDED'
    case 'ENDED':
      return 'REPORT'
    default:
      return 'REPORT'
  }
}

export default TutorSessionDetailView
