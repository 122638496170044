import React from 'react'
import {
  Button,
  Divider,
  ClassroomSwitcher,
  SidebarContainer,
  SidebarText,
  withStyles,
} from '../../components'
import { daysBetween } from '../../utils/datetime'
import { sideBarStyles } from '../../utils/style'
import { RESOLVE_ISSUES } from './queries'
import { useMutation } from '@apollo/client'

const IssueSidebar = ({
  issues,
  issueCount,
  myClassrooms,
  handleMyClassrooms,
  classes,
}) => {
  const [resolveIssues] = useMutation(RESOLVE_ISSUES)

  const employeeId = 'b001d0de-a579-4535-ab3e-ac7ebe165484'
  const today = new Date()
  const issueByDays = new Array(7).fill(0)
  issues.forEach(issue => {
    let days = daysBetween(today, issue.createdOn)
    if (days >= 6) days = 6
    issueByDays[days]++
  })

  return (
    <SidebarContainer>
      <ClassroomSwitcher
        title={'Classes'}
        checked={!myClassrooms}
        handleMyClassrooms={handleMyClassrooms}
      />
      <SidebarText
        classes={{ root: classes.headline }}
        title="Total Issues:"
        value={issueCount}
      />
      <Button
        variant="contained"
        onClick={async () => {
          await resolveIssues({
            variables: {
              employeeId: employeeId,
            },
          })
          window.location.reload(false)
        }}
        color="primary"
      >
        Auto Resolve Issues
      </Button>
      <Divider />
      <SidebarText
        classes={{ root: classes.subheadingOne }}
        title="Issues Day Outstanding:"
      />
      {issueByDays.map((tbw, index) => {
        return <SidebarText key={index} title={`Day ${index}: `} value={tbw} />
      })}
    </SidebarContainer>
  )
}

export default withStyles(sideBarStyles)(IssueSidebar)
