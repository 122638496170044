import React, { useState, useEffect } from 'react'
import ReactTable from 'react-table-6'
import { convertToMDY, convertToHMA } from '../../utils/datetime'

const ResponseTable = props => {
  const [data, setData] = useState(props.problemResponses)

  useEffect(() => {
    setData(props.problemResponses)
  }, [props.problemResponses])

  const columns = [
    {
      Header: 'Created On',
      accessor: 'createdOn',
      Cell: props => (
        <span>
          {props.value &&
            convertToMDY(props.value) + ' ' + convertToHMA(props.value)}
        </span>
      ),
      width: 200,
    },
    {
      Header: 'Student',
      accessor: 'student',
      width: 150,
    },
    {
      Header: 'Class',
      accessor: 'class',
      width: 150,
    },
    {
      Header: 'Lesson',
      accessor: 'lesson',
      width: 150,
    },
    {
      Header: 'Assignment',
      accessor: 'assignment',
      width: 150,
    },
    {
      Header: 'Problem',
      accessor: 'problem',
      width: 150,
    },
    {
      Header: '% Correct',
      accessor: 'percentCorrect',
      Cell: props => <span>{props.value + '%'}</span>,
      width: 150,
    },
    {
      Header: 'Time Spent',
      accessor: 'timeSpent',
      Cell: props => <span>{props.value && props.value + 's'}</span>,
      width: 150,
    },
  ]
  return (
    <div align="left">
      <ReactTable
        columns={columns}
        data={data}
        filterable={true}
        className="-highlight"
        defaultSorted={[{ id: 'createdOn' }]}
        style={{ fontSize: '10pt' }}
        defaultPageSize={25}
        getTheadProps={(state, rowInfo, column, instance) => {
          return {
            style: {
              fontWeight: 'bold',
              borderBottom: '1px solid #E5E5E5',
            },
          }
        }}
      />
    </div>
  )
}

export default ResponseTable
