import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import {
  Button,
  Box,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '../../components'

import { SEND_SMS } from './queries'
import { stripIndent } from 'common-tags'

const TextMessage = props => {
  const [message, setMessage] = useState('')
  const [sendSMS] = useMutation(SEND_SMS)

  const sendPresetMessage = async type => {
    const { instructor, classCode } = props
    const sendPresetSMS = await Promise.all(
      props.checkedStudents.map(async student => {
        const { firstName, lastName, phoneNumber } = student
        return await sendSMS({
          variables: {
            phoneNumber: phoneNumber,
            message: getMessage(type, firstName, instructor, classCode),
          },
        })
      })
    )
    return sendPresetSMS.every(data => data.data.sendSMS.success)
  }

  const handleSendInstantMessage = async e => {
    props.setSnackbar({
      open: true,
      message: 'Sending Text Messages',
      messageType: 'loading',
    })
    const success = sendPresetMessage(e.currentTarget.value)
    props.setSnackbar({
      open: true,
      message: success ? 'Texts Submitted ✔' : 'error',
      messageType: success ? 'success' : 'error',
    })
    props.handleClose()
  }

  const sendCustomMessage = async () => {
    const sendCustomSMS = await Promise.all(
      props.checkedStudents.map(async student => {
        return await sendSMS({
          variables: {
            phoneNumber: student.phoneNumber,
            message: message.replace(/(<([^>]+)>)/g, ''),
          },
        })
      })
    )
    return sendCustomSMS.every(data => data.data.sendSMS.success)
  }

  const handleSend = async sendSMS => {
    props.setSnackbar({
      open: true,
      message: 'Sending Text Messages',
      messageType: 'loading',
    })
    const success = sendCustomMessage()
    props.setSnackbar({
      open: true,
      message: success ? 'Texts Sent ✔' : 'error',
      messageType: success ? 'success' : 'error',
    })
    props.handleClose()
  }

  const { instructor, classCode } = props
  const students = props.checkedStudents
    .map(student => `${student.firstName}, ${student.lastName}`)
    .join('; ')
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      width="50%"
      maxWidth="lg"
    >
      <DialogTitle id="form-dialog-title">
        Text Parents of Following Students:
      </DialogTitle>
      <DialogContent>
        {<Typography>{students}</Typography>}
        <Paper
          variant="outlined"
          sx={{
            my: 3,
          }}
        >
          <DialogContentText
            sx={{
              mx: 1,
            }}
          >
            Instant Messages (Mouse over to view an example, click on the button
            to send):
          </DialogContentText>
          <Box>
            {[
              'PARTICIPATION',
              'ACTIVITY',
              'QUIZ',
              'OFFICEHOUR',
              'MEETING',
              'ATTENDANCE',
            ].map(buttonType => {
              const presetMessage = getMessage(
                buttonType,
                'John',
                instructor,
                classCode
              )
              return (
                <Tooltip title={presetMessage}>
                  <Button
                    key={buttonType}
                    variant="contained"
                    value={buttonType}
                    onClick={handleSendInstantMessage}
                    sx={{
                      mx: 1,
                      mt: 2,
                      mb: 2,
                    }}
                    color="secondary"
                  >
                    {buttonType}
                  </Button>
                </Tooltip>
              )
            })}
          </Box>
        </Paper>
        <DialogContentText
          sx={{
            my: 2,
          }}
        >
          Custom Message: Enter a message you would like to send
        </DialogContentText>
        <TextField
          id={'message'}
          label={'Text Message'}
          value={message}
          fullWidth
          multiline
          rows={6}
          onChange={e => setMessage(e.target.value)}
        />
      </DialogContent>

      <DialogActions>
        <Button variant="contained" onClick={props.handleClose} color="primary">
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={message.length === 0}
          onClick={async () => {
            handleSend()
          }}
        >
          Send
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const getMessage = (type, firstName, instructor, classCode) => {
  let message = `Hi, this is ${instructor.trim()} from Ardent teaching ${classCode} right now.`
  switch (type) {
    case 'ATTENDANCE':
      return stripIndent`${message}
            Please ask ${firstName} to join the class ASAP.`
    case 'MEETING':
      return stripIndent`${message} 
            I would like to meet with you and ${firstName} after today’s class 
            to discuss ${firstName}'s progress. 
            Would you please join the zoom meeting at the end of the class? 
            Looking forward to meeting you!`
    case 'ACTIVITY':
      return stripIndent`${message} We are working on an in-class activity on Solve right now. 
            However, I noticed that ${firstName} has not yet entered any responses. 
            Can you please check on ${firstName}? 
            Thank you so much!`
    case 'OFFICEHOUR':
      return stripIndent`${message}  
            I have asked ${firstName} to stay after class to get some extra help to 
            complete required homework assignments. 
            However, ${firstName} left right after the class. 
            Can you please ask ${firstName} to rejoin the class? Thank you so much!`
    case 'QUIZ':
      return stripIndent`${message} 
            We are taking a class quiz right now. 
            However, I noticed that ${firstName} has not yet started quiz for 10 minutes. 
            Can you please check on ${firstName}? Thank you so much!`
    case 'PARTICIPATION':
      return stripIndent`${message}  
            I noticed that ${firstName} has not actively participated for a while. 
            Can you please check on ${firstName}? 
            Thank you so much!`
    default:
      return ''
  }
}
export default TextMessage
