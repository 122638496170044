import React from 'react'
import { useQuery } from '@apollo/client'

import { AppBar, Box, Button, Toolbar, Typography } from '@mui/material'

import { version } from '../../package.json'

import {
  loginRedirect,
  debug,
  tokenName,
  idName,
  LOCAL_ARDENT_ACCOUNT_URL,
} from '../config'
import { GET_EMPLOYEE } from '../queries'
import * as Cookies from 'js-cookie'

const NavBar = props => {
  const employeeId = Cookies.get(idName)

  const getEmployee = useQuery(GET_EMPLOYEE, {
    variables: { id: employeeId },
  })
  const { error, data } = getEmployee
  const userAutohrized = !error && data && data.employee.isInstructorUser
  const employeeName = userAutohrized
    ? `${data.employee.firstName} ${data.employee.lastName}`
    : ''
  const isAdmin = userAutohrized && data.employee.isAdminOfSOLVE

  localStorage.setItem('userAuthorized', userAutohrized)
  localStorage.setItem('employeeName', employeeName)
  localStorage.setItem('isAdmin', isAdmin)

  return (
    <Box>
      <AppBar position="static">
        <Toolbar
          variant="dense"
          sx={{ color: 'white', bgcolor: 'primary.main' }}
        >
          <Typography align="left" variant="h6" color="inherit" flexGrow="1">
            {debug
              ? `Ardent Instructor Test Server v${version}`
              : `Ardent Instructor v${version}`}
          </Typography>
          {userAutohrized ? (
            <Button color="inherit" onClick={redirect}>
              {employeeName}
            </Button>
          ) : (
            <Button color="inherit" onClick={redirect}>
              Login
            </Button>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export const redirect = props => {
  const options = {
    domain:
      process.env.NODE_ENV !== 'production' ? 'localhost' : 'ardentlabs.io',
    path: '/',
  }
  Cookies.remove(tokenName, options)
  Cookies.remove(idName, options)

  window.location.replace(
    (window.location.href.startsWith('http://localhost')
      ? LOCAL_ARDENT_ACCOUNT_URL
      : loginRedirect) + `?from=${encodeURIComponent(window.location.href)}`
  )
}

export default NavBar
