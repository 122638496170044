import React from 'react'
import { Query } from '@apollo/client/react/components'

import { Button } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

import {
  Divider,
  ErrorMessage,
  Flex,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '../../../components'
import { GET_SIMPLE_ASSIGNMENTS, GET_SIMPLE_LESSONS } from '../queries'

export const GradesHeader = React.memo(props => {
  const {
    classId,
    lesson,
    assignment,
    handleLessonChange,
    handleAssignmentChange,
    handleActionChange,
  } = props

  let lessons = null
  const setDefaultLesson = () => {
    if (lesson === '' && lessons && lessons.length) {
      const defaultLesson = lessons.reduce((prev, cur) => {
        return prev.date < cur.date ? prev : cur
      })
      handleLessonChange(defaultLesson.id)
    }
  }

  let assignments = null
  const setDefaultAssignment = () => {
    if (assignment === '' && assignments && assignments.length) {
      handleAssignmentChange(assignments[0].id)
    }
  }

  return (
    <React.Fragment>
      <Divider orientation="vertical" flexItem style={{ marginLeft: 'auto' }} />
      <Query
        query={GET_SIMPLE_LESSONS}
        variables={{ id: classId }}
        onCompleted={setDefaultLesson}
      >
        {({ error, loading, data }) => {
          if (loading) return ''
          if (error) return <ErrorMessage error={error} />
          const today = new Date()
          lessons = [...data.classroom.lessons]
            .sort((a, b) => a.lessonPlan.order - b.lessonPlan.order)
            .map(lesson => {
              return {
                id: lesson.id,
                title: lesson.lessonPlan.title,
                order: lesson.lessonPlan.order,
                date: Math.abs(today - new Date(lesson.startOn).getTime()),
              }
            })

          return (
            <Flex
              style={{
                width: '16em',
                marginRight: '.5em',
                marginLeft: '.5em',
              }}
            >
              <FormControl error={lesson === ''} fullWidth>
                <InputLabel id="lesson-select-input-label">Lesson</InputLabel>
                <Select
                  labelId="lesson-select-label"
                  id="lesson-select"
                  value={lesson}
                  onChange={e => handleLessonChange(e.target.value)}
                >
                  {lessons.map(lesson => {
                    return (
                      <MenuItem key={`menuitem-${lesson.id}`} value={lesson.id}>
                        {lesson.order}. {lesson.title}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Flex>
          )
        }}
      </Query>
      <Divider orientation="vertical" flexItem />
      {lesson !== '' && (
        <Query
          query={GET_SIMPLE_ASSIGNMENTS}
          variables={{ id: lesson }}
          onCompleted={setDefaultAssignment}
        >
          {({ error, loading, data }) => {
            if (loading) return ''
            if (error) return <ErrorMessage error={error} />
            assignments = [...data.lesson.assignments]
              .sort((a, b) => a.problemSet.order - b.problemSet.order)
              .map(assignment => {
                return {
                  id: assignment.id,
                  title: assignment.problemSet.title,
                  order: assignment.problemSet.order,
                }
              })
            const currentIndex = assignments.findIndex(
              temp => temp.id === assignment
            )
            return (
              <React.Fragment>
                <Flex
                  style={{
                    width: '20em',
                    marginRight: '.5em',
                    marginLeft: '.5em',
                  }}
                >
                  <FormControl
                    color="primary"
                    error={assignment === ''}
                    fullWidth
                  >
                    <InputLabel id="assignment-select-input-label">
                      Assignment
                    </InputLabel>
                    <Select
                      labelId="assignment-select-label"
                      id="assignment-select"
                      value={assignment}
                      onChange={e => {
                        handleAssignmentChange(e.target.value)
                      }}
                    >
                      {assignments.map(assignment => {
                        return (
                          <MenuItem
                            key={`menuitem-${assignment.id}`}
                            value={assignment.id}
                          >
                            {assignment.order}. {assignment.title}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Flex>
                <Divider orientation="vertical" flexItem />
                <Button
                  variant="text"
                  style={{ width: '6em' }}
                  color="primary"
                  disabled={currentIndex === 0 || assignment === ''}
                  onClick={() => {
                    handleAssignmentChange(assignments[currentIndex - 1].id)
                  }}
                >
                  <Flex column>
                    <Flex
                      style={{
                        marginBottom: '.5em',
                        justifyContent: 'center',
                      }}
                    >
                      <ArrowBackIcon />
                    </Flex>
                  </Flex>
                </Button>
                <Divider orientation="vertical" flexItem />
                <Button
                  variant="text"
                  style={{ width: '6em' }}
                  color="primary"
                  disabled={
                    currentIndex === assignments.length - 1 || assignment === ''
                  }
                  onClick={() => {
                    handleAssignmentChange(assignments[currentIndex + 1].id)
                  }}
                >
                  <Flex column>
                    <Flex
                      style={{
                        marginBottom: '.5em',
                        justifyContent: 'center',
                      }}
                    >
                      <ArrowForwardIcon />
                    </Flex>
                  </Flex>
                </Button>
              </React.Fragment>
            )
          }}
        </Query>
      )}
      <Divider orientation="vertical" flexItem />
      <Button
        variant="text"
        style={{ width: '6em' }}
        color="primary"
        value="reset"
        onClick={handleActionChange}
      >
        <Flex column>
          <Flex style={{ marginBottom: '.5em', justifyContent: 'center' }}>
            Reset
          </Flex>
        </Flex>
      </Button>
      <Divider orientation="vertical" flexItem />
      <Button
        variant="text"
        style={{ width: '6em' }}
        color="primary"
        value="submit"
        onClick={handleActionChange}
      >
        <Flex column>
          <Flex style={{ marginBottom: '.5em', justifyContent: 'center' }}>
            Submit
          </Flex>
        </Flex>
      </Button>
      <Divider orientation="vertical" flexItem />
    </React.Fragment>
  )
})

export default GradesHeader
