import React from 'react'
import { Route, Switch } from 'react-router-dom'
import SubmissionTableView from './SubmissionTableView'

const SubmissionView = props => {
  const { url } = props.match
  return (
    <Switch>
      <Route path={`${url}`} component={SubmissionTableView} />
    </Switch>
  )
}

export default SubmissionView
