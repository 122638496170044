import React from 'react'
import { Box, Typography } from '@mui/material/'

const SidebarText = ({ classes, className, title, value }) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'pace-between',
    }}
  >
    <Typography classes={classes} style={{ padding: '8px', fontSize: 14 }}>
      {title}
    </Typography>
    <Typography classes={classes} style={{ padding: '8px', fontSize: 14 }}>
      {value}
    </Typography>
  </Box>
)

export default SidebarText
