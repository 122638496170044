import React, { useState, useEffect, useCallback } from 'react'
import {
  Flex,
  LoadingMessage,
  ErrorMessage,
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Icon,
} from '../../../components'
import {
  contest_icon,
  homework_icon,
  instruction_icon,
  practice_icon,
  quiz_icon,
  review_icon,
} from '../../../components/assests/'
import { compareStudents } from '../utils'
import Tooltip from '@mui/material/Tooltip'
import { useQuery } from '@apollo/client'
import { GET_ASSIGNMENTS } from '../queries'

const useStyles = makeStyles(() => ({
  defaultHeaderCellStyle: {
    height: '3em',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'lightgrey',
    padding: '5px 5px 5px 5px',
    align: 'center',
    alignItems: 'center',
  },
  ACell: {
    backgroundColor: '#b9f6ca',
    height: '3em',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'lightgrey',
    padding: '5px 5px 5px 5px',
    align: 'center',
    alignItems: 'center',
  },
  BCell: {
    backgroundColor: '#fff9c4',
    height: '3em',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'lightgrey',
    padding: '5px 5px 5px 5px',
    align: 'center',
    alignItems: 'center',
  },
  CCell: {
    backgroundColor: '#ffecb3',
    height: '3em',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'lightgrey',
    padding: '5px 5px 5px 5px',
    align: 'center',
    alignItems: 'center',
  },
  FCell: {
    backgroundColor: '#ffccbc',
    height: '3em',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'lightgrey',
    padding: '5px 5px 5px 5px',
    align: 'center',
    alignItems: 'center',
  },
  CellTextContainer: {
    display: 'block',
    width: '6em',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  problemSetIconRoot: {
    textAlign: 'center',
  },
  problemSetIcon: {
    height: '75%',
  },
}))

export const AssignmentTableView = props => {
  const [colorCoded, setColorCoded] = useState(true)
  const [required, setRequired] = useState(true)

  /**
   * Fires action on fireAction trigger
   */
  const { fireAction, handleSiblingAction } = props
  useEffect(() => {
    if (fireAction) {
      switch (fireAction) {
        case 'colorize':
          handleSiblingAction(() => {
            setColorCoded(!colorCoded)
          })
          break
        case 'required':
          handleSiblingAction(() => {
            setRequired(!required)
          })
          break
        default:
          break
      }
    }
  }, [fireAction])

  const processAssignments = useCallback(assignmentData => {
    const studentMap = new Map()

    assignmentData.submissionRecords.forEach(student => {
      studentMap.set(student.id, {
        id: student.studentId,
        firstName: student.firstName,
        lastName: student.lastName,
        results: student.results,
      })
    })
    return {
      assignments: assignmentData.assignmentLabels,
      studentMap: assignmentData.submissionRecords,
    }
  }, [])

  const { loading, error, data } = useQuery(GET_ASSIGNMENTS, {
    variables: { classroomId: props.classId, required: required },
  })
  if (loading) return <LoadingMessage />
  if (error) return <ErrorMessage error={error} />
  const processedData = processAssignments(data.gradesForClassroomAssignment)
  return (
    <Flex align="left" column style={{ overflowX: 'scroll' }}>
      <AssignmentTable data={processedData} colorCoded={colorCoded} />
    </Flex>
  )
}

const AssignmentTable = React.memo(({ data, colorCoded }) => {
  const classes = useStyles()

  const gradeCellFork = value => {
    if (colorCoded && value.submitted) {
      const { grade } = value
      if (grade === 0 || grade === null) return classes.defaultHeaderCellStyle
      else if (grade >= 90) return classes.ACell
      else if (grade < 90 && grade >= 80) return classes.BCell
      else if (grade < 80 && grade >= 70) return classes.CCell
      else return classes.FCell
    } else {
      return classes.defaultHeaderCellStyle
    }
  }

  const problemTypeIconFork = type => {
    switch (type) {
      case 'PREVIEW':
        return homework_icon
      case 'INSTRUCTION':
        return instruction_icon
      case 'EXERCISE':
      case 'PRACTICE':
        return practice_icon
      case 'HOMEWORK':
        return homework_icon
      case 'REVIEW':
        return review_icon
      case 'QUIZ':
        return quiz_icon
      case 'CONTEST':
        return contest_icon
      default:
        return null
    }
  }

  return (
    <Flex>
      <Table>
        <TableHead>
          <TableRow>
            {data.assignments.map((entry, index) => (
              <Tooltip title={entry.title} key={index}>
                <TableCell
                  key={`header-title-${entry.index}`}
                  variant={'head'}
                  align={'center'}
                  style={{
                    width: '3em',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  className={classes.defaultHeaderCellStyle}
                >
                  <Flex className={classes.CellTextContainer} key={index}>
                    {entry.index}
                  </Flex>
                </TableCell>
              </Tooltip>
            ))}
          </TableRow>
          <TableRow>
            {data.assignments.map((entry, index) => (
              <Tooltip title={entry.kind} key={index}>
                <TableCell
                  key={`header-kind-${entry.index}`}
                  variant={'head'}
                  align={'center'}
                  style={{
                    width: '3em',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  className={classes.defaultHeaderCellStyle}
                >
                  <Icon
                    color="primary"
                    classes={{ root: classes.problemSetIconRoot }}
                  >
                    <img
                      className={classes.problemSetIcon}
                      src={problemTypeIconFork(entry.kind)}
                      alt={entry.kind}
                    />
                  </Icon>
                </TableCell>
              </Tooltip>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {[...data.studentMap.values()]
            .sort((a, b) =>
              compareStudents(a.lastName, b.lastName, a.firstName, b.firstName)
            )
            .map(student => (
              <TableRow hover key={`${student.studentId}-body-row`}>
                {student.results.map((result, index) => {
                  return (
                    <React.Fragment key={index}>
                      <TableCell
                        align={'center'}
                        colSpan={1}
                        className={result && gradeCellFork(result)}
                        style={{ width: '2.5em' }}
                        key={`${student.studentId}-grade-cell`}
                      >
                        {result && result.grade === -1
                          ? 'Excused'
                          : result && result.submitted
                          ? `${result.grade}%`
                          : ''}
                      </TableCell>
                    </React.Fragment>
                  )
                })}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Flex>
  )
})

export default AssignmentTableView
