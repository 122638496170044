import React from 'react'

import { Box, Switch, Typography } from '@mui/material'

const ClassroomSwitcher = ({
  title,
  handleMyClassrooms,
  disabled,
  checked,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
      }}
    >
      <Typography variant="subtitle2">My {title}</Typography>
      <Switch
        disabled={disabled}
        checked={checked}
        onChange={(_, checked) => handleMyClassrooms(!checked)}
      />
      <Typography variant="subtitle2">All {title}</Typography>
    </Box>
  )
}

export default ClassroomSwitcher
