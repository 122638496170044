import React from 'react'
import { Route, Switch } from 'react-router-dom'
import ClassTableView from './ClassTableView'
import ClassView from './ClassView'
import GradeStudentAssignment from './GradeStudentAssignment'

const ClassViewRouter = props => {
  const url = props.match.url
  return (
    <Switch>
      <Route path={`${url}/*/*/*/*`} component={GradeStudentAssignment} />
      <Route path={`${url}/*`} render={props => <ClassView {...props} />} />
      <Route path={url} render={props => <ClassTableView />} />
    </Switch>
  )
}

export default ClassViewRouter
